import axios from "axios";

const baseURL = "https://apisync.thatapp.io/api/v1/";
// const baseURL = "http://localhost:3300/api/v1/";
const authURL = "https://thatapp-api.thatapp.io/api/";
const authToken = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("chosen_token")}`,
  },
};
const Endpoint = {
  items: () => {
    return axios.get(`${baseURL}sync/item`);
  },
  spaces: (org_id, db) => {
    return axios.get(`${baseURL}sync/spaces?org_id=${org_id}&db=${db}`);
  },
  appUnderSpace: (space_id, _org_id, _db) => {
    return axios.get(
      `${baseURL}sync/space/` + space_id + `?org_id=${_org_id}&db=${_db}`
    );
  },
  itemsUnderApps: (
    deleted_from_podio = false,
    app_id,
    org_id,
    database,
    skip = 0,
    limit = 100,
    data,
    filter_model,
    sort
  ) => {
    let filterModel = "";
    let sortModel = "";

    if (filter_model !== null) {
      filterModel = filter_model;
    }

    if (sort !== null) {
      sortModel = sort;
    }

    let delete_from_podio = "";

    if (deleted_from_podio) {
      delete_from_podio = "&deleted_from_podio=true";
    }

    if (data !== null) {
      return axios.get(
        `${baseURL}sync/app/items/${app_id}?org_id=${org_id}&database=${database}&skip=${skip}&limit=${limit}&type=${data.type}&group_field=${data.group_field}&group_value=${data.group_value}${filterModel}${sortModel}${delete_from_podio}&api_v2=true`
      );
    } else {
      return axios.get(
        `${baseURL}sync/app/items/${app_id}?org_id=${org_id}&database=${database}&skip=${skip}&limit=${limit}${filterModel}${sortModel}${delete_from_podio}&api_v2=true`
      );
    }
  },
  hooksUnderApp: (org, database, skip = 0, limit = 200) => {
    return axios.get(
      `${baseURL}sync/app/hooks/${org}?&database=${database}&skip=${skip}&limit=${limit}&api_v2=true`
    );
  },
  hooksUnderSingleApp: (org, database, app_id, skip = 0, limit = 200) => {
    return axios.get(
      `${baseURL}sync/apps/single/hooks/${org}?database=${database}&app_id=${app_id}&skip=${skip}&limit=${limit}&api_v2=true`
    );
  },
  hooksUnderSpace: (org, database, skip = 0, limit = 200) => {
    return axios.get(
      `${baseURL}sync/space/hooks/${org}?database=${database}&skip=${skip}&limit=${limit}&api_v2=true`
    );
  },
  fieldHooksUnderSingleApp: (org, database, app_id, skip = 0, limit = 200) => {
    return axios.get(
      `${baseURL}sync/apps/field/single/hooks/${org}?database=${database}&app_id=${app_id}&skip=${skip}&limit=${limit}&api_v2=true`
    );
  },
  hooksUnderSingleSpace: (org, database, space_id, skip = 0, limit = 200) => {
    return axios.get(
      `${baseURL}sync/space/single/hooks/${org}?database=${database}&space_id=${space_id}&skip=${skip}&limit=${limit}&api_v2=true`
    );
  },
  deletedItems: (app_id, org_id, database, skip, limit) => {
    return axios.get(
      `${baseURL}sync/app/items/deleted/${app_id}?org_id=${org_id}&database=${database}&skip=${skip}&limit=${limit}`
    );
  },
  singleApp: (app_id, org_id, db) => {
    return axios.get(
      `${baseURL}sync/app/${app_id}?org_id=${org_id}&db=${db}&api_v2=true`
    );
  },
  apps: () => {
    return axios.get(`${baseURL}sync/apps`);
  },
  org: () => {
    return axios.get(`${baseURL}sync/org`);
  },
  socialLogin: (data) => {
    return axios.post(`${authURL}auth/login`, data);
  },
  me: () => {
    return axios.get(`${authURL}auth/me`);
  },
  logout: () => {
    return axios.get(`${authURL}auth/logout`);
  },
  refresh_token: (token) => {
    return axios.get(`${authURL}auth/refresh?token=${token}`);
  },
  login: (data) => {
    return axios.post(`${baseURL}auth/login`, data);
  },
  getOrg: (org_id) => {
    return axios.get(`${authURL}org?org_id=${org_id}`);
  },
  getFiles: (org_id, db, skip = 0, limit = 100, query) => {
    return axios.get(
      `${baseURL}sync/files/${org_id}?db=${db}&skip=${skip}&limit=${limit}${query}`
    );
  },
  getOrgs: () => {
    return axios.get(`${authURL}podio/orgs`);
  },
  getMyOrgs: () => {
    return axios.get(`${authURL}my/orgs`);
  },
  syncOrganisation: (data) => {
    return axios.post(`${authURL}sync/organisation`, data);
  },
  updateOrganisation: (data) => {
    return axios.post(`${authURL}edit/organisation `, data);
  },
  deleteOrganisation: (data) => {
    return axios.post(`${authURL}delete/organisation`, data);
  },
  organizationDetails: () => {
    return axios.get(`${authURL}orgs/details`);
  },
  organizationDetailsOnSync: () => {
    return axios.get(`${authURL}syncing/details`);
  },
  addFileHaven: (data) => {
    return axios.post(`${authURL}setup/haven`, data);
  },
  deleteFileHaven: (data) => {
    return axios.post(`${authURL}delete/haven`, data);
  },
  restoreItem: (data) => {
    return axios.post(`${authURL}restore/items`, data);
  },
  deleteItem: (data) => {
    return axios.post(`${authURL}delete/item`, data, authToken);
  },
  AddItem: (data) => {
    return axios.post(`${authURL}create/item`, data);
  },
  connectSocialApp: (data) => {
    return axios.post(`${baseURL}auth/social_connect/login`, data);
  },
  registration: (data) => {
    return axios.post(`${baseURL}auth/register`, data);
  },
  auditOrg: (org_id, database) => {
    return axios.get(
      `${baseURL}audit/spaces?org_id=${org_id}&database=${database}`
    );
  },
  auditSpace: (space_id, org_id, database) => {
    return axios.get(
      `${baseURL}audit/space?space_id=${space_id}&org_id=${org_id}&database=${database}`
    );
  },
  auditApp: (app_id, org_id, database) => {
    return axios.get(
      `${baseURL}audit/app?app_id=${app_id}&org_id=${org_id}&database=${database}`
    );
  },
  updateItemValue: (data) => {
    return axios.post(`${authURL}edit/item`, data);
  },
  syncSpace: (org, space) => {
    return axios.post(`${authURL}sync/space?org_id=${org}&space_id=${space}`);
  },
  syncApp: (org, app) => {
    return axios.post(`${authURL}sync/app?org_id=${org}&app_id=${app}`);
  },
  switchRole: (org, space, userId, role) => {
    return axios.post(
      `${authURL}space/member/role?org_id=${org}&space_id=${space}&user_id=${userId}&role=${role}`
    );
  },
  refreshUser: (org, space, userId) => {
    return axios.post(
      `${authURL}space/refresh/member?org_id=${org}&space_id=${space}&user_id=${userId}`
    );
  },
  getOrgCounts: (org_id) => {
    return axios.get(`${authURL}org/${org_id}/details`);
  },
  removeMemberFromOrg: (orgId, user) => {
    return axios.get(
      `${authURL}org/remove/member?org_id=${orgId}&user_id=${user}`
    );
  },
  AddUserToSpace: (org, space, user, role) => {
    return axios.post(
      `${authURL}space/add/member?org_id=${org}&space_id=${space}&users=${user}&role=${role}`
    );
  },
  getMenu: () => {
    return axios.get(`${authURL}menu`);
  },
  updatedAuditSpace: (org_id) => {
    return axios.get(`${authURL}audit/spaces?org_id=${org_id}`);
  },
  updatedAuditMembers: (org_id) => {
    return axios.get(`${authURL}audit/members?org_id=${org_id}`);
  },
  removeMemberFromSpace: (org_id, space_id, user_id) => {
    return axios.post(
      `${authURL}space/remove/member?org_id=${org_id}&space_id=${space_id}&user_id=${user_id}`
    );
  },
  getIrisOrgs: () => {
    return axios.get(`${authURL}iris/get-org-list`);
  },
  getInvoices: () => {
    return axios.get(`${authURL}account/invoices`);
  },
  getCalcBundle: (id, type) => {
    return axios.get(
      `${authURL}v2/iris/radial/get-edge-bundle-data?type=${type}&org_id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("chosen_token")}`,
        },
      }
    );
  },
  getUsers: (page = 1, per_page = 50) => {
    return axios.get(
      `${authURL}auth/users?use_pagination=1&page=${page}&per_page=${per_page}`
    );
  },
  imitateAccount: (user_id) => {
    return axios.post(`${authURL}auth/imitate?user_id=${user_id}`);
  },
  saveFrequency: (data) => {
    return axios.post(`${authURL}frequency/save`, data);
  },
  getUsersCustom: (start = 0, per_page = 50, query) => {
    return axios.get(
      `${authURL}auth/users?use_pagination=0&start=${start}&per_page=${per_page}${query}`
    );
  },
  getHooksChart: () => {
    return axios.get(`${authURL}hook-manager/chart`);
  },
  getHooksAnalytics: () => {
    return axios.get(`${authURL}hook-manager/analytics`);
  },
  killHook: (orgId, url) => {
    return axios.post(`${authURL}hook-killer?org_id=${orgId}&url=${url}`);
  },
  restoreApp: (data) => {
    return axios.post(`${authURL}restore/app`, data);
  },
  restoreSpace: (data) => {
    return axios.post(`${authURL}restore/space`, data);
  },
  getPlans: () => {
    return axios.get(`${authURL}billing/plans`);
  },
  saveCard: (data) => {
    return axios.post(`${authURL}billing/add/card`, data);
  },
  getCards: () => {
    return axios.get(`${authURL}billing/cards`);
  },
  defaultCard: (id) => {
    return axios.post(`${authURL}billing/default/card`, { card_id: id });
  },
  deleteCard: (id) => {
    return axios.post(`${authURL}billing/delete/card`, { card_id: id });
  },
  getActivePlan: () => {
    return axios.get(`${authURL}billing/active/plan`);
  },
  savePlan: (id) => {
    return axios.post(`${authURL}billing/register/plan`, { plan_id: id });
  },
  restoreFile: (data) => {
    return axios.post(`${authURL}restore/file`, data);
  },
  deleteAccount: () => {
    return axios.post(`${authURL}auth/delete/account`);
  },
  getOwnerNames: (spaceId, orgId, database) => {
    return axios.get(
      `${baseURL}sync/space/single/members/${spaceId}?org_id=${orgId}&database=${database}&api_v2=true`
    );
  },
};

export default Endpoint;
