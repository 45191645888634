import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const IrisExperiment = () => {
  const myRef = useRef("");
  var width = 960;
  const height = 500;
  // useEffect(() => {
  //   let size = 500;
  //   let svg = d3
  //     .select(myRef.current)
  //     .append("svg")
  //     .attr("width", size)
  //     .attr("height", size);
  //   let rect_width = 95;

  //   svg
  //     .selectAll("rect")
  //     .data(dataset)
  //     .enter()
  //     .append("rect")
  //     .attr("x", (d, i) => 5 + i * (rect_width + 5))
  //     .attr("y", (d) => size - d)
  //     .attr("width", rect_width)
  //     .attr("height", (d) => d)
  //     .attr("fill", "teal");
  // }, []);
  function randomColor() {
    return "hsl(" + ~~(60 + Math.random() * 180) + ",80%,60%)";
  }

  useEffect(() => {
    var svg = d3
        .select(myRef.current)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .style("background-color", randomColor),
      g = svg.append("g");

    // then, create the zoom behvavior
    var zoom = d3
      .zoom()
      // only scale up, e.g. between 1x and 50x
      .scaleExtent([1, 50])
      .on("zoom", function () {
        // the "zoom" event populates d3.event with an object that has
        // a "translate" property (a 2-element Array in the form [x, y])
        // and a numeric "scale" property
        var e = d3.event,
          // now, constrain the x and y components of the translation by the
          // dimensions of the viewport
          tx = Math.min(0, Math.max(e.translate[0], width - width * e.scale)),
          ty = Math.min(0, Math.max(e.translate[1], height - height * e.scale));
        // then, update the zoom behavior's internal translation, so that
        // it knows how to properly manipulate it on the next movement

        zoom.translate([tx, ty]);
        // and finally, update the <g> element's transform attribute with the
        // correct translation and scale (in reverse order)
        g.attr(
          "transform",
          ["translate(" + [tx, ty] + ")", "scale(" + e.scale + ")"].join(" ")
        );
      });

    svg.call(zoom);

    // then, let's add some circles
    //eslint-disable-next-line
  }, []);

  return <div ref={myRef}>asdf</div>;
};

export default IrisExperiment;
