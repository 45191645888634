import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import Summary from "./Summary";
import SyncTable from "../../components/Table/SyncTable/SyncTable";
import MyDialog from "../../components/syncModal/sync";
import "./Sync.css";
import Backdrop from "../../components/Loader/Backdrop";
import {
  deleteOrganization,
  getSyncedOrganizations,
  syncOrganization,
  getOrganization,
  setFrequencyData,
  setOrgUpdate,
} from "../../services/sync";
import { syningcOrg } from "../../services/trysync";
import { connect } from "react-redux";
import { setOrgs } from "../../redux/actions/sync";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { getNavigation } from "../../services/common";
import { setNav } from "../../redux/actions/sidebar";
import * as actions from "../../redux/actions";
import GDPRModal from "../../components/ConfirmModal/GDPRModal";
import OrgUpdateModal from "../../components/ConfirmModal/OrgUpdateModal";
import Swal from "sweetalert2";

const Sync = ({
  onFetchAllUnSyncedOrganizations,
  onGetSynced,
  onGetUnsynced,
  orgs,
  setOrgs,
  spaceCount,
  appCount,
  itemsCount,
  setNav,
}) => {
  const [options, setOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [org, setOrg] = useState(null);
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const authToken = localStorage.getItem("chosen_token");
  const [isModal, setModal] = useState({
    value: false,
    message: "",
    id: null,
    name: null,
  });
  const [gdpr_modal, setGdprModal] = useState({ org_id: null, value: false });
  const [org_modal, setOrgUpdateModal] = useState({
    org_id: null,
    org_name: null,
    value: false,
  });
  // const [currentSyncOrgId, setcurrentSyncOrgId] = useState(null);
  const INTERVAL_KEY = "IntervalId";
  const ORGS_KEY = "selectedOrgs";

  const handleClickOpen = () => {
    setOption(onGetUnsynced.map((item) => item.name));
    setValue("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSync = async () => {
    if (!org) return false;
    handleClose();
    setLoading(true);

    const response = await syningcOrg(authToken, org.name, org.org_id);

    if (response.status === true && response.message) {
      // setcurrentSyncOrgId(org.org_id);
      await startStuff();
      setLoading(response.isLoading);
      fetchOrgs();
      toaster.notify(response.message, {
        duration: 1000,
        position: "top",
      });
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.message.data.message,
      });
    }
  };

  const startStuff = async () => {
    const intervalId = setInterval(function startSyncing() {
      checkIfOrgIsDoneSyncing();
      getSyncedOrganizations().then((res) => {
        setOrgs(res.data);
      });
      getNavigation().then((response) => {
        setNav(response.menus);
      });
    }, 2000);
    //https://github.com/thatapp/thatapp_frontend_v2/pull/16
    // I used local storage here because the setstate was not working

    localStorage.setItem(INTERVAL_KEY, intervalId);
  };

  function checkIfOrgIsDoneSyncing() {
    // if (!currentSyncOrgId) return false;
    // I used local storage here because the setstate was not working
    const currentOrg = JSON.parse(localStorage.getItem(ORGS_KEY));

    if (!currentOrg.org_id) return false;

    // if (!localStorage.getItem(ORGS_KEY)) return false;
    getOrganization(currentOrg.org_id).then((res) => {
      const status = res.data.status;

      if (status === "Done" || status === "N'SYNC") {
        clearInterval(localStorage.getItem(INTERVAL_KEY));
        localStorage.removeItem(ORGS_KEY);
        localStorage.removeItem(INTERVAL_KEY);
      }
    });
  }

  const fetchOrgs = useCallback(async () => {
    onFetchAllUnSyncedOrganizations(authToken);
    let res = await getSyncedOrganizations();

    setOrgs(res.data);
    // eslint-disable-next-line
  }, [setOrgs, authToken]);

  useEffect(() => {
    fetchOrgs();
  }, [fetchOrgs]);

  const toggleModal = (value, message, id, name) =>
    setModal({ value, message, id, name });

  const gdprModal = (org_id, value) =>
    setGdprModal({ org_id: org_id, value: value });

  const submitFrequency = (org_id, value) => {
    setFrequencyData(`?organisation_id=${org_id}&frequency=${value}`)
      .then(() => {
        toaster.notify("Frequency was saved successfully", {
          duration: 4000,
          position: "bottom",
        });
        setGdprModal({ org_id: null, value: false });
      })
      .catch(() => {
        toaster.notify(
          "An error occurred while trying to save the frequency.",
          {
            duration: 4000,
            position: "bottom",
          }
        );
        setGdprModal({ org_id: null, value: false });
      });
  };

  const orgUpdateModal = (org_id, org_name, value) =>
    setOrgUpdateModal({ org_id: org_id, org_name: org_name, value: value });

  const submitOrgUpdate = (org_id, value) => {
    setOrgUpdate(`?org_name=${value}&org_id=${org_id}`)
      .then((r) => {
        getSyncedOrganizations().then((res) => {
          setOrgs(res.data);
        });
        toaster.notify(r.message, {
          duration: 4000,
          position: "bottom",
        });
        setOrgUpdateModal({ org_id: null, org_name: null, value: false });
      })
      .catch(() => {
        toaster.notify(
          "An error occurred while trying to update organisation.",
          {
            duration: 4000,
            position: "bottom",
          }
        );
        setOrgUpdateModal({ org_id: null, org_name: null, value: false });
      });
  };

  const updateNavigation = async () => {
    let response = await getNavigation();

    setNav(response.menus);
  };

  const syncOrg = async () => {
    toggleModal(null);
    let query = `?org_id=${isModal.id}&org_name=${isModal.name}`;

    try {
      let response = await syncOrganization(query);

      if (response) {
        // setcurrentSyncOrgId(org.org_id);

        await startStuff();
        toaster.notify("Your organization is syncing", {
          duration: 4000,
          position: "bottom",
        });
        await updateNavigation();
      }

      fetchOrgs();
      toggleModal(null);
    } catch (x) {
      toggleModal(null);
    }
  };

  const deleteOrg = async () => {
    toggleModal(null);
    let query = `?org_id=${isModal.id}`;

    try {
      let response = await deleteOrganization(query);

      if (response) {
        fetchOrgs();
        toaster.notify("Your organization has been deleted", {
          duration: 4000,
          position: "bottom",
        });
        updateNavigation();
      }

      fetchOrgs();
    } catch (err) {
      toggleModal(null);
    }
  };

  // alert(orgs.length)

  return (
    <div className="sync__layout">
      <Backdrop loading={loading} />
      <div style={{ width: "100px" }}>
        <MyDialog
          open={open}
          Close={handleClose}
          handleClose={handleClose}
          click={() => handleSync()}
          style={{ maxWidth: "100px" }}
        >
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              setOrg(
                onGetUnsynced.find((element) => element.name === newValue)
              );
              // I used local storage here because the setstate was not working

              localStorage.setItem(
                ORGS_KEY,
                JSON.stringify(
                  onGetUnsynced.find((element) => element.name === newValue)
                )
              );
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sync your organization"
                variant="outlined"
              />
            )}
          />
        </MyDialog>
      </div>
      {isModal.value ? (
        <ConfirmModal
          message={isModal.message}
          toggleModal={toggleModal}
          syncOrg={syncOrg}
          deleteOrg={deleteOrg}
        />
      ) : (
        ""
      )}
      {gdpr_modal.value && (
        <GDPRModal
          gdprModal={gdprModal}
          action={submitFrequency}
          the_data={gdpr_modal}
        />
      )}
      {org_modal.value && (
        <OrgUpdateModal
          setOrgUpdateModal={orgUpdateModal}
          action={submitOrgUpdate}
          the_data={org_modal}
        />
      )}
      <Summary
        click={handleClickOpen}
        synced={onGetSynced}
        orgs={orgs}
        spaceCount={spaceCount}
        appCount={appCount}
        itemsCount={itemsCount}
      />

      <SyncTable
        orgs={orgs}
        toggleModal={toggleModal}
        gdprModal={gdprModal}
        orgUpdateModal={orgUpdateModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.syncing.loading,
  onGetSynced: state.getALLsyncedOrgs.data ? state.getALLsyncedOrgs.data : [],
  onGetUnsynced: state.getALLUnsyncedOrgs.data
    ? state.getALLUnsyncedOrgs.data
    : [],
  syncing: state.syncing.data,
  syncingError: state.syncing.error,
  db_name: state.user.authUser.database
    ? state.user.authUser.database.database
    : null,
  orgs: state.sync.orgs,
  synced: state.sync.orgs ? state.sync.orgs.filter((item) => item.details) : [],
  unSyncedAccounts: state.sync.orgs
    ? state.sync.orgs.filter((item) => item.sync === 0)
    : [],
  spaceCount: state.sync.orgs
    ? state.sync.orgs.reduce((total, acc) => {
        if (acc.details) {
          if (acc.details.mongo_summary)
            return total + acc.details.mongo_summary.count.spaces;
        }

        return total;
      }, 0)
    : [],
  appCount: state.sync.orgs
    ? state.sync.orgs.reduce((total, acc) => {
        if (acc.details) {
          if (acc.details.mongo_summary)
            return total + acc.details.mongo_summary.count.apps;
        }

        return total;
      }, 0)
    : [],
  itemsCount: state.sync.orgs
    ? state.sync.orgs.reduce((total, acc) => {
        if (acc.details) {
          if (acc.details.mongo_summary)
            return total + acc.details.mongo_summary.count.items;
        }

        return total;
      }, 0)
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
  setNav: (data) => dispatch(setNav(data)),
  getAllPodioAccounts: (idToken) =>
    dispatch(actions.getAllPodioAccounts(idToken)),
  onFetchAllSyncedOrganizations: (token) =>
    dispatch(actions.getAllSyncedAccounts(token)),
  onFetchAllUnSyncedOrganizations: (token) =>
    dispatch(actions.getAllUnSyncedAccounts(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sync);
