import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import 'ag-grid-community/dist/styles/ag-theme-fresh.css';
import Pusher from "pusher-js";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "./HooksGrid.css";
import { AllModules } from "ag-grid-enterprise";

class LatestGridHooksClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoGroupColumnDef: { minWidth: 200 },
      columnDefs: [
        { headerName: "Exec. Time", field: "execution_time" },
        { headerName: "Mongo Id", field: "mongo_id" },
        { headerName: "User", field: "user" },
        { headerName: "Hook ID", field: "hook_id" },
        { headerName: "Outcome", field: "outcome" },
        { headerName: "Time", field: "time" },
        { headerName: "Type", field: "type" },
      ],
      rowData: [],
      defaultColDef: {
        flex: 1,
        minWidth: 140,
        resizable: true,
        filter: true,
        sortable: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
      },
    };
  }

  componentDidMount() {
    const pusher = new Pusher("91c3f23431d0a0e33eee", {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("2265081_hooks");

    channel.bind("UserEvent", (data) => {
      let newRow = this.state.rowData.map((item) => {
        if (item.hook_id === data.hook_id) item = { ...data };
        return item;
      });

      this.setState({ ...this.state, rowData: newRow });
    });

    this.setState({ ...this.state, rowData: this.props.hooksLog });
  }

  render() {
    return (
      <>
        <div style={{ width: "100%", height: "500px" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
              overflow: "hidden",
            }}
            className="ag-theme-balham-dark"
          >
            <AgGridReact
              modules={AllModules}
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              defaultColDef={this.state.defaultColDef}
              enableFilter={true}
              enableSorting={true}
              showToolPanel={true}
              rowGroupPanelShow="always"
              rowSelection="multiple"
              debug={true}
              sideBar={true}
              enableCellTextSelection={true}
              suppressAggFuncInHeader={true}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              enableRangeSelection={true}
              animateRows={true}
            ></AgGridReact>
          </div>
        </div>
      </>
    );
  }
}

export default LatestGridHooksClass;
