import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import InputText from "../../../components/atoms/InputText";
import Dropdown from "../../../components/atoms/Dropdown";
import CustomButton from "../../../components/atoms/CustomButton";
import {
  getOrgs,
  getSpaces,
  getApps,
  createTemplate,
} from "../../../redux/actions/print";
import { useDispatch, useSelector } from "react-redux";

const AddTemplate = () => {
  const dispatch = useDispatch();
  const organizatons = useSelector((state) => state.print.organisations);
  const spaces = useSelector((state) => state.print.spaces);
  const apps = useSelector((state) => state.print.apps);

  const [orgsData, setorgsData] = useState([]);
  const [spaceData, setspaceData] = useState([]);
  const [appData, setappData] = useState([]);
  const [templateName, settemplateName] = useState("");

  useEffect(() => {
    if (!organizatons.processed && !organizatons.success) {
      dispatch(getOrgs());
    }
  }, [dispatch, organizatons.processed, organizatons.success]);
  useEffect(() => {
    if (organizatons.success) {
      setorgsData(
        organizatons.data.map((item) => {
          return { value: item.org_id, label: item.name };
        })
      );
    }
  }, [organizatons.data, organizatons.success]);

  useEffect(() => {
    if (spaces.success) {
      setspaceData(
        spaces.data.map((item) => {
          return { value: item.space_id, label: item.name };
        })
      );
    }
  }, [spaces.data, spaces.success]);

  useEffect(() => {
    if (apps.success) {
      setappData(
        apps.data.map((item) => {
          return { value: item.app_id, label: item.url_label };
        })
      );
    }
  }, [apps.data, apps.success]);
  const workspaces = {
    helperText: "Select a Space",
    label: "Select a Space",
    list: [
      {
        value: "prodevs",
        label: "ProDevs",
      },
      {
        value: "thatapp",
        label: "ThatApp",
      },
    ],
  };
  const applications = {
    helperText: "Select an Application",
    label: "Select an Application",
    list: [
      {
        value: "prodevs",
        label: "ProDevs",
      },
      {
        value: "thatapp",
        label: "ThatApp",
      },
    ],
  };
  // const [selectedOrg, setselectedOrg] = React.useState("");
  // const [apps, setApps] = React.useState("prodevs");
  // const [spaces, setSpaces] = React.useState("prodevs");

  const handleOrgs = (event) => {
    dispatch(getSpaces({ id: event.target.value }));
  };

  const handleApps = (event) => {
    // setApps(event.target.value);
    dispatch(getApps({ id: event.target.value }));
  };

  return (
    <AddTemplate.Wrapper>
      <InputText
        label="Template Title"
        value={templateName}
        onChange={(e) => settemplateName(e.target.value)}
      />
      <div className="row">
        <div className="col-md-4">
          <Dropdown
            // value={orgs}
            function={handleOrgs}
            helperText="Select an Organization"
            label="Select an Organization"
            list={orgsData}
          />
        </div>
        <div className="col-md-4">
          <Dropdown
            // value={spaces}
            function={handleApps}
            helperText={workspaces.helperText}
            label={workspaces.label}
            list={spaceData}
          />
        </div>
        <div className="col-md-4">
          <Dropdown
            // value={apps}
            function={handleApps}
            helperText={applications.helperText}
            label={applications.label}
            list={appData}
          />
        </div>
      </div>
      <div className="button-container">
        <CustomButton
          className="action-button-tab add-button"
          onClick={() =>
            dispatch(
              createTemplate({
                file_title: templateName,
                title: templateName,
              })
            )
          }
        >
          Create Template
        </CustomButton>
      </div>
    </AddTemplate.Wrapper>
  );
};

AddTemplate.Wrapper = styled.div`
  background: #fff;
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 48px;
  width: 80%;
  margin: auto;
  .inputs:focus {
    border: 1px #cfcfcf solid !important;
    background: #f7f7f7 !important;
  }
  .text {
    width: 50%;
  }
  .row {
    margin-top: 50px;
  }
  .dropdown-input {
    width: 100%;
  }
  .button-container {
    padding-top: 88px;
    text-align: center;
  }
  .add-button {
    background: #4caf50;
  }
`;
export default AddTemplate;
