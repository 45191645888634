import React, { useEffect } from "react";
import Endpoint from "../../Iris/Endpoint";
import ItemFields from "./ItemFields";
import { connect } from "react-redux";

const CreateItem = (props) => {
  const { match } = props;
  const [fields, setFields] = React.useState(null);
  const user = props.user;

  useEffect(() => {
    let columnArray = [];

    Endpoint.singleApp(
      match.params.id,
      match.params.org_id,
      user.database.database
    )

      .then((response) => {
        response.data.data.map((item) => {
          return item.fields.map((item) => {
            if (item.status === "active") return columnArray.push(item);
            return "";
          });
        });
        setFields(columnArray);
      })
      .catch((res) => {
        props.history.push("/errors/error-404");
      });
  }, [match, user, props.history]);

  const backToGrid = () => {
    props.history.push(
      `/dashboard/app/${match.params.org_id}/${match.params.id}`
    );
  };

  return (
    <div className="file__haven">
      <div className="row mb-2">
        <div className="col-md-12 text-right">Create Item</div>
      </div>
      <div className="p-24">
        {fields ? (
          <ItemFields
            fields={fields}
            app={props.match.params.id}
            org={props.match.params.org_id}
            backToGrid={backToGrid}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(CreateItem);
