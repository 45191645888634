import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Sync from "../Sync/Sync";
import Haven from "../Haven/Haven";
import { Route, Switch, withRouter } from "react-router";
import RawData from "../Monitor/RawData/RawData";
import HowFilterWorks from "../Monitor/RawData/HowFilterWorks";
import Partner from "../Partner/Partner";
import Audit from "../Audit/Audit";
import Files from "../Haven/Files/File";
import Hooks from "../Hooks/Hooks";
import HookManager from "../Hooks/HookManager/HookManager";
import Items from "../Items/Items";
import CreateItem from "../Items/CreateItem/CreateItem";
import Live from "../Trail/Live";
import Accounts from "../UserInfo/Accounts";
import Plans from "../UserInfo/Plans";
import PlatformUsers from "../UserInfo/PlatformUsers";
import MyInvoices from "../UserInfo/MyInvoices";
import PrintTest from "../../containers/Print/PrintTest";
import {
  USER_ACCOUNT,
  PLANS,
  MY_INVOICES,
  PLATFORM_USERS,
  PRINT_DATA,
} from "../pagePath";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#F7F7F7",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header baropen={open} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/dashboard/sync" component={Sync} />
          <Route exact path="/dashboard/apps/:id" component={Audit} />
          <Route exact path="/dashboard/filters" component={HowFilterWorks} />

          <Route
            exact
            path="/dashboard/createitem/app/:id/:org_id"
            component={CreateItem}
          />
          <Route exact path="/dashboard/haven" component={Haven} />
          <Route exact path="/dashboard/raw-data" component={RawData} />
          <Route exact path="/dashboard/partners/apply" component={Partner} />
          <Route exact path="/dashboard/view/:org_id" component={Files} />
          <Route exact path="/dashboard/hooks" component={Hooks} />
          <Route exact path="/dashboard/manage-hook" component={HookManager} />
          <Route exact path="/dashboard/app/:org_id/:id" component={Items} />
          <Route exact path="/dashboard/delete/:org_id/:id" component={Items} />
          <Route exact path="/dashboard/test" component={Live} />
          <Route exact path={USER_ACCOUNT} component={Accounts} />
          <Route exact path={PLANS} component={Plans} />
          <Route exact path={MY_INVOICES} component={MyInvoices} />
          <Route exact path={PLATFORM_USERS} component={PlatformUsers} />
          <Route exact path={PRINT_DATA} component={PrintTest} />
        </Switch>
      </main>
    </div>
  );
};

export default withRouter(Dashboard);
