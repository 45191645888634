import React, { useEffect } from "react";
import styled from "@emotion/styled";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getTemplates } from "../../../redux/actions/print";

const Table = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);
  const templates = useSelector((state) => state.print.templates.data);

  console.log("templates:", templates);

  const handleClick = () => {
    return null;
  };

  const deleteFunction = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your template is safe :)",
            "error"
          );
        }
      });
  };

  const columns = [
    { title: "TITLE", field: "title" },
    { title: "PRINTS ", field: "prints" },
    { title: "CREATED AT", field: "created_at" },
    { title: "LAST UPDATED", field: "last_updated" },
    { title: "LAST PRINTED", field: "last_printed" },
  ];

  const data = [
    {
      title: "Invoicing",
      prints: "4 Prints",
      created_at: (
        <React.Fragment>
          <div>June 29, 2020</div>
          <span className="small">William Nwogbo</span>
        </React.Fragment>
      ),
      last_updated: (
        <React.Fragment>
          <div>July 02, 2020 13:13</div>
          <span className="small">William Nwogbo</span>
        </React.Fragment>
      ),
      last_printed: "July 02, 2020",
      // actions_field: <ActionButton className="action-button" />,
    },
    {
      title: "Test Template 1",
      prints: "2 Prints",
      created_at: (
        <React.Fragment>
          <span>June 29, 2020</span>
          <span className="small">William Nwogbo</span>
        </React.Fragment>
      ),
      last_updated: (
        <React.Fragment>
          <div>July 02, 2020 13:13</div>
          <span className="small">William Nwogbo</span>
        </React.Fragment>
      ),
      last_printed: "No Printing",
    },
    {
      title: "Test Template 2",
      prints: "0 Prints",
      created_at: (
        <React.Fragment>
          <div>June 29, 2020</div>
          <span className="small">William Nwogbo</span>
        </React.Fragment>
      ),
      last_updated: (
        <React.Fragment>
          <span>July 02, 2020 13:13</span>
          <br />
          <span className="small">William Nwogbo</span>
        </React.Fragment>
      ),
      last_printed: "No Printing",
    },
  ];

  console.log("Data", data);

  const options = {
    actionsColumnIndex: -1,
  };

  return (
    <Table.Wrapper>
      <MaterialTable
        title=""
        columns={columns}
        data={templates}
        options={options}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit Template",
            iconProps: { style: { color: "green" } },
            onClick: (event, rowData) => handleClick(),
          },
          {
            icon: "delete",
            iconProps: { style: { color: "firebrick" } },
            tooltip: "Delete Template",
            onClick: (event, rowData) => deleteFunction(),
            // onClick: (event, rowData) => handleClick(),
          },
        ]}
      />
    </Table.Wrapper>
  );
};

Table.Wrapper = styled.div`
  .small {
    color: #515151;
  }
  .action-button {
    /* cursor: pointer; */
  }
  table {
    th {
      font-weight: bold;
    }
    td {
      font-size: 14px !important;
    }
  }
`;

export default Table;
