import React, { useEffect, useState } from "react";

import "../index.css";
import Logo from "../../../assets/images/logosync.svg";

import { Link, useLocation } from "react-router-dom";
import { verifyEmail } from "../../../services/auth";
import Loader from "../../../components/Loader/Loader";

const VerifyEmail = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let split = location.search.split("&");
    let token = split[0].split("=")[1];
    let email = split[1].split("=")[1];

    verify(email, token);
  }, [location]);

  const verify = async (email, token) => {
    setLoading(true);

    try {
      let query = `?email=${email}&token=${token}`;
      let response = await verifyEmail(query);

      if (response.status) {
        setLoading(false);
        setSuccess(true);
      }
    } catch (x) {
      setLoading(false);
      setSuccess(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="auth">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="auth__sidebar">
            <div className="layer">
              <div className="custom__container__80">
                <img src={Logo} alt="logo" />
                <h1 className="title">
                  Welcome to <br /> ThatApp!
                </h1>
                <p className="description">
                  It's our job to ensure that you're never lost in the clouds.
                  Gain a refreshing perspective of your business processes and
                  strategic initiatives through the eyes of our skilled workflow
                  architects and system integrators.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="auth__component">
            <div className="custom__container__80">
              <div className="auth__form__container mt-5 text-center">
                {success ? (
                  <div className="verify__result">
                    <i className="far fa-check-circle"></i>
                    <h3>Your Email was verified successfully !</h3>
                    <Link to="/login">Log in to your account</Link>
                  </div>
                ) : (
                  <div className="verify__result">
                    <i className="far fa-times-circle"></i>
                    <h3>Your Email was not verified successfully !</h3>
                    <Link to="/account/verification ">
                      Resend Verification Link
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
