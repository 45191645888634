import React, { useEffect } from "react";
import Register from "./containers/Authentication/Register/Register";
import Login from "./containers/Authentication/Login/Login";
import ForgotPassword from "./containers/Authentication/ForgotPassword/ForgotPassword";
import VerifyEmail from "./containers/Authentication/VerifyEmail/VerifyEmail";
import Connector from "./containers/Connector/Connector";
import UnauthenticatedRoute from "./utils/UnauthenticatedRoute";
import Dashboard from "./containers/Dashboard/Dashboard";
import Iris from "./containers/Iris/Iris";
import IrisExperiment from "./containers/Iris/IrisExperiment";
import ChooseService from "./containers/ChooseService/ChooseService";
import AuthenticatedRoute from "./utils/AuthenticatedRoute";
import ConfirmEmail from "./containers/Authentication/ConfirmEmail/ConfirmEmail";
import { Switch } from "react-router";
import AuditServerSide from "./containers/Audit/AuditServerSide";
import Live from "./containers/Trail/Live";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import PodioAuthorizationError from "./containers/Authentication/ConfirmEmail/PodioAuthorizationError";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Authorizepass from "./containers/Authentication/Login/Authorizepass";
import ImitateAccount from "./containers/ImitateAccount";
import Connections from "./pages/AuthPages/Print/Connections";
import PrintDashboard from "./pages/AuthPages/Print/Dashboard";
import { CONNECT_PRINT, PRINT_HOME, PRINT_DATA } from "./containers/pagePath";
import PrintTest from "./containers/Print/PrintTest";
import { getMe } from "./redux/actions/user";
import { store } from "./redux/store";

const App = () => {
  useEffect(() => {
    if (store.getState().user.authUser.isLoggedIn) store.dispatch(getMe());
  });
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#fcaf3b",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: ["Mulish", "sans-serif", "Roboto"].join(","),
    },
  });

  return (
    <div>
      <ToastContainer />
      <MuiThemeProvider theme={theme}>
        <Switch>
          <UnauthenticatedRoute exact path="/" component={Login} />
          <UnauthenticatedRoute exact path="/register" component={Register} />
          <UnauthenticatedRoute exact path="/login" component={Login} />
          <UnauthenticatedRoute
            exact
            path="/authorizepass"
            component={Authorizepass}
          />
          <UnauthenticatedRoute
            exact
            path="/forgotpassword"
            component={ForgotPassword}
          />
          <UnauthenticatedRoute
            exact
            path="/podio-authorization-error"
            component={PodioAuthorizationError}
          />
          <UnauthenticatedRoute
            path="/account/verification"
            component={VerifyEmail}
          />
          <UnauthenticatedRoute
            path="/account/confirm-email"
            component={ConfirmEmail}
          />
          <UnauthenticatedRoute path="/test" component={Live} />
          <AuthenticatedRoute path="/connect/:id?" component={Connector} />
          <AuthenticatedRoute
            exact
            path={CONNECT_PRINT}
            component={Connections}
          />
          <AuthenticatedRoute
            exact
            path={PRINT_HOME}
            component={PrintDashboard}
          />
          <AuthenticatedRoute exact path={PRINT_DATA} component={PrintTest} />
          <AuthenticatedRoute exact path="/service" component={ChooseService} />
          <AuthenticatedRoute path="/dashboard" component={Dashboard} />
          <UnauthenticatedRoute
            path="/imitate-account"
            component={ImitateAccount}
          />
          <AuthenticatedRoute
            path="/audit/:orgId"
            component={AuditServerSide}
          />
          <AuthenticatedRoute path="/iris" component={Iris} />
          <AuthenticatedRoute path="/iris-test" component={IrisExperiment} />
        </Switch>
      </MuiThemeProvider>
    </div>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     user: state.user.authUser,
//     orgs: state.orgs,
//     isAuthenticated: state.user.authUser !== null,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     onTryAutoSignIn: (expirationDate, user) =>
//       dispatch(actions.authCheckState(expirationDate, user)),
//     setUser: (user) => dispatch(setUser(user)),
//     setAuditOrg: (data) => dispatch(setAuditOrg(data)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(App);
export default App;
