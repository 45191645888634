import React from "react";
import Confirm from "../../../../components/Modal/Confirm";
import { updateMemberRole } from "../../../../services/sync";
import toaster from "toasted-notes";

const SwitchRole = (props) => {
  const initial = {
    message: null,
    modal_type: null,
    title: "",
    modal_value: false,
    modal_data: {},
    popup: false,
  };
  const [syncModal, syncModalData] = React.useState(initial);

  //Action
  const handleSubmit = (type) => {
    updateMemberRole(
      `?org_id=${syncModal.modal_data.org}&space_id=${syncModal.modal_data.space_id}&user_id=${syncModal.modal_data.user_id}&role=${type}`
    )
      .then((r) => {
        toaster.notify("Changed successfully.", {
          duration: 4000,
          position: "bottom",
        });
        syncModalData(initial);
        props.setValue(type);
      })
      .catch((e) => {
        if (e.response.data.message === "PodioForbiddenError") {
          toaster.notify("You're not authorized to perform this action", {
            duration: 4000,
            position: "bottom",
          });
          syncModalData(initial);
          return;
        }

        syncModalData(initial);
      });
  };

  //Action Display
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  //Call the modals
  const changeRole = (org, space_id, user_id, role) => {
    syncModalData({
      message: `Do you want to make this member ${role.toUpperCase()}?`,
      modal_type: `${role}`,
      title: "Update Role",
      modal_value: true,
      modal_data: { org, space_id, user_id },
      popup: true,
    });
  };

  const toggleModal = () => {
    syncModalData(initial);
  };

  return (
    <>
      <Confirm
        className="confirm-modal-container"
        openModal={syncModal.modal_value}
        message={syncModal.message}
        action={() => handleSubmit(syncModal.modal_type)}
        title={syncModal.title}
        toggleModal={toggleModal}
      />
      {data !== null ? (
        props.colDef.headerName === "Admin" ? (
          data.role === "admin" ? (
            "Yes"
          ) : (
            <i
              className="fa fa-plus"
              style={{ fontSize: "1.50em", cursor: "pointer" }}
              title="Make Admin"
              onClick={() =>
                changeRole(data.org_id, data.space_id, data.user_id, "admin")
              }
            ></i>
          )
        ) : props.colDef.headerName === "Regular" ? (
          data.role === "regular" ? (
            "Yes"
          ) : (
            <i
              className="fa fa-plus"
              style={{ fontSize: "1.50em", cursor: "pointer" }}
              title="Make Regular"
              onClick={() =>
                changeRole(data.org_id, data.space_id, data.user_id, "regular")
              }
            ></i>
          )
        ) : props.colDef.headerName === "Light" ? (
          data.role === "light" ? (
            "Yes"
          ) : (
            <i
              className="fa fa-plus"
              style={{ fontSize: "1.50em", cursor: "pointer" }}
              title="Make Light"
              onClick={() =>
                changeRole(data.org_id, data.space_id, data.user_id, "light")
              }
            ></i>
          )
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};

export default SwitchRole;
