import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import "./Teams.css";
import TeamsList from "./TeamsList";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { sendInvite, getTeamMembers } from "../../redux/actions/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "relative",
  },
  loader: {
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    display: "grid",
    placeItems: "center",
  },
}));

const Teams = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();
  const invitation = useSelector((state) => state.user.invite);
  const teamMembers = useSelector((state) => state.user.teamMembers.data);
  const pendingInvitation = useSelector(
    (state) => state.user.pendingInvitation.data
  );

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getTeamMembers());
  }, [dispatch]);

  useEffect(() => {
    if (invitation.processing) {
      setOpen(false);
    }
  }, [invitation.processing]);

  const handleSend = () => {
    if (email !== "" && name !== "") {
      dispatch(sendInvite({ email, name }));
      // setOpen(false);
      setEmail("");
      setName("");
    } else {
      toast.error("Please fill input name and email");
    }
  };

  return (
    <div className="teams">
      <div className="header">
        <div className="left">
          <p>Members on your team</p>
          <p className="highlighted">{teamMembers.length} Members</p>
        </div>
        <div className="right">
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "4rem" }}
            onClick={handleOpen}
          >
            + &nbsp; &nbsp; Invite Members
          </Button>
        </div>
      </div>

      <div className="users">
        {teamMembers.map((item) => {
          return (
            <TeamsList
              key={item.email}
              image="https://i.stack.imgur.com/l60Hf.png"
              name={item.name}
              email={item.email}
              // role={item.role}
            />
          );
        })}

        {pendingInvitation.map((item) => {
          return (
            item.claimed_at === null && (
              <TeamsList
                key={item.email}
                image="https://i.stack.imgur.com/l60Hf.png"
                name={item.name}
                email={item.email}
                pending
                // role={item.role}
              />
            )
          );
        })}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {invitation.processing && (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            )}
            <h2 id="transition-modal-title">Invite a team Member</h2>
            <p id="transition-modal-description">
              Invite team members by email and choose their priviledges
            </p>
            <div className="input-fields-container">
              <TextField
                type="text"
                label="Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
              <TextField
                type="text"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                fullWidth
              />
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "4rem" }}
                onClick={handleSend}
              >
                Send Invite
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Teams;
