import React, { useState } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import Icon from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const MenuItem = (props) => {
  const {
    page,
    isSubMenu = false,
    onClick,
    isActive = false,
    showArrow,
  } = props;

  const { name, icon } = page;

  return (
    <MenuItem.Wrapper
      isActive={isActive}
      isSubMenu={isSubMenu}
      onClick={() => onClick(page)}
    >
      <Icon>{icon}</Icon>
      <div className="d-flex justify-content-between w-100">
        <span className="name-holder">{name}</span>
        {showArrow && <KeyboardArrowDownIcon />}
      </div>
    </MenuItem.Wrapper>
  );
};

MenuItem.Wrapper = styled.span`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 13px;
  border-radius: 0 24px 24px 0;
  padding: 11px 20px;
  .name-holder {
    margin-left: 2rem;
  }
  .show-arrow {
    display: none;
  }
  &:hover {
    background-color: #f7c948;
    cursor: pointer;
  }

  ${(props) =>
    props.isSubMenu &&
    css`
      padding-left: 20px;
    `}

  ${(props) =>
    props.isActive &&
    css`
      background-color: #f7c948;
      cursor: pointer;
    `}
`;

const SubMenu = (props) => {
  const [visible, setVisible] = useState(false);
  const { icon, name, pages, onSubMenuClick } = props;

  const itemPage = {
    name,
    icon,
    link: "",
  };

  const currentLocation = window.location.pathname;

  return (
    <React.Fragment>
      <MenuItem
        page={itemPage}
        showArrow
        onClick={() => setVisible(!visible)}
      />

      <SubMenu.Wrapper visible={visible}>
        {pages.map((page, index) => {
          const { name, icon, submenu } = page;

          return submenu === undefined ? (
            <MenuItem
              isActive={currentLocation.includes(page.link)}
              page={page}
              key={index}
              onClick={onSubMenuClick}
            />
          ) : (
            <SubMenu
              onSubMenuClick={onSubMenuClick}
              key={page.name}
              name={name}
              icon={icon}
              pages={submenu}
            />
          );
        })}
      </SubMenu.Wrapper>
    </React.Fragment>
  );
};

SubMenu.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  ${(props) =>
    props.visible &&
    css`
      max-height: 5000px;
      transition: max-height 0.25s ease-in;
    `}
`;

const SideBarHandler = (props) => {
  const { pages } = props;
  const history = useHistory();
  const currentLocation = window.location.pathname;
  const menuItemClickHandler = (page) => history.push(page.link);

  return (
    <div>
      <div className="items">
        {pages.map((page, index) => {
          const { name, icon, submenu } = page;

          return submenu === undefined ? (
            <MenuItem
              isActive={currentLocation.includes(page.link)}
              page={page}
              key={index}
              onClick={menuItemClickHandler}
            />
          ) : (
            <SubMenu
              onSubMenuClick={menuItemClickHandler}
              key={page.name}
              name={name}
              icon={icon}
              pages={submenu}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SideBarHandler;
