import React,{useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {getColumnsRequest,getDataSource} from '../../redux/actions/test';
import { AllModules } from "ag-grid-enterprise";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

const Live = () => {
  const formatData = (x) => {
    if(x.type === 'text') return x.values[0].value
    if(x.type === 'embed') return x.values[0].embed.title
    if(x.type === 'duration') return x.values[0].value
    if(x.type === 'video') return x.values[0].value
    if(x.type === 'location') return x.values[0].value
    if(x.type === 'progress') return x.values[0].value
    if(x.type === 'money') return x.values[0].value
    if(x.type === 'contact') return x.values[0].value.name
    if(x.type === 'member') return x.values[0].value
    if(x.type === 'app') return x.values[0].value.title
    if(x.type === 'date') return x.values[0].start_date
    if(x.type === 'image'){
        let images = [];
        x.values.forEach(image => {
            if(image.value.link){
                images.push(image.value.link);
            }
        });
        return images.toString();
    }
    if(x.type === 'number') return x.values[0].value

    if(x.type === 'link') {
        let links = [];
        x.values.forEach(link => {
           links.push(link.embed.url);
        });
        return links.toString();
    }

    if(x.type === 'email') {
        let emails = [];
        x.values.forEach(email => {
            if(email.value){
                emails.push(email.value);
            }
        });
        return emails.toString();
    }
    if(x.type === 'category') {
        return x.values[0].value.text
    }
    if(x.type === 'phone') return x.values[0].value
    if(x.type === 'calculation') return x.values[0].value
    if(x.type === 'tag') return x.values[0].value
    return "Not formatted"
};

  // -------Code starts here--------------
  const [theArray, setTheArray] = useState([]);
  const [theData, settheData] = useState([]);
  const addItem = (item) => {
      setTheArray(oldArray => [...oldArray, item]);
  };
      const dispatch = useDispatch();

      useEffect(() => {
        
      dispatch(getColumnsRequest());
      dispatch(getDataSource());

      }, [dispatch]);

   const liveDataState = useSelector((state) => state.liveData);
   const dataColumns = liveDataState.columns.data[0]?.fields
  //  const dataColumns = useSelector((state) => state.liveData.columns.data[0]?.fields);

   const dataSource = useSelector((state) => state.liveData.dataSource.data)
    // useEffect(() => {
    
    // }, [dataSource, liveDataState.dataSource.success])

    useEffect(() => {
    if (liveDataState.columns.success) {
      dataColumns.forEach(element => {
        addItem({headerName:element.label,field:element.external_id})
      });
    }
    //eslint-disable-next-line
    }, [liveDataState.columns.success, dataColumns])

    useEffect(() => {
      if (liveDataState.dataSource.success) {
        dataSource.forEach(rowItem => {
          const tempObj = {}
          settheData(oldArray => [...oldArray, tempObj]);
          rowItem.fields.forEach(element => {
            tempObj[`${element.external_id}`] = formatData(element)
          });
        });
      }
      //eslint-disable-next-line
      }, [liveDataState.dataSource.success, dataSource])

  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  //   setGridColumnApi(params.columnApi);

  //   const httpRequest = new XMLHttpRequest();
  //   const updateData = (data) => {
  //     var fakeServer = createFakeServer(data);
  //     var datasource = createServerSideDatasource(fakeServer);
  //   };

  //   httpRequest.open(
  //     'GET',
  //     'https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/olympicWinners.json'
  //   );
  //   httpRequest.send();
  //   httpRequest.onreadystatechange = () => {
  //     if (httpRequest.readyState === 4 && httpRequest.status === 200) {
  //       updateData(JSON.parse(httpRequest.responseText));
  //     }
  //   };
  // };

  const onGridReady = (params) => {
    // updateData();
    const fakeServer = createFakeServer(theData );
//       [{
// "deliverables-budget-2": "$null",
// "project-description": "<p>sdiohhsodhhs dhsdoihoichsd hsohco hsd</p><p><br /></p>",
// "project-owner": "Faith Dike",
// "project-title": "Medbury",
// "stage": "In progress",
// "start-and-finish-dates": "2018-09-01"
//       }]
   
    const datasource = createServerSideDatasource(fakeServer);
      params.api.setServerSideDatasource(datasource);
};

//   const columns = [
//     { headerName: 'athlete', field: 'athlete'},
//     { headerName: 'country', field: 'country'},
//     { headerName: 'sport', field: 'sport'},
//     { headerName: 'gold', field: 'gold'},
//     { headerName: 'silver', field: 'silver'},
//     { headerName: 'bronze', field: 'bronze'}
// ];
  return (
    <React.Fragment>
    this is new
    dgssdg
    sdfgdxhf
    dfsgddf
    <div style={{ width: '100%', height: '100vh' }}>
      <div
        id="myGrid"
        style={{
          height: '100%',
          width: '100%',
        }}
        className="ag-theme-alpine-dark"
      >
      {
        theData.length!==0 &&
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
          }}
          columnDefs={theArray}
          // columnDefs={columns}
          rowModelType='serverSide'
          onGridReady={onGridReady}
          modules={AllModules}
          animateRows={true}
          debug={true}
          enableRangeSelection={true}
          sideBar={true}
          suppressAggFuncInHeader={true}
          enableFilter={true}
          enableSorting={true}
          showToolPanel={true}
          rowGroupPanelShow="always"
          rowSelection="multiple"
        >
        
        </AgGridReact>
      }
      </div>
    </div>
    </React.Fragment>
  );
};

function createServerSideDatasource(server) {
  return {
    getRows: function (params) {
      var response = server.getData(params.request);
      setTimeout(function () {
        if (response.success) {
          params.successCallback(response.rows, response.lastRow);
        } else {
          params.failCallback();
        }
      }, 500);
    },
  };
}
function createFakeServer(allData) {
  return {
    getData: function (request) {
      var requestedRows = allData.slice(request.startRow, request.endRow);
      var lastRow = getLastRowIndex(request, requestedRows);
      return {
        success: true,
        rows: requestedRows,
        lastRow: lastRow,
      };
    },
  };
}
function getLastRowIndex(request, results) {
  if (!results) return undefined;
  var currentLastRow = request.startRow + results.length;
  return currentLastRow < request.endRow ? currentLastRow : undefined;
}

export default Live;



// import React, { useState } from 'react';
// import { AgGridReact, AgGridColumn } from 'ag-grid-react';
// import 'ag-grid-enterprise';
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

// const Live = () => {
//   const [gridApi, setGridApi] = useState(null);
//   const [gridColumnApi, setGridColumnApi] = useState(null);

//   const onGridReady = (params) => {
//     setGridApi(params.api);
//     setGridColumnApi(params.columnApi);

//     const httpRequest = new XMLHttpRequest();
//     const updateData = (data) => {
//       var fakeServer = createFakeServer(data);
//       var datasource = createServerSideDatasource(fakeServer);
//       params.api.setServerSideDatasource(datasource);
//     };

//     httpRequest.open(
//       'GET',
//       'https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/olympicWinners.json'
//     );
//     httpRequest.send();
//     httpRequest.onreadystatechange = () => {
//       if (httpRequest.readyState === 4 && httpRequest.status === 200) {
//         updateData(JSON.parse(httpRequest.responseText));
//       }
//     };
//   };

//   return (
//     <div style={{ width: '100%', height: '100vh' }}>
//       <div
//         id="myGrid"
//         style={{
//           height: '100%',
//           width: '100%',
//         }}
//         className="ag-theme-alpine-dark"
//       >
//         <AgGridReact
//           defaultColDef={{
//             flex: 1,
//             minWidth: 100,
//           }}
//           rowModelType={'serverSide'}
//           onGridReady={onGridReady}
//         >
//           <AgGridColumn field="athlete" minWidth={220} />
//           <AgGridColumn field="country" minWidth={200} />
//           <AgGridColumn field="year" />
//           <AgGridColumn field="sport" minWidth={200} />
//           <AgGridColumn field="gold" />
//           <AgGridColumn field="silver" />
//           <AgGridColumn field="bronze" />
//         </AgGridReact>
//       </div>
//     </div>
//   );
// };

// function createServerSideDatasource(server) {
//   return {
//     getRows: function (params) {
//       var response = server.getData(params.request);
//       setTimeout(function () {
//         if (response.success) {
//           params.successCallback(response.rows, response.lastRow);
//         } else {
//           params.failCallback();
//         }
//       }, 500);
//     },
//   };
// }
// function createFakeServer(allData) {
//   return {
//     getData: function (request) {
//       var requestedRows = allData.slice(request.startRow, request.endRow);
//       var lastRow = getLastRowIndex(request, requestedRows);
//       return {
//         success: true,
//         rows: requestedRows,
//         lastRow: lastRow,
//       };
//     },
//   };
// }
// function getLastRowIndex(request, results) {
//   if (!results) return undefined;
//   var currentLastRow = request.startRow + results.length;
//   return currentLastRow < request.endRow ? currentLastRow : undefined;
// }

// export default Live;