import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { AllModules } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
import { getInvoicesRequest } from "../../redux/actions/test";
import { useDispatch, useSelector } from "react-redux";
import InvoiceAction from "./InvoiceAction";

const Invoices = () => {
  const filterParams = {
    filterOptions: ["contains", "notContains"],
    textFormatter: function (r) {
      if (r == null) return null;
      r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
      r = r.replace(new RegExp("æ", "g"), "ae");
      r = r.replace(new RegExp("ç", "g"), "c");
      r = r.replace(new RegExp("[èéêë]", "g"), "e");
      r = r.replace(new RegExp("[ìíîï]", "g"), "i");
      r = r.replace(new RegExp("ñ", "g"), "n");
      r = r.replace(new RegExp("[òóôõøö]", "g"), "o");
      r = r.replace(new RegExp("œ", "g"), "oe");
      r = r.replace(new RegExp("[ùúûü]", "g"), "u");
      r = r.replace(new RegExp("[ýÿ]", "g"), "y");
      return r;
    },
    debounceMs: 0,
    caseSensitive: true,
    suppressAndOrCondition: true,
  };
  const dispatch = useDispatch();
  const [invoices, setInvoices] = React.useState({
    columns: [
      // {
      //   headerName: "Extension",
      //   field: "extension",
      //   rowGroup: true,
      //   hide: true,
      // },
      {
        headerName: "Menu",
        width: 30,
        field: "menu",
        cellRenderer: "viewAction",
      },
      {
        headerName: "Billing Start",
        field: "start_date",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Billing End",
        field: "end_date",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Total Cost",
        field: "total_cost",
        filter: "agTextColumnFilter",
        filterParams,
      },
      // { headerName: 'Url', field: 'url', filter: 'agTextColumnFilter', filterParams},
      {
        headerName: "Payment Status",
        field: "status",
        filter: "agTextColumnFilter",
        filterParams,
      },
    ],
    data: [],
  });

  const invoiceState = useSelector((state) => state.liveData.invoices);

  useEffect(() => {
    dispatch(getInvoicesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (invoiceState.success) {
      invoiceState.data.forEach((Ddata) => {
        setInvoices((prevState) => {
          const data = [...prevState.data];

          data.push({
            total_cost: "$" + Ddata.total_cost,
            start_date: Ddata.start_date,
            end_date: Ddata.end_date,
            status: Ddata.status === 1 ? "PAID" : "NOT PAID",
            encryption_id: Ddata.encrypted_id,
          });
          return { ...prevState, data };
        });
      });
    } else {
      setAgrid("No data rows");
    }
  }, [invoiceState.data, invoiceState.success]);

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const autoGroupColumnDef = { minWidth: 200 };
  const frameworks = {
    viewAction: InvoiceAction,
    // viewAction: null,
  };

  const [, setAgrid] = React.useState("Loading...");

  setTimeout(function () {
    setAgrid("No Data");
  }, 3000);

  return (
    <React.Fragment>
      <div className="flex flex-1 items-center justify-between p-24">
        <div className="flex flex-col">
          <Typography variant="h6" style={{ marginBottom: "2rem" }}>
            Invoices
          </Typography>
        </div>
      </div>
      <div className="p-24">
        <div
          className="ag-theme-balham-dark"
          style={{
            height: "700px",
            width: "100%",
          }}
        >
          <AgGridReact
            modules={AllModules}
            columnDefs={invoices.columns}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            frameworkComponents={frameworks}
            enableRangeSelection={true}
            animateRows={true}
            rowData={invoices.data}
            // overlayNoRowsTemplate={grid_loading}

            rowGroupPanelShow="always"
            rowSelection="multiple"
            debug={true}
            sideBar={true}
            enableCellTextSelection={true}
            suppressAggFuncInHeader={true}
            enableFilter={true}
            enableSorting={true}
            showToolPanel={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Invoices;
