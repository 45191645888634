import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import RevisionIcon from "@material-ui/icons/AssignmentInd";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Endpoint from "../../Iris/Endpoint";
import { Alert } from "@material-ui/lab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RestoreIcon from "@material-ui/icons/Restore";
import "./style.css";

const GridActions = (props) => {
  const [spaceSync, syncModalData] = React.useState({
    message: null,
    modal_type: null,
    modal_value: false,
    modal_data: {},
  });
  const [snack, setSnack] = React.useState({
    message: null,
    snackType: null,
    isSnack: false,
    reload: false,
  });

  //Action Display
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  //opens the modal for confirmation to delete item
  const deleteItemModal = (org, item_id) => {
    syncModalData({
      message: "Do you want to remove this item from Podio?",
      modal_type: "remove_item",
      modal_value: true,
      modal_data: { org, item_id },
    });
  };

  const restoreItemModal = (org, item_id) => {
    syncModalData({
      message: "Do you want to restore this item to Podio?",
      modal_type: "restore_item",
      modal_value: true,
      modal_data: { org, item_id },
    });
  };

  const deleteItem = (org, item_id) => {
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
    const data = {
      item_id,
      org_id: org,
    };

    Endpoint.deleteItem(data)
      .then(() => {
        setSnack({
          message: "Deleted successfully",
          snackType: "success",
          isSnack: true,
          reload: true,
        });
      })
      .catch(() => {
        setSnack({
          message: "Failed to delete, try again later!",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
      });
  };

  const restoreItem = (org, item_id) => {
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
    const data = {
      item_id,
      org_id: org,
    };

    Endpoint.restoreItem(data)
      .then(() => {
        setSnack({
          message: "Restored successfully",
          snackType: "success",
          isSnack: true,
          reload: true,
        });
      })
      .catch(() => {
        setSnack({
          message: "Failed to restore, try again later!",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
      });
  };

  const confirm_handleClose = () => {
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  };

  const handleClose = () => {
    if (snack.reload) window.history.go();
    setSnack({ message: "", snackType: "", isSnack: false, reload: false });
  };

  // revision data
  const [revisionData, setRevisionData] = React.useState({
    message: null,
    modal_type: null,
    modal_value: false,
    modal_data: {},
  });

  const revisionModal = (org, name, date, image) => {
    setRevisionData({
      name: name,
      date: date,
      image: image,
      modal_type: "remove_item",
      modal_value: true,
      modal_data: { org, name, date, image },
    });
  };

  const closeRevisionData = () => {
    setRevisionData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  };

  if (!data) {
    return <React.Fragment></React.Fragment>;
  }

  let revision_data_list = null;

  if (data.revision_data) {
    revision_data_list = data.revision_data
      .slice(0)
      .reverse()
      .map((item, list) => {
        const revision_data_date = item.created_on;
        const revision_date = revision_data_date.substring(0, 10);
        const revision_time = revision_data_date.substring(
          revision_data_date.length - 8,
          revision_data_date.length
        );

        return (
          <div className="review-cards" key={list}>
            <CardMedia
              component="img"
              alt="User Image"
              image={
                item.created_by.image.length < 1
                  ? "https://www.gnd.center/bpm/resources/img/avatar_placeholder_small.png"
                  : item.created_by.image.thumbnail_link
              }
              title="User Image"
              className="cardMedia"
            />
            <div className="revision-info">
              <div id="form-dialog-title" className="revision-modal-name">
                {item.created_by.name}
              </div>
              <DialogContent className="revision-time-stamp">
                {
                  <DialogContentText>
                    Created on <b>{revision_date}</b> at <b>{revision_time}</b>
                  </DialogContentText>
                }
              </DialogContent>
            </div>
          </div>
        );
      });
    // end revision data
  }

  return (
    <>
      {data !== null && data.check_deletion === "false" ? (
        <React.Fragment>
          <Tooltip title="Delete from Podio" style={{ marginTop: "-10px" }}>
            <IconButton
              onClick={() => deleteItemModal(data._org_id, data._item_id)}
              aria-label="Delete from Podio"
            >
              <DeleteIcon style={{ color: "#d11a2a" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Item on Podio" style={{ marginTop: "-10px" }}>
            <IconButton
              aria-label="View Item on Podio"
              onClick={() => {
                window.open(data.item_link, "_blank");
              }}
            >
              <VisibilityIcon style={{ color: "#4cbcd9" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Revision" style={{ marginTop: "-10px" }}>
            <IconButton
              onClick={() => {
                if (data.revision_data) {
                  revisionModal(
                    data.revision_data,
                    data.revision_data[0].created_by.name,
                    data.revision_data[0].created_on,
                    data.revision_data[0].created_by.image.thumbnail_link
                  );
                } else {
                  setSnack({
                    message: "There is no revision for this item.",
                    snackType: "error",
                    isSnack: true,
                    reload: false,
                  });
                }
              }}
              aria-label="View Revision"
            >
              <RevisionIcon style={{ color: "#4cbcd9" }} />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ) : data !== null && data.check_deletion === "true" ? (
        <React.Fragment>
          {
            // <input
            //     type="checkbox"
            //     onClick={checkedHandler}
            //     checked={props.value}
            //   />
          }
          <Tooltip title="Restore Item" style={{ marginTop: "-10px" }}>
            <IconButton
              aria-label="Restore Item"
              onClick={() => restoreItemModal(data._org_id, data._item_id)}
            >
              <RestoreIcon style={{ color: "#FFF" }} />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ) : (
        ""
      )}
      <Dialog
        open={snack.isSnack}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Alert onClose={handleClose} severity={snack.snackType}>
          {snack.message}
        </Alert>
      </Dialog>
      <Dialog
        open={spaceSync.modal_value}
        onClose={confirm_handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Audit Organization</DialogTitle>
        <DialogContent>
          <DialogContentText>{spaceSync.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirm_handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={
              spaceSync.modal_type === "remove_item"
                ? () =>
                    deleteItem(
                      spaceSync.modal_data.org,
                      spaceSync.modal_data.item_id
                    )
                : () =>
                    restoreItem(
                      spaceSync.modal_data.org,
                      spaceSync.modal_data.item_id
                    )
            }
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={revisionData.modal_value}
        onClose={closeRevisionData}
        aria-labelledby="form-dialog-title"
        className="revisionData MuiDialog-paperWidthLg"
      >
        <h1 className="revision-modal-header">Review</h1>
        <div className="review-container">
          {revision_data_list && revision_data_list}
        </div>
        <DialogActions>
          <Button onClick={closeRevisionData} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GridActions;
