import React from "react";
import "../Haven.css";
import { connect } from "react-redux";
import FileGrid from "./FileGrid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { Alert } from "@material-ui/lab";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import { getOrganization, syncFileHaven } from "../../../services/sync";

const Files = (props) => {
  const [org, setOrg] = React.useState(null);
  const [spaceSync, syncModalData] = React.useState({
    message: null,
    modal_type: null,
    modal_value: false,
    modal_data: {},
  });
  const [snack, setSnack] = React.useState({
    message: null,
    snackType: null,
    isSnack: false,
    reload: false,
  });
  const [preloader, setPreloader] = React.useState(false);

  const [files] = React.useState({
    columns: [
      {
        headerName: "Menu",
        width: 30,
        field: "menu",
        cellRenderer: "downloadAction",
        sortable: false,
        filter: false,
        enableRowGroup: false,
      },
      { headerName: "App", field: "app_name" },
      { headerName: "Item Title", field: "item_title" },
      { headerName: "File Id", field: "file_id" },
      { headerName: "Name", field: "name" },
      { headerName: "Size", field: "size" },
      { headerName: "Mime Type", field: "mimetype" },
      { headerName: "Link", field: "link" },
      { headerName: "Created By", field: "created_by" },
      { headerName: "Hosted By", field: "hosted_by" },
    ],
    data: [],
  });

  const [sync_status, setSyncStatus] = React.useState(0);
  const [sync_status_text, setSyncStatusText] = React.useState(null);
  const F_INTERVAL_KEY = "F_IntervalId";

  const confirm_handleClose = () => {
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  };

  const handleClose = () => {
    if (snack.reload) window.history.go();
    setSnack({ message: "", snackType: "", isSnack: false, reload: false });
  };

  React.useEffect(() => {
    getOrganization(props.match.params.org_id).then((res) => {
      setOrg(res.data);
      if ("undefined" !== typeof res.data["mongo_summary"])
        setSyncStatus(res.data.mongo_summary.count.files);
    });
  }, [props.match.params.org_id]);

  function checkIfOrgIsDoneSyncing() {
    if (!props.match.params.org_id) return false;
    getOrganization(props.match.params.org_id).then((res) => {
      const status = res.data.status;

      if ("undefined" !== typeof res.data["mongo_summary"]) {
        setSyncStatus(res.data.mongo_summary.count.files);
        setSyncStatusText(status);
      }

      setTimeout(() => {
        if (status === "Done" || status === "N'SYNC") {
          clearInterval(localStorage.getItem(F_INTERVAL_KEY));
          localStorage.removeItem(F_INTERVAL_KEY);
        }
      }, 2000);
    });
  }

  const startStuff = async () => {
    const intervalId = setInterval(function startSyncing() {
      checkIfOrgIsDoneSyncing();
    }, 2000);

    localStorage.setItem(F_INTERVAL_KEY, intervalId);
  };

  function resyncFileHaven() {
    let query = `?org_id=${props.match.params.org_id}&org_name=${org.org_name}&file_syncing=1`;

    setPreloader(true);
    syncFileHaven(query)
      .then(() => {
        confirm_handleClose();
        setSnack({
          message: "Syncing has started successfully",
          snackType: "success",
          isSnack: true,
        });
        startStuff();
        setPreloader(false);
      })
      .catch(() => {
        confirm_handleClose();
        setPreloader(false);
        setSnack({
          message: "Failed to resync",
          snackType: "error",
          isSnack: true,
        });
      });
  }

  if (preloader) {
    return (
      <div className="material_block">
        <svg
          className="spinner"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle"
            fill="#fff"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    );
  }

  return (
    <div className="file__haven">
      <div className="row mb-2">
        <div
          className="col-md-12 text-left"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>
            {" "}
            {org !== null &&
              org.org_name + "(" + props.match.params.org_id + ")"}
          </span>
          <br />
          <span>
            <p>
              <b>Total synced files: {sync_status}</b>
            </p>
            {sync_status_text && (
              <p>
                <b>Status: Done</b>
              </p>
            )}
          </span>
          <br />
          <div style={{ float: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setPreloader(true);
                setTimeout(() => {
                  setPreloader(false);
                }, 5000);
              }}
            >
              Refresh Grid
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              variant="contained"
              color="primary"
              onClick={() => {
                syncModalData({
                  message: "Are you about resyncing file haven?",
                  modal_type: "filehaven",
                  modal_value: true,
                  modal_data: {},
                });
              }}
            >
              Resync Files
            </Button>
          </div>
        </div>
      </div>
      {files.columns ? (
        <FileGrid
          org_id={props.match.params.org_id}
          user={props.user}
          files={files}
        />
      ) : (
        ""
      )}

      <Dialog
        open={snack.isSnack}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Alert onClose={handleClose} severity={snack.snackType}>
          {snack.message}
        </Alert>
      </Dialog>

      <Dialog
        open={spaceSync.modal_value}
        onClose={confirm_handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Resync</DialogTitle>
        <DialogContent>
          <DialogContentText>{spaceSync.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirm_handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={() => resyncFileHaven()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(Files);
