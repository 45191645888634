import React from "react";

import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader__page">
      <div className="material_block">
        <svg
          className="spinner"
          width="65px"
          stroke="#F0B429"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle"
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    </div>
  );
};

export default Loader;
