import React from "react";
import { AgGridReact } from "ag-grid-react";
import { AllModules } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import ActionsRenderer from "../AuditGrids/actions/ActionsRenderer";
import Axios from "../../../config";

const Spaces = ({ org_id }) => {
  const filterParams = {
    filterOptions: ["contains", "notContains"],
    textFormatter: function (r) {
      if (r == null) return null;
      r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
      r = r.replace(new RegExp("æ", "g"), "ae");
      r = r.replace(new RegExp("ç", "g"), "c");
      r = r.replace(new RegExp("[èéêë]", "g"), "e");
      r = r.replace(new RegExp("[ìíîï]", "g"), "i");
      r = r.replace(new RegExp("ñ", "g"), "n");
      r = r.replace(new RegExp("[òóôõøö]", "g"), "o");
      r = r.replace(new RegExp("œ", "g"), "oe");
      r = r.replace(new RegExp("[ùúûü]", "g"), "u");
      r = r.replace(new RegExp("[ýÿ]", "g"), "y");
      return r;
    },
    debounceMs: 0,
    caseSensitive: true,
    suppressAndOrCondition: true,
  };

  const columns = [
    { headerName: "Space Name", field: "space", rowGroup: true, hide: true },
    {
      headerName: "Apps",
      field: "app",
      filter: "agTextColumnFilter",
      filterParams,
    },
    {
      headerName: "App Id",
      field: "app_id",
      filter: "agTextColumnFilter",
      filterParams,
    },
    // { headerName: 'Space Id', hide: false, field: 'space_id', filter: 'agTextColumnFilter', filterParams},
    {
      headerName: "Number of Items",
      field: "items_count",
      filter: "agTextColumnFilter",
      filterParams,
    },
    {
      headerName: "Number of Fields",
      field: "fields",
      filter: "agTextColumnFilter",
      filterParams,
    },
    {
      headerName: "Last synced",
      field: "last_sync",
    },
    { headerName: "Action", field: "actions", cellRenderer: "actionsRenderer" },
  ];

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const rowModelType = "serverSide";

  const frameworks = {
    actionsRenderer: ActionsRenderer,
  };

  const ServerSideDatasource = () => {
    return {
      getRows: function (params) {
        let group = "";
        let query = "";

        if (
          Array.isArray(params.request.groupKeys) &&
          params.request.groupKeys.length
        ) {
          group = `space_id=${params.parentNode.data.space_id}`;
          query = `${query}&${group}`;
        }

        if (group === "") {
          Axios.get(`/sync/podio/audit/spaces?org_id=${org_id}${query}`)
            .then((response) => {
              const rowData = [];

              response.data.map((item, i) => {
                return rowData.push({
                  space: item.space_name,
                  app: item.apps,
                  app_id: "N/A",
                  space_id: item.space_id,
                  fields: item.fields,
                  files: item.files,
                  org_id: org_id,
                  items_count: item.items_count,
                  deleted_from_podio: "false",
                  deleted_space_from_podio: item.deleted_space_from_podio,
                  point: "space",
                  last_sync: item.synced_at,
                });
              });
              setTimeout(function () {
                params.successCallback(rowData, rowData.length);
              }, 200);
            })
            .catch(() => {
              params.failCallback();
            });
        } else {
          Axios.get(`/sync/podio/audit/apps?org_id=${org_id}&${query}`)
            .then((response) => {
              const rowData = [];

              response.data.map((item) => {
                return rowData.push({
                  app: item.app,
                  app_id: item.app_id,
                  space_id: item.space_id,
                  fields: item.fields,
                  files: item.files,
                  org_id: org_id,
                  items_count: item.items_count,
                  deleted_from_podio: item.deleted_from_podio,
                  deleted_space_from_podio: "false",
                  point: "app",
                  last_sync: item.synced_at,
                });
              });
              setTimeout(function () {
                params.successCallback(rowData, rowData.length);
              }, 200);
            })
            .catch(() => {
              params.failCallback();
            });
        }
      },
    };
  };

  const onGridReady = (params) => {
    const datasource = ServerSideDatasource();

    params.api.setServerSideDatasource(datasource);
  };

  return (
    <div
      className="ag-theme-balham-dark"
      style={{
        height: "700px",
        width: "100%",
      }}
    >
      <AgGridReact
        modules={AllModules}
        columnDefs={columns}
        onGridReady={onGridReady}
        frameworkComponents={frameworks}
        animateRows={true}
        debug={true}
        enableRangeSelection={true}
        sideBar={true}
        suppressAggFuncInHeader={true}
        enableFilter={true}
        enableSorting={true}
        showToolPanel={true}
        defaultColDef={defaultColDef}
        rowModelType={rowModelType}
        rowGroupPanelShow="always"
        rowSelection="multiple"
      ></AgGridReact>
    </div>
  );
};

export default Spaces;
