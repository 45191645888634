import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import SideBarHandler from "./SideBarHandler";
import Illus from "../../assets/images/Illus.svg";
import Button from "@material-ui/core/Button";

const OrganizationNavFunction = ({ nav }) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (nav.children.length) {
            buildNav(nav.children);
        } else {
            setItems([]);
        }
        buildNav(nav.children);
    }, [nav]);

    function buildNav(data) {
        let nav = data.map((obj, i) => {
            return {
                id: i + 1,
                title: "",
                items: [
                    {
                        id: obj.id + 1,
                        icon: "dashboard",
                        name: obj.title,
                        submenu: obj.children.map((child, i) => {
                            return {
                                id: i + 1,
                                icon: "",
                                name:
                                    child.title.length > 12
                                        ? child.title.substring(0, 12) + "..."
                                        : child.title,
                                submenu: child.children.map((item, i) => {
                                    const theData = JSON.parse(item.other_data);

                                    return {
                                        id: i + 1,
                                        name: item.title,
                                        icon: "",
                                        link: `/dashboard/app/${theData.org_id}/${theData.app_id}`,
                                    };
                                }),
                            };
                        }),
                    },
                ],
            };
        });
        setItems(nav);
    }

    return (
        <div>
            {
                items.length > 0 ? items.map((item) => {
                    return <SideBarHandler key={item.id} pages={item.items} />;
                })
                    :
                    <div>
                    <img src={Illus} alt="Orgs Illustration" style={{marginTop: "10px", marginLeft: "10px"}} />
                        <p style={{textAlign: "center", marginTop: "10px", fontSize: "15px"}}><b>Start Syncing an organization</b></p>
                    <p style={{textAlign: "center"}}>Add an organization to start <br />syncing now.</p>
                        <Button
                            style={{marginLeft: "30px", textTransform: "capitalize"}}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                window.document.getElementById("syncAnOrgButton").click();
                            }}
                        >
                            Sync an Organization
                        </Button>
                    </div>
            }
        </div>
    );

}

const mapStateToProps = (state) => ({
    nav: state.nav.nav
});

export default connect(mapStateToProps, null)(OrganizationNavFunction);