import React from "react";
import EmptyImage from "../assets/images/Group 11732.svg";
import styled from "@emotion/styled";
import {Link} from 'react-router-dom'

function EmptyState() {
  return (
    <Empty>
      <img src={EmptyImage} alt="" className="empty-state" />
      <h2>You have not synced an Organization yet!</h2>
      <Link to="/dashboard/sync">
        <button className="sync__btn">
          Sync an Organization
        </button>
      </Link>
      
    </Empty>
  );
}

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  h2 {
    margin: 2rem auto;
    color: #292d61;
    font-size: 1.6rem;
  };
  button {
    color: #fff;
    background: #292d61;
    border-radius: 6px;
    border:none;
    padding: 15px 30px;
  }
`;

export default EmptyState;
