import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { UseForm } from "../../../services/UseForm";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { createItem } from "../../../services/sync";

const ItemFields = (props) => {
  const [values, handleChange] = UseForm({});
  const [err, setErr] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [button, setButton] = React.useState(false);
  const jsxFields = [];
  let externalIds = [];
  let types = [];
  // let category_ids = [];

  const onSubmit = (e) => {
    e.preventDefault();
    setButton(true);

    if (!values) {
      setButton(false);
      setErr(true);
      setSuccess(false);
      return false;
    }

    let allValues = [];

    externalIds.forEach((element) => {
      const val = values[element];

      allValues.push(val);
    });

    const data = {
      app_id: props.app,
      external_id: externalIds,
      type: types,
      value: allValues,
      field_category_id: allValues,
    };

    createItem(data)
      .then((res) => {
        setButton(false);
        setErr(false);
        setSuccess(true);
      })
      .catch((error) => {
        externalIds.splice(0, externalIds.length);
        types.splice(0, types.length);
        setSuccess(false);
        setButton(false);
        setErr(true);
      });
  };

  props.fields.forEach(function (item) {
    const item_name = item.external_id;

    externalIds.push(item_name);
    types.push(item.type);

    if (item.type === "category") {
      let categories = [];
      let catText = [];

      item.config.settings.options.forEach((element) => {
        if (!catText.includes(element.text)) {
          catText.push(element.text);
          return categories.push(
            <MenuItem value={element.id}>{element.text}</MenuItem>
          );
        }
      });

      return jsxFields.push(
        <Grid item xs={12} sm={6}>
          <InputLabel id="demo-simple-select-label">{item.label}</InputLabel>
          <Select
            fullWidth
            label={item.label}
            margin="normal"
            name={item_name}
            value={values[item_name]}
            onChange={handleChange}
          >
            {categories}
          </Select>
        </Grid>
      );
    } else if (item.type === "date") {
      return jsxFields.push(
        <Grid item xs={12} sm={6}>
          <TextField
            id="standard-full-width"
            label={item.label}
            placeholder={item.label}
            fullWidth
            type="date"
            margin="normal"
            name={item_name}
            value={values[item_name]}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );
    } else if (item.type === "money") {
      return jsxFields.push(
        <Grid item xs={12} sm={6}>
          <TextField
            id="standard-full-width"
            label={item.label}
            placeholder={item.label}
            fullWidth
            type="number"
            margin="normal"
            name={item_name}
            value={values[item_name]}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );
    } else if (item.type === "image") {
      return jsxFields.push(
        <Grid item xs={12} sm={6}>
          <TextField
            id="standard-full-width"
            label={item.label}
            placeholder="Image Url"
            fullWidth
            type="text"
            margin="normal"
            name={item_name}
            value={values[item_name]}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );
    } else {
      return jsxFields.push(
        <Grid item xs={12} sm={6}>
          <TextField
            id="standard-full-width"
            label={item.label}
            placeholder={item.label}
            fullWidth
            margin="normal"
            name={item_name}
            value={values[item_name]}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );
    }
  });

  return (
    <>
      {err || success ? (
        <Grid container spacing={3}>
          {success ? (
            <Grid item xs={12} sm={6}>
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Item created successfully!
              </Alert>
            </Grid>
          ) : (
            ""
          )}
          {err ? (
            <Grid item xs={12} sm={6}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Error creating this Item, check your details and try again!
              </Alert>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      ) : (
        ""
      )}
      <form onSubmit={onSubmit}>
        <Grid container spacing={3}>
          {jsxFields}
        </Grid>
        <br />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={button}
        >
          Save
        </Button>
        &nbsp;&nbsp;
        <Button variant="contained" color="default" onClick={props.backToGrid}>
          Cancel
        </Button>
      </form>
    </>
  );
};

export default ItemFields;
