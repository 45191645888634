import React from "react";
import "./Haven.css";
import HavenGrid from "./Grids/Grid";
import { setOrgs } from "../../redux/actions/sync";
import { connect } from "react-redux";
import Config from "../../utils/Config";
import { getSyncedOrganizations, setupHaven } from "../../services/sync";
import toaster from "toasted-notes";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import EmptyState from "../../components/EmptyState";
import Button from "@material-ui/core/Button";

const Haven = ({ _orgs }) => {
  const [haven, setHaven] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [confirmOpen, setConfirmOpen] = React.useState({
    open: false,
    data: null,
  });

  React.useEffect(() => {
    let havenArray = [];
    let orgs;

    if (!Config.isEmpty(_orgs)) {
      orgs = _orgs;
    } else {
      getSyncedOrganizations().then((res) => {
        orgs = res.data;
        setOrgs(res.data);
      });
    }

    if (orgs && Array.isArray(orgs)) {
      orgs.forEach((data) => {
        const org_name = data.name;
        const org_id = data.org_id;
        const file_haven = data.details ? data.details.file_haven : null;

        havenArray.push({
          id: org_id,
          organization: org_name,
          haven: file_haven === null ? "Unprotected" : "Protected",
          _hidden_status: file_haven,
        });
      });
      setLoading(false);
    } else {
      setLoading(false);
    }

    setHaven(havenArray);
    //eslint-disable-next-line
  }, []);

  const confirm_handleClose = () => {
    setConfirmOpen({ open: false, data: null });
  };

  const confirmProtectOrgs = () => {
    haven.forEach((r) => {
      setupHaven(`?org_id=${r.id}`)
        .then(() => {
          toaster.notify(
            r.organization + " haven has been activated successfully",
            {
              duration: 4000,
              position: "bottom",
            }
          );
          let havenArray = [];

          getSyncedOrganizations().then((_r) => {
            const orgs = _r.data;

            if (orgs && Array.isArray(orgs)) {
              orgs.forEach((data) => {
                const org_name = data.name;
                const org_id = data.org_id;
                const file_haven = data.details
                  ? data.details.file_haven
                  : null;

                havenArray.push({
                  id: org_id,
                  organization: org_name,
                  haven: file_haven === null ? "Unprotected" : "Protected",
                  _hidden_status: file_haven,
                });
              });
              setLoading(false);
            } else {
              setLoading(false);
            }

            setHaven(havenArray);
            setOrgs(orgs);
          });
        })
        .catch((err) => {});
    });
    setConfirmOpen({ open: false, data: null });
  };

  return (
    <div>
      {_orgs.length === 0 ? (
        <EmptyState />
      ) : (
        <React.Fragment>
          <div className="file__haven">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3>File Haven</h3>
              {_orgs.length !== 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setConfirmOpen({ open: true, data: {} })}
                >
                  Protect All Organisations
                </Button>
              )}
            </div>
            <HavenGrid haven={haven} load={loading} />
            <Dialog
              open={confirmOpen.open}
              onClose={confirm_handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">File Haven</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Do you want to enable file haven for a organisations?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={confirm_handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => confirmProtectOrgs()} color="primary">
                  Yes I am Sure
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  _orgs: state.sync.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Haven);
