import axios from "../config";
import asyncHandler from "../utils/asyncHandler";

export const syncOrganization = asyncHandler(
  async (query) => await axios.post(`/sync/podio/org${query}`)
);

export const syncFileHaven = asyncHandler(
  async (query) => await axios.post(`/sync/podio/org${query}`)
);

export const syncSpaces = asyncHandler(
  async (query) => await axios.post(`/sync/podio/spaces${query}`)
);

export const deleteApp = asyncHandler(
  async (query) => await axios.post(`/sync/podio/delete/app${query}`)
);

export const deleteSpace = asyncHandler(
  async (query) => await axios.post(`/sync/podio/delete/space${query}`)
);

export const syncApps = asyncHandler(
  async (query) => await axios.post(`/sync/podio/apps${query}`)
);

export const deleteOrganization = asyncHandler(
  async (query) => await axios.post(`sync/podio/delete/org${query}`)
);

export const getOrganizations = asyncHandler(
  async () => await axios.get(`/sync/podio/orgs/all`)
);

export const getOrganization = asyncHandler(
  async (id) => await axios.get(`/sync/podio/single/org/${id}`)
);

export const getSyncedOrganizations = asyncHandler(
  async () => await axios.get(`/sync/podio/orgs/synced`)
);

export const getSpaces = asyncHandler(
  async (id) => await axios.get(`/sync/podio/audit/spaces?org_id=${id}`)
);

export const getApps = asyncHandler(
  async (query) => await axios.get(`/sync/podio/audit/apps${query}`)
);

//Audit
export const getAuditSpacesAndApps = asyncHandler(
  async (query) => await axios.get(`/sync/podio/audit/spaces_and_apps${query}`)
);
export const getAuditMembers = asyncHandler(
  async (query) => await axios.get(`/sync/podio/audit/members${query}`)
);

//Hooks
export const getHooksLog = asyncHandler(
  async (query = "") => await axios.get(`/hook-manager/analytics${query}`)
);
export const getHooksChart = asyncHandler(
  async (query = "") => await axios.get(`/hook-manager/chart${query}`)
);
export const hookKiller = asyncHandler(
  async (query = "") => await axios.post(`/hook-killer${query}`)
);
export const makeHookActive = asyncHandler(
  async (query = "") => await axios.post(`/sync/podio/hooks/verify${query}`)
);
export const makeHookDelete = asyncHandler(
  async (query = "") => await axios.post(`/sync/podio/hooks/delete${query}`)
);
export const makeHook = asyncHandler(
  async (query = "") => await axios.post(`/sync/podio/hooks/create${query}`)
);

//Common
export const setFrequencyData = asyncHandler(
  async (query) => await axios.post(`/sync/podio/frequency/save${query}`)
);
export const setOrgUpdate = asyncHandler(
  async (query) => await axios.post(`/sync/podio/edit/organisation${query}`)
);
export const updateMemberRole = asyncHandler(
  async (query) => await axios.post(`/sync/podio/space_member/role${query}`)
);

//Members
export const refreshMember = asyncHandler(
  async (query) => await axios.post(`/sync/podio/space/refresh/member${query}`)
);
export const addMember = asyncHandler(
  async (query) => await axios.post(`/sync/podio/space/add/member${query}`)
);
export const removeMember = asyncHandler(
  async (query) => await axios.post(`/sync/podio/space/remove/member${query}`)
);
export const banMember = asyncHandler(
  async (query) => await axios.post(`/sync/podio/remove/member${query}`)
);

//Haven
export const setupHaven = asyncHandler(
  async (query) => await axios.post(`/sync/podio/setup/haven${query}`)
);
export const deleteHaven = asyncHandler(
  async (query) => await axios.post(`/sync/podio/delete/haven${query}`)
);
export const restoreFileInHaven = asyncHandler(
  async (query) => await axios.post(`/sync/podio/restore/file${query}`)
);
export const downloadFiles = asyncHandler(
    async (query) => await axios.get(`/sync/podio/download/files${query}`)
);

//Items
export const createItem = asyncHandler(
  async (data) => await axios.post(`/sync/podio/create/item`, data)
);
export const editItem = asyncHandler(
  async (data) => await axios.post(`/sync/podio/edit/item`, data)
);
export const deleteItem = asyncHandler(
  async (data) => await axios.post(`/sync/podio/delete/item`, data)
);
