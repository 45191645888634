import { createActionType } from "../../helpers/reduxHelpers";

export const SET_USER = "SET_USER";

export const SEND_INVITE = createActionType("SEND_INVITE");
export const GET_TEAM_MEMBERS = createActionType("GET_TEAM_MEMBERS");
export const GET_ME = createActionType("GET_ME");
export const DELETE_PODIO = createActionType("DELETE_PODIO");
export const GET_PENDING_INVITATION = createActionType(
  "GET_PENDING_INVITATION"
);
