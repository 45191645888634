import React from "react";
import PrintDashboardTemplate from "../../../components/templates/print/PrintDashboardTemplate";

const Dashboard = () => {
  return (
    <div>
      <PrintDashboardTemplate />
    </div>
  );
};

export default Dashboard;
