import { SET_USER } from "../constants/user";
import { AUTH_LOGOUT } from "../constants/actionsTypes";
import {
  defaultSingleObjectState,
  defaultSingleObjectArrayState,
} from "../../utils/constants";
import { extractStatus, handleReduxAction } from "../../helpers/reduxHelpers";
import {
  SEND_INVITE,
  GET_TEAM_MEMBERS,
  GET_PENDING_INVITATION,
  // GET_ME,
  DELETE_PODIO,
} from "../constants/user";

const INITIAL_STATE = {
  authUser: {
    isLoggedIn: false,
  },
  invite: defaultSingleObjectState,
  teamMembers: defaultSingleObjectArrayState,
  pendingInvitation: defaultSingleObjectArrayState,
};
const defaultPayload = {
  data: [],
  errors: [],
};

const userReducer = (
  state = INITIAL_STATE,
  { type, payload = defaultPayload }
) => {
  const status = extractStatus(type);

  switch (type) {
    case SET_USER:
      // case GET_ME.success:
      return {
        ...state,
        authUser: {
          isLoggedIn: true,
          ...payload,
        },
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        authUser: { isLoggedIn: false },
      };
    case SEND_INVITE.request:
    case SEND_INVITE.success:
    case SEND_INVITE.error:
      return handleReduxAction(state, payload, status, "invite");
    case GET_TEAM_MEMBERS.request:
    case GET_TEAM_MEMBERS.success:
    case GET_TEAM_MEMBERS.error:
      return handleReduxAction(state, payload, status, "teamMembers");
    case GET_PENDING_INVITATION.request:
    case GET_PENDING_INVITATION.success:
    case GET_PENDING_INVITATION.error:
      return handleReduxAction(state, payload, status, "pendingInvitation");
    case DELETE_PODIO.success:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          connected_platform: [],
        },
      };
    default:
      return state;
  }
};

export default userReducer;
