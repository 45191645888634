import React from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import {AgGridReact} from "ag-grid-react";
import Endpoint from "./Endpoint"
import {AllModules} from "ag-grid-enterprise";
import ImitateAction from "./ImitateAction";

const Users = (props) => {

    const filterParams = {
        filterOptions: ['contains', 'notContains'],
        textFormatter: function(r) {
            if (r == null) return null;
            r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
            r = r.replace(new RegExp('æ', 'g'), 'ae');
            r = r.replace(new RegExp('ç', 'g'), 'c');
            r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
            r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
            r = r.replace(new RegExp('ñ', 'g'), 'n');
            r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
            r = r.replace(new RegExp('œ', 'g'), 'oe');
            r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
            r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
            return r;
        },
        debounceMs: 0,
        caseSensitive: true,
        suppressAndOrCondition: true,
    };

    const [users] = React.useState({
        columns: [
            { headerName: 'Menu', width: 100, field: 'menu', cellRenderer: 'imitateAction'},
            {headerName: 'User Id', field: 'id', filter: 'agTextColumnFilter', filterParams},
            {headerName: 'Name', field: 'name', filter: 'agTextColumnFilter', filterParams},
            {headerName: 'Email', field: 'email', filter: 'agTextColumnFilter', filterParams},
            {headerName: 'Podio Id', field: 'user_id_extension', filter: 'agTextColumnFilter', filterParams}
        ],
        data: []
    });

    const  defaultColDef = {
        flex: 1,
        minWidth: 100,
        filter: true,
        sortable: true,
        resizable: true,
    };
    const autoGroupColumnDef = { minWidth: 200 };
    const frameworks = {
        imitateAction: ImitateAction
        // imitateAction: null
    };
    const paginationPageSize = 100;
    const cacheBlockSize = 100;
    const rowModelType = 'serverSide';

    const ServerSideDatasource = () => {
        return {
            getRows: function(params) {
                let query = '';
                if(Object.entries(params.request.filterModel).length > 0) {
                    let search_key = Object.keys(params.request.filterModel).length;
                    if (search_key === 1) {
                        search_key = Object.keys(params.request.filterModel)[0];
                    } else {
                        search_key = Object.keys(params.request.filterModel)[Object.keys(params.request.filterModel).length - 1];
                    }
                    const filterData = params.request.filterModel[search_key];
                    query = `${query}&search_word=${filterData.filter}&filter_field=${search_key}&filter=${filterData.type}`;
                }

                if(params.request.sortModel.length > 0)
                {
                    query = `${query}&sort=${params.request.sortModel[0].sort}&sort_field=${params.request.sortModel[0].colId}`;
                }
                Endpoint.getUsersCustom(params.request.startRow, paginationPageSize, query)
                    .then(response => {
                        const data = response.data.data;
                        setTimeout(function() {
                            params.successCallback(data, data[0].total_count);
                        }, 200);
                    })
                    .catch(err => {
                        params.failCallback();
                    });
            },
        };
    };

    const onGridReady = params => {
        const theData = ServerSideDatasource();
        params.api.setServerSideDatasource(theData);
    };

    return (
      <div className="p-24">
        <h4 style={{fontWeight:`bold`, marginBottom:`15px`}}>PLATFORM USERS</h4>
      <div
          className="ag-theme-balham-dark"
          style={{
              height: '700px',
              width: '100%'}}
      >
                        <AgGridReact
                            modules={AllModules}
                            columnDefs={users.columns}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            rowModelType={rowModelType}
                            frameworkComponents={frameworks}
                            enableRangeSelection={true}
                            animateRows={true}
                            // rowData={users.data}
                            onGridReady={onGridReady}
                            rowGroupPanelShow="always"
                            rowSelection="multiple"
                            pagination={true}
                            paginationPageSize={paginationPageSize}
                            cacheBlockSize={cacheBlockSize}
                            debug={true}
                            sideBar={true}
                            enableCellTextSelection={true}
                            suppressAggFuncInHeader={true}
                            enableFilter={true}
                            enableSorting={true}
                            showToolPanel={true}
                        />
                    </div>
                    </div>
               
        
    )
};

export default Users