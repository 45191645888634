export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH ";

export const GET_ALL_PODIO_ACCOUNTS_START = "GET_ALL_PODIO_ACCOUNTS_START";
export const GET_ALL_PODIO_ACCOUNTS_SUCCESS = "GET_ALL_PODIO_ACCOUNTS_SUCCESS";
export const GET_ALL_PODIO_ACCOUNTS_FAIL = "GET_ALL_PODIO_ACCOUNTS_FAIL";

export const GET_ALL_SYNCED_ORG_START = "GET_ALL_SYNCED_ORG_START";
export const GET_ALL_SYNCED_ORG_SUCCESS = "GET_ALL_SYNCED_ORG_SUCCESS";
export const GET_ALL_SYNCED_ORG_FAIL = "GET_ALL_SYNCED_ORG_FAIL";

export const GET_ALL_UNSYNCED_ORG_START = "GET_ALL_UNSYNCED_ORG_START";
export const GET_ALL_UNSYNCED_ORG_SUCCESS = "GET_ALL_UNSYNCED_ORG_SUCCESS";
export const GET_ALL_UNSYNCED_ORG_FAIL = "GET_ALL_UNSYNCED_ORG_FAIL";

export const SYNC_ORGANISATION_START = "SYNC_ORGANISATION_START";
export const SYNC_ORGANISATION_SUCCCESS = "SYNC_ORGANISATION_SUCCCESS";
export const SYNC_ORGANISATION_FAIL = "SYNC_ORGANISATION_FAIL";

export const GET_SYNCING_STATUS_FROM_FIREBASE_START =
  "GET_SYNCING_STATUS_FROM_FIREBASE_START";
export const GET_SYNCING_STATUS_FROM_FIREBASE_SUCCESS =
  "GET_SYNCING_STATUS_FROM_FIREBASE_SUCCESS";
export const GET_SYNCING_STATUS_FROM_FIREBASE_FAIL =
  "GET_SYNCING_STATUS_FROM_FIREBASE_FAIL";
