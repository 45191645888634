import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import "./css/filter.css";

const HowFilterWorks = () => {
  const filterUrl = `${process.env.REACT_APP_BASEURL}/sync/podio/raw/SyncDatabase_294/podio_spaces_1473960?email=william@techego.com&secret_key=$2y$10$DC/MY7OJKMPxXS8fG1qdqu1c001kYG2OwMy7b.okgHbw.YPgI08ni`;

  const [nav1, setNav1] = useState(true);

  const [nav2, setNav2] = useState(false);
  const [nav2_1, setNav2_1] = useState(false);
  const [nav2_2, setNav2_2] = useState(false);
  const [nav2_3, setNav2_3] = useState(false);

  const [nav3, setNav3] = useState(false);
  const [nav3_1, setNav3_1] = useState(false);
  const [nav3_2, setNav3_2] = useState(false);

  const [nav4, setNav4] = useState(false);
  const [nav4_1, setNav4_1] = useState(false);
  const [nav4_2, setNav4_2] = useState(false);
  const [nav4_3, setNav4_3] = useState(false);

  const nav1f = () => {
    setNav1(true);

    setNav2(false);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(false);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(false);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav2f = () => {
    setNav1(false);

    setNav2(true);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(false);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(false);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav2_1f = () => {
    setNav1(false);

    setNav2(true);
    setNav2_1(true);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(false);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(false);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav2_2f = () => {
    setNav1(false);

    setNav2(true);
    setNav2_1(false);
    setNav2_2(true);
    setNav2_3(false);

    setNav3(false);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(false);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav2_3f = () => {
    setNav1(false);

    setNav2(true);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(true);

    setNav3(false);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(false);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav3f = () => {
    setNav1(false);

    setNav2(false);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(true);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(false);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav3_1f = () => {
    setNav1(false);

    setNav2(false);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(true);
    setNav3_1(true);
    setNav3_2(false);

    setNav4(false);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav3_2f = () => {
    setNav1(false);

    setNav2(false);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(true);
    setNav3_1(false);
    setNav3_2(true);

    setNav4(false);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav4f = () => {
    setNav1(false);

    setNav2(false);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(false);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(true);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav4_1f = () => {
    setNav1(false);

    setNav2(false);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(false);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(true);
    setNav4_1(true);
    setNav4_2(false);
    setNav4_3(false);
  };

  const nav4_2f = () => {
    setNav1(false);

    setNav2(false);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(false);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(true);
    setNav4_1(false);
    setNav4_2(true);
    setNav4_3(false);
  };

  const nav4_3f = () => {
    setNav1(false);

    setNav2(false);
    setNav2_1(false);
    setNav2_2(false);
    setNav2_3(false);

    setNav3(false);
    setNav3_1(false);
    setNav3_2(false);

    setNav4(true);
    setNav4_1(false);
    setNav4_2(false);
    setNav4_3(true);
  };

  return (
    <HowFilterWorks.Wrapper>
      <div id="wrapper" className="how-filter-works">
        <div className="container">
          <section id="top" className="section docs-heading">
            <div className="row">
              <div className="col-md-12 back" id="line1">
                <Link to="/dashboard/raw-data" className="links-active">
                  Back
                </Link>
              </div>
            </div>
          </section>

          <div className="row welcome">
            <div className="col-md-9 main-content">
              <section className="row" id="refUrl">
                <div className="col-md-12">
                  <h1>How Filters work</h1>
                  <p>Overview of how our Thatapp collection filters work</p>
                  <p id="introduction">
                    <i>
                      Note: we'll be using the reference api url for this
                      documentation{" "}
                      <code style={{ marginRight: `100px` }}>{filterUrl}</code>
                    </i>
                  </p>
                </div>
              </section>

              <section className="row" onMouseOver={nav1f}>
                <div className="col-md-12">
                  <h2>Introduction</h2>
                  <p id="limitSKip">
                    A Collection query filter conditionally applies additional
                    query predicates and projections to an incoming query before
                    your Mongodb Realm runs the query. Filters are useful for
                    improving the performance of queries on collections that
                    have many documents or roles that use complex Apply When
                    expressions eg. space collection has nested list of members
                    etc. You can add filters to a collection to remove documents
                    that you know are not relevant to incoming queries before
                    our system evaluates any roles. This can help to reduce the
                    amount of computation that our system needs to perform for a
                    request. Filters can take advantage of indexes in a
                    linked/nested objects, so filtering out your api is
                    generally faster than waiting for our system to evaluate a
                    role for it.
                  </p>
                </div>
              </section>

              <section className="row">
                <div className="col-md-12">
                  <h2 onMouseOver={nav2f}>Limit and Skip</h2>
                  <p onMouseOver={nav2f} id="limitSkip_example">
                    Your collections could comprise of numerous data, in other
                    to avoid late response or congestion from our API we've
                    provided you with a seamless way to limit and skip certain
                    records. This functionality could easily help paginate your
                    resource.
                  </p>
                  <div className="">
                    <div onMouseOver={nav2_1f}>
                      <h4 style={{ marginLeft: "20px" }}>Example</h4>
                      <p>
                        By default the below api url will return the actual
                        records of spaces synced into our system:
                      </p>
                      <p className="filter-url">{filterUrl}</p>
                      <code id="limitSkip_usingLimit">
                        {`{
                          "items":[
                        {
                          "_id":{},
                          "space_id":6673591,
                          "auto_join":false,
                          "created_by":{},
                          "created_on":"2019-07-15 09:57:14",
                          "members":[],
                          "mongodb_timestamp":1607934970,
                          "name":"Projects",
                          "org_id":1473960,
                          "post_on_new_app":true,
                          "post_on_new_member":true,
                          "premium":false,
                          "privacy":"open",
                          "rights":[],
                          "role":"admin",
                          "subscribed":true,
                          "sync_timestamp":{},
                          "type":"regular",
                          "url":"https://podio.com/prodevsio/projects",
                          "url_label":"projects",
                          "hooks":[]
                        },
                        {
                          "_id":{},
                          "space_id":6672008,
                          "auto_join":false,
                          "created_by":{},
                          "created_on":"2019-07-13 13:58:55",
                          "members":[],
                          "mongodb_timestamp":1607934973,
                          "name":"Sales",
                          "org_id":1473960,
                          "post_on_new_app":true,
                          "post_on_new_member":true,
                          "premium":false,
                          "privacy":"closed",
                          "rights":[],
                          "role":"admin",
                          "subscribed":true,
                          "sync_timestamp":{},
                          "type":"regular",
                          "url":"https://podio.com/prodevsio/sales",
                          "url_label":"sales",
                          "hooks":[]
                        }
                          ],
                          "total":2
                        }`}
                      </code>
                      <br />
                      <br />
                    </div>

                    <div onMouseOver={nav2_2f}>
                      <h4 style={{ marginLeft: "20px" }}>Using Limit</h4>
                      <p>Set limit to (1) space</p>
                      <p className="filter-url">{filterUrl}&limit=1</p>
                      <code id="limitSkip_usingSkip">
                        {`{
                          "items":[
                        {
                          "_id":{},
                          "space_id":6673591,
                          "auto_join":false,
                          "created_by":{},
                          "created_on":"2019-07-15 09:57:14",
                          "members":[],
                          "mongodb_timestamp":1607934970,
                          "name":"Projects",
                          "org_id":1473960,
                          "post_on_new_app":true,
                          "post_on_new_member":true,
                          "premium":false,
                          "privacy":"open",
                          "rights":[],
                          "role":"admin",
                          "subscribed":true,
                          "sync_timestamp":{},
                          "type":"regular",
                          "url":"https://podio.com/prodevsio/projects",
                          "url_label":"projects",
                          "hooks":[]
                        }
                          ],
                          "total":1
                        }`}
                      </code>
                      <br />
                      <br />
                    </div>

                    <div onMouseOver={nav2_3f}>
                      <h4 style={{ marginLeft: "20px" }}>Using Skip</h4>
                      <p>
                        To get the next document after the one above, skip the
                        first one
                      </p>
                      <p className="filter-url">{filterUrl}&limit=1&skip=1</p>
                      <code id="queryFilter">
                        {`{
                          "items":[
                          {
                          "_id":{},
                          "space_id":6672008,
                          "auto_join":false,
                          "created_by":{},
                          "created_on":"2019-07-13 13:58:55",
                          "members":[],
                          "mongodb_timestamp":1607934973,
                          "name":"Sales",
                          "org_id":1473960,
                          "post_on_new_app":true,
                          "post_on_new_member":true,
                          "premium":false,
                          "privacy":"closed",
                          "rights":[],
                          "role":"admin",
                          "subscribed":true,
                          "sync_timestamp":{},
                          "type":"regular",
                          "url":"https://podio.com/prodevsio/sales",
                          "url_label":"sales",
                          "hooks":[]
                        }
                          ],
                          "total":1
                        }`}
                      </code>
                    </div>
                  </div>
                </div>
              </section>

              <section className="row">
                <div className="col-md-12">
                  <h2 onMouseOver={nav3f}>Query Filter Configuration</h2>
                  <p onMouseOver={nav3f}>
                    When our system receives a query request, it determines if
                    any filters associated with the collection apply to the
                    request and adds those to the query. To assign filters to a
                    query, our system evaluates the Apply When JSON expression
                    that you defined for each filter. If a filter's Apply When
                    evaluates to true, we merge the Filter Query into the
                    incoming query document.
                  </p>
                  <code id="queryFilter_1stExample">
                    {`{
                        "name": "<Filter Name>",
                        "apply_when": <JSON Expression>,
                        "query": <Query Document>,
                        "projection": <Projection Document>
                    }`}
                  </code>
                  <div className="" onMouseOver={nav3_1f}>
                    <h4 style={{ marginLeft: "20px", marginTop: "20px" }}>
                      1st Example
                    </h4>
                    <p>
                      We can do a quick filter to get a particular space using
                      the reference spaces{" "}
                      <a
                        href="#refUrl"
                        style={{ marginRight: `100px` }}
                        title={filterUrl}
                      >
                        api url
                      </a>{" "}
                      and passing the below payload
                    </p>
                    <code>
                      {`{
                        space_id: 6672008,
                        premium: false
                      }
                      `}
                    </code>
                    <br />
                    <br />
                    <p>
                      The above query would return the space whose space_id is
                      6672008 and premium is false.
                    </p>
                    <code id="queryFilter_2ndExample">
                      {`
                        { "items":[ { "_id":{}, "space_id":6672008, "auto_join":false, "created_by":{}, "created_on":"2019-07-13 13:58:55", "members":[],
                        "mongodb_timestamp":1607934973, "name":"Sales", "org_id":1473960, "post_on_new_app":true, "post_on_new_member":true, "premium":false, "privacy":"closed",
                        "rights":[], "role":"admin", "subscribed":true, "sync_timestamp":{}, "type":"regular", "url":"https://podio.com/prodevsio/sales", "url_label":"sales", "hooks":[] } ], "total":1 }
                      `}
                    </code>
                  </div>
                  <div className="" onMouseOver={nav3_2f}>
                    <h4 style={{ marginLeft: "20px", marginTop: "20px" }}>
                      2nd Example
                    </h4>
                    <p>
                      For collections where space_id or app_id is wrapped into a
                      parent eg space.space_id in app collections, you will need
                      to provide a slightly different payload.
                    </p>
                    <code>
                      {`{
                        space_space_id: 6672008
                      }
                      `}
                    </code>
                    <br />
                    <br />
                    <p>
                      For items querying using the app_id, you would need to do
                      the following.
                    </p>
                    <code>
                      {`{
                        app_space_id: 6672008
                      }
                      `}
                    </code>
                    <br />
                    <br />
                    <i id="advanceFilterRules">
                      Note: this only applies to app_id or space_id in nested
                      objects eg items collections and apps_collections.
                    </i>
                  </div>
                </div>
              </section>

              <section className="row">
                <div className="col-md-12">
                  <h2 onMouseOver={nav4f}>Advance Filter Rules</h2>
                  <p onMouseOver={nav4f} id="advanceFilterRules_nestedField">
                    Your Thatapp data sits on a mongodb instance, so any queries
                    that works on any mongdb instance would work via our
                    collections api interface. You can check out the mongo db
                    official documentation on how to query{" "}
                    <a href="https://docs.mongodb.com/manual/tutorial/query-documents/">
                      documents.
                    </a>
                  </p>

                  <div onMouseOver={nav4_1f}>
                    <h4 style={{ marginLeft: "20px", marginTop: "20px" }}>
                      Query on Nested Field
                    </h4>
                    <p>
                      To specify a query condition on fields in an
                      embedded/nested object, use dot notation{" "}
                      <code>("field.nestedField")</code>.
                    </p>
                    <h4
                      id="advanceFilterRules_queryOperator"
                      style={{ marginLeft: "20px", marginTop: "20px" }}
                    >
                      The following example selects all items where the field{" "}
                      <b>user_id</b> nested in the <b>created_by</b> field
                      equals <b>"4768617"</b>:
                    </h4>
                    <p>
                      <code>
                        {`{
                        created_by.user_id: 4768617
                      }
                      `}
                      </code>
                    </p>
                  </div>

                  <div onMouseOver={nav4_2f}>
                    <h4 style={{ marginLeft: "20px", marginTop: "20px" }}>
                      Specify Match using Query Operator
                    </h4>
                    <p>
                      A query filter document can use the query operators to
                      specify conditions in the following form:
                    </p>
                    <p id="advanceFilterRules_array">
                      The following query uses the less than operator{" "}
                      <b>($lt)</b> on the field h embedded in the size field:
                    </p>
                    <p>
                      <code>{`{ "comment_count": { $lt: 3 }}`}</code>
                    </p>
                    <p>
                      <i>
                        Read more about our supported{" "}
                        <a href="https://docs.mongodb.com/manual/reference/operator/query/">
                          query operators
                        </a>
                      </i>
                    </p>
                  </div>

                  <div onMouseOver={nav4_3f}>
                    <h4 style={{ marginLeft: "20px", marginTop: "20px" }}>
                      Query an Array for an Element
                    </h4>
                    <p>
                      To query if the array field contains at least one element
                      with the specified value, use the filter{" "}
                      <code>{`{ <field>: <value> }`}</code> where{" "}
                      <code>{`<value>`}</code> is the element value. The
                      following example queries for all app fields where field
                      is an array that contains the type assigned to{" "}
                      <b>"contact"</b> as one of its elements:
                    </p>
                    <p>
                      <code>{`{ "fields.type": "contact"}`}</code>
                    </p>
                    <p>
                      The following will return a list of fields with type
                      contact.
                    </p>
                  </div>
                </div>
              </section>

              <div>
                <p>
                  Still having troubles with filtering, you can drop an{" "}
                  <a href="mailto:support@techeego.com">email</a> and our
                  support team will reply withing 24 hours.
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <nav
                className="docs-sidebar"
                data-spy="affix"
                data-offset-top="300"
                data-offset-bottom="200"
                role="navigation"
              >
                <ul className="nav">
                  <li>
                    <a
                      onClick={nav1f}
                      className={nav1 ? "links-active" : "links"}
                      href="#introduction"
                    >
                      Introduction
                    </a>
                  </li>
                </ul>
                <ul className="nav">
                  <li>
                    <a
                      onClick={nav2f}
                      className={nav2 ? "links-active" : "links"}
                      href="#limitSKip"
                    >
                      Limit and Skip
                    </a>
                    <ul>
                      <li>
                        <a
                          onClick={nav2_1f}
                          className={nav2_1 ? "links-active" : "links"}
                          href="#limitSkip_example"
                        >
                          Example
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={nav2_2f}
                          className={nav2_2 ? "links-active" : "links"}
                          href="#limitSkip_usingLimit"
                        >
                          Using Limit
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={nav2_3f}
                          className={nav2_3 ? "links-active" : "links"}
                          href="#limitSkip_usingSkip"
                        >
                          Using Skip
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="nav">
                  <li>
                    <a
                      onClick={nav3f}
                      className={nav3 ? "links-active" : "links"}
                      href="#queryFilter"
                    >
                      Query Filter Configuration
                    </a>
                    <ul>
                      <li>
                        <a
                          onClick={nav3_1f}
                          className={nav3_1 ? "links-active" : "links"}
                          href="#queryFilter_1stExample"
                        >
                          1st Example
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={nav3_2f}
                          className={nav3_2 ? "links-active" : "links"}
                          href="#queryFilter_2ndExample"
                        >
                          2nd Example
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="nav">
                  <li>
                    <a
                      onClick={nav4f}
                      className={nav4 ? "links-active" : "links"}
                      href="#advanceFilterRules"
                    >
                      Advance Filter Rules
                    </a>
                    <ul>
                      <li>
                        <a
                          onClick={nav4_1f}
                          className={nav4_1 ? "links-active" : "links"}
                          href="#advanceFilterRules_nestedField"
                        >
                          Query on Nested Field
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={nav4_2f}
                          className={nav4_2 ? "links-active" : "links"}
                          href="#advanceFilterRules_queryOperator"
                        >
                          Specify Match using Query Operator
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={nav4_3f}
                          className={nav4_3 ? "links-active" : "links"}
                          href="#advanceFilterRules_array"
                        >
                          Query an Array for an Element
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </HowFilterWorks.Wrapper>
  );
};

HowFilterWorks.Wrapper = styled.div`
  img {
    width: 50%;
    display: block;
  }
  i {
    margin-right: 500px !important;
    width: 10vw !important;
  }
`;
export default HowFilterWorks;
