import React from "react";

import "./ConfirmModal.css";

const ConfirmModal = ({ message, syncOrg, toggleModal, deleteOrg }) => {
  return (
    <div className="confirm__modal">
      <div className="modal__content">
        <div>
          <div className="modal__card">
            <div>
              <h6 className="mt-3">{message}</h6>
              <button
                onClick={() => {
                  message.includes("resync") ? syncOrg() : deleteOrg();
                }}
              >
                Confirm
              </button>
              <p className="cancel" onClick={() => toggleModal(null, null)}>
                Cancel
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
