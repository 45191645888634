import React from "react";
import "./ActionsRenderer.css";
import Confirm from "../../../../components/Modal/Confirm";
import {
  deleteApp,
  deleteSpace,
  syncApps,
  syncSpaces,
} from "../../../../services/sync";
import toaster from "toasted-notes";

const ActionsRenderer = (props) => {
  const initial = {
    message: null,
    modal_type: null,
    title: "",
    modal_value: false,
    modal_data: {},
    popup: false,
  };
  const [syncModal, syncModalData] = React.useState(initial);

  //Action
  const handleSubmit = async (type) => {
    let data;

    if (type === "syncapp") {
      data = {
        org_id: syncModal.modal_data.org,
        app_id: syncModal.modal_data.app,
      };

      syncApps(`?org_id=${data.org_id}&app_ids=${data.app_id}`)
        .then((r) => {
          syncModalData(initial);
          toaster.notify("App synced successfully", {
            duration: 4000,
            position: "bottom",
          });
        })
        .catch((e) => {
          syncModalData(initial);
        });
    }

    if (type === "syncspace") {
      data = {
        org_id: syncModal.modal_data.org,
        space_id: syncModal.modal_data.space,
      };
      syncSpaces(`?org_id=${data.org_id}&space_ids=${data.space_id}`)
        .then((r) => {
          syncModalData(initial);
          toaster.notify("Space synced successfully", {
            duration: 4000,
            position: "bottom",
          });
        })
        .catch((e) => {
          syncModalData(initial);
        });
    }

    if (type === "restorespace") {
      data = {
        org_id: syncModal.modal_data.org,
        space_id: syncModal.modal_data.space,
      };
    }

    if (type === "restoreapp") {
      data = {
        org_id: syncModal.modal_data.org,
        space_id: syncModal.modal_data.app,
      };
    }

    if (type === "deleteapp") {
      data = {
        org_id: syncModal.modal_data.org,
        app_id: syncModal.modal_data.app,
      };
      deleteApp(`?org_id=${data.org_id}&app_id=${data.app_id}`)
        .then((r) => {
          syncModalData(initial);
          toaster.notify(
            "This app and its items were deleted successfully, refresh the grid to see results",
            {
              duration: 4000,
              position: "bottom",
            }
          );
          window.document.getElementById("refreshThisGrid").click();
        })
        .catch((e) => {
          syncModalData(initial);
        });
    }

    if (type === "deletespace") {
      data = {
        org_id: syncModal.modal_data.org,
        space_id: syncModal.modal_data.space,
      };
      deleteSpace(`?org_id=${data.org_id}&space_id=${data.space_id}`)
        .then((r) => {
          syncModalData(initial);
          toaster.notify(
            "This space and its apps/items were deleted successfully, refresh the grid to see results",
            {
              duration: 4000,
              position: "bottom",
            }
          );
          window.document.getElementById("refreshThisGrid").click();
        })
        .catch((e) => {
          syncModalData(initial);
        });
    }
  };

  //Call the modals
  const syncAppPopup = (org, app) => {
    syncModalData({
      message: "Do you want to Resync this App?",
      modal_type: "syncapp",
      title: "Sync App",
      modal_value: true,
      modal_data: { org, app },
      popup: true,
    });
  };

  const syncSpacePopup = (org, space) => {
    syncModalData({
      message: "Do you want to Resync this Space?",
      modal_type: "syncspace",
      title: "Sync Space",
      modal_value: true,
      modal_data: { org, space },
      popup: true,
    });
  };

  const restoreSpacePopup = (org, space) => {
    syncModalData({
      message: "Do you want to Restore this Space?",
      modal_type: "restorespace",
      title: "Restore Space",
      modal_value: true,
      modal_data: { org, space },
      popup: true,
    });
  };

  const restoreAppPopup = (org, app) => {
    syncModalData({
      message: "Do you want to Restore this App?",
      modal_type: "restoreapp",
      title: "Restore app",
      modal_value: true,
      modal_data: { org, app },
      popup: true,
    });
  };

  const deleteAppPopup = (org, app) => {
    syncModalData({
      message:
        "Do you want to Delete this App and all its data from our system?",
      modal_type: "deleteapp",
      title: "Delete app",
      modal_value: true,
      modal_data: { org, app },
      popup: true,
    });
  };

  const deleteSpacePopup = (org, space) => {
    syncModalData({
      message:
        "Do you want to Delete this Space and all its data from our system?",
      modal_type: "deletespace",
      title: "Delete space",
      modal_value: true,
      modal_data: { org, space },
      popup: true,
    });
  };

  //Action Display
  const dataInGroup =
    typeof props.node["childrenAfterGroup"] !== "undefined"
      ? props.node.childrenAfterGroup[0].data
      : {};
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  const toggleModal = () => {
    syncModalData(initial);
  };

  return (
    <>
      <Confirm
        className="confirm-modal-container"
        openModal={syncModal.modal_value}
        message={syncModal.message}
        action={() => handleSubmit(syncModal.modal_type)}
        title={syncModal.title}
        toggleModal={toggleModal}
      />
      {data.point === "app" ? (
        <>
          {data.deleted_from_podio === "false" ? (
            <i
              className="fa fa-sync-alt ag-grid-data-list"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Resync App"
              onClick={() => syncAppPopup(data.org_id, data.app_id)}
            ></i>
          ) : (
            <i
              className="fa fa-trash-restore-alt ag-grid-data-list"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Restore App"
              onClick={() => restoreAppPopup(data.org_id, data.app_id)}
            ></i>
          )}
          <i
            className="fa fa-trash"
            style={{
              fontSize: "1.50em",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Delete App"
            onClick={() => deleteAppPopup(data.org_id, data.app_id)}
          ></i>
        </>
      ) : (
        <>
          {dataInGroup.deleted_space_from_podio === "false" ||
          data.deleted_space_from_podio === "false" ? (
            <i
              className="fa fa-sync-alt"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Resync Space"
              onClick={() => syncSpacePopup(data.org_id, data.space_id)}
            ></i>
          ) : (
            <i
              className="fa fa-trash-restore-alt"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Restore Space"
              onClick={() => restoreSpacePopup(data.org_id, data.space_id)}
            ></i>
          )}
          <i
            className="fa fa-trash"
            style={{
              fontSize: "1.50em",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Delete Space"
            onClick={() => {
              deleteSpacePopup(data.org_id, data.space_id);
            }}
          ></i>
        </>
      )}
    </>
  );
};

export default ActionsRenderer;
