import React from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const BillingCards = () => {
  const tableStyle = {
    display: "block",
    width: "100%",
  };
  const columns = [
    { title: "Card Type", field: "card_type" },
    { title: "Card No", field: "card_no" },
    { title: "Card Expiry", field: "card_expiry" },
  ];

  const [loading] = React.useState(false);
  const [, setShouldShowCardModal] = React.useState(false);

  return (
    <div>
      <Card className="w-full mb-16" style={tableStyle}>
        <Button
          variant="contained"
          color="primary"
          // onClick={() => saveCard()}
          onClick={() => setShouldShowCardModal(true)}
        >
          Add Card
        </Button>
        <br />
        <br />
        <MaterialTable
          title="Card Details"
          columns={columns}
          data={[]}
          isLoading={loading}
          localization={{
            body: {
              emptyDataSourceMessage: "No cards",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
          actions={[
            {
              icon: "settings_system_daydream",
              tooltip: "Enable File Haven",
              onClick: (event) => {
                event.disabled = true;
                // setConfirmModal({
                //   message: "",
                //   modal_type: "card_default",
                //   modal_value: true,
                //   modal_data: rowData,
                // });
              },
            },
            {
              icon: "delete",
              tooltip: "Remove File Haven",
              onClick: (event) => {
                event.disabled = true;
                // setConfirmModal({
                //   message: "",
                //   modal_type: "delete_card",
                //   modal_value: true,
                //   modal_data: rowData,
                // });
              },
            },
          ]}
          components={{
            Action: (props) => {
              if (props.action.icon === "settings_system_daydream") {
                return (
                  <React.Fragment>
                    <Button
                      onClick={(event) =>
                        props.action.onClick(event, props.data)
                      }
                      color="primary"
                      variant="contained"
                      style={{
                        fontSize: 7,
                        display: props.data.hidden_action === 1 ? "none" : "",
                        marginRight: "30px !important",
                      }}
                    >
                      Make Default
                    </Button>
                  </React.Fragment>
                );
              }

              if (props.action.icon === "delete") {
                return (
                  <React.Fragment>
                    {props.data.hidden_action === 1 ? (
                      <React.Fragment>
                        <br />
                        Default
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    <Tooltip
                      style={{ marginLeft: "-10px" }}
                      title="Delete Card"
                    >
                      <IconButton
                        onClick={(event) =>
                          props.action.onClick(event, props.data)
                        }
                      >
                        <DeleteIcon>Delete Card</DeleteIcon>
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                );
              }
            },
          }}
          options={{
            actionsColumnIndex: -1,
          }}
        />
      </Card>
    </div>
  );
};

export default BillingCards;
