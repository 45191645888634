import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import './styles.css'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width:`100vw`
  },
}));

const Summary = ({ synced, spaceCount, appCount, itemsCount, click, orgs }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root}`}>
      <div
        className="d-flex justify-content-between"
        style={{ alignItems: "center" }}
      >
        <h3 style={{ margin: "0", fontWeight: "bold" }}> OVERVIEW </h3>
        <button
          id="syncAnOrgButton"
          className={orgs.length < 1 ? "sync__btn jiggle" : "sync__btn"}
          onClick={click}
        >
          Sync an Organization
        </button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} className="text-right"></Grid>
        <Grid item xs={12} className="text-left"></Grid>
        <Grid item xs={12} sm={3}>
          <div className="overview__card">
            <p className="title">Organization</p>
            <h1 className="count">{orgs.length}</h1>
            <p className="description">Synced Organizations</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="overview__card">
            <p className="title">Spaces in Organizations</p>
            <h1 className="count">{spaceCount}</h1>
            <p className="description">All Spaces</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="overview__card">
            <p className="title">Apps across all spaces</p>
            <h1 className="count">{appCount}</h1>
            <p className="description">Total Apps</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="overview__card">
            <p className="title">Items across all Apps</p>
            <h1 className="count">{itemsCount}</h1>
            <p className="description">Total Items</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;
