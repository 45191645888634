import React from "react";
import "./ConfirmModal.css";
import { Alert } from "@material-ui/lab";

const GDPRModal = ({ gdprModal, action, the_data }) => {
  const [dp, setDp] = React.useState(null);

  const handleChange = (event) => {
    setDp(event.target.value);
  };

  return (
    <div className="confirm__modal">
      <div className="modal__content">
        <div>
          <div className="modal__card">
            <div>
              <h6 className="mt-3">Data Protection Policy</h6>
              <Alert severity="info">
                Inline with General Data Protection Regulation, you can select
                the frequency in which you want your deleted Data, store on our
                server to be permanently deleted.
              </Alert>
              <div className="form-group">
                <br />
                <label htmlFor="exampleFormControlSelect1">
                  Choose a frequency
                </label>
                <select className="form-control" onChange={handleChange}>
                  <option value={dp}>
                    {dp === 1 && "Don't Delete"}
                    {dp === 2 && "Monthly"}
                    {dp === 3 && "Quarterly"}
                    {dp === 4 && "6 Months"}
                  </option>
                  <option value={1}>Don't Delete</option>
                  <option value={2}>Monthly</option>
                  <option value={3}>Quarterly</option>
                  <option value={4}>6 Months</option>
                </select>
              </div>
              <button
                onClick={() => {
                  if (dp !== null) action(the_data.org_id, dp);
                }}
                style={{ marginTop: "-20px" }}
              >
                Save setting
              </button>
              <p className="cancel" onClick={() => gdprModal(null, false)}>
                Cancel
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDPRModal;
