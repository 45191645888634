import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LockIcon from "@material-ui/icons/Lock";
import { useHistory } from "react-router-dom";
import {
  deleteHaven,
  getSyncedOrganizations,
  setupHaven,
} from "../../../services/sync";
import toaster from "toasted-notes";
import { setOrgs } from "../../../redux/actions/sync";
import { connect } from "react-redux";

const ActionsRenderer = (props) => {
  const history = useHistory();
  const [confirmOpen, setConfirmOpen] = React.useState({
    open: false,
    data: null,
  });
  const [confirmOpenDelete, setConfirmOpenDelete] = React.useState({
    open: false,
    data: null,
  });

  const handleConfirmClickOpen = (data) => {
    setConfirmOpen({ open: true, data: data });
  };

  const handleConfirmClickOpenDelete = (data) => {
    setConfirmOpenDelete({ open: true, data: data });
  };

  const confirm_handleClose = () => {
    setConfirmOpen({ open: false, data: null });
  };

  const confirm_handleCloseDelete = () => {
    setConfirmOpenDelete({ open: false, data: null });
  };

  const handleRouting = (data) => {
    history.push(`/dashboard/view/${data}`);
  };

  const startFileHaven = (org) => {
    setupHaven(`?org_id=${org.org_id}`)
      .then(() => {
        toaster.notify("File haven has been activated successfully", {
          duration: 4000,
          position: "bottom",
        });
        getSyncedOrganizations().then((res) => {
          props.setOrgs(res.data);
          window.location.reload();
        });
      })
      .catch((err) => {});
  };

  const startFileHavenDeletion = (org) => {
    deleteHaven(`?org_id=${org.org_id}`)
      .then((res) => {
        toaster.notify("File haven has been deactivated successfully", {
          duration: 4000,
          position: "bottom",
        });
        getSyncedOrganizations().then((res) => {
          props.setOrgs(res.data);
          window.location.reload();
        });
      })
      .catch((err) => {});
  };

  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  return (
    <React.Fragment>
      {data !== null ? (
        <React.Fragment>
          {data._hidden_status === 1 ? (
            <React.Fragment>
              <Tooltip title="View">
                <IconButton
                  onClick={() => handleRouting(data.id)}
                  aria-label="View"
                >
                  <VisibilityIcon style={{ color: "#FFF" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Disable Protection">
                <IconButton
                  aria-label="Diasble Protection"
                  onClick={() =>
                    handleConfirmClickOpenDelete({
                      org_id: data.id,
                      org_name: data.organization,
                    })
                  }
                >
                  <LockOpenIcon style={{ color: "#FFF" }} />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          ) : (
            <Tooltip title="Enable Protection">
              <IconButton
                onClick={() =>
                  handleConfirmClickOpen({
                    org_id: data.id,
                    org_name: data.organization,
                  })
                }
                aria-label="Enable Protection"
              >
                <LockIcon style={{ color: "#FFF" }} />
              </IconButton>
            </Tooltip>
          )}
        </React.Fragment>
      ) : null}

      <Dialog
        open={confirmOpen.open}
        onClose={confirm_handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {confirmOpen.data ? confirmOpen.data.org_name : "File Haven"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to enable file haven?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirm_handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              startFileHaven(confirmOpen.data);
              confirm_handleClose();
            }}
            color="primary"
          >
            Yes I'm Sure
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmOpenDelete.open}
        onClose={confirm_handleCloseDelete}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {confirmOpenDelete.data
            ? confirmOpenDelete.data.org_name
            : "File Haven"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to deactivate file haven?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirm_handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              startFileHavenDeletion(confirmOpenDelete.data);
              confirm_handleCloseDelete();
            }}
            color="primary"
          >
            Yes I'm Sure
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  _orgs: state.sync.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionsRenderer);
