export const SET_AUDIT_ORGS = "SET_AUDIT_ORGS";
export const SET_SPACE_HOOKS = "SET_SPACE_HOOKS";
export const SET_APP_HOOKS = "SET_APP_HOOKS";

export const DIS_SPACE_HOOKS = "DIS_SPACE_HOOKS";
export const DESTROY_SPACE_HOOKS = "DESTROY_SPACE_HOOKS";
export const UPDATE_SPACE_HOOKS = "UPDATE_SPACE_HOOKS";
export const REMOVE_SPACE_HOOKS = "REMOVE_SPACE_HOOKS";

export const DIS_APP_HOOKS = "DIS_APP_HOOKS";
export const DESTROY_APP_HOOKS = "DESTROY_APP_HOOKS";
export const UPDATE_APP_HOOKS = "UPDATE_APP_HOOKS";
export const REMOVE_APP_HOOKS = "REMOVE_APP_HOOKS";
