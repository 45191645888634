import React, { useEffect, useState } from "react";
import "../../Iris/Iris.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Endpoint from "../../Iris/Endpoint";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
import { AllModules } from "ag-grid-enterprise";
import { getSyncedOrganizations } from "../../../services/sync";
import { setOrgs } from "../../../redux/actions/sync";
import HookActions from "./HookActions";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import EmptyState from "../../../components/EmptyState";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styled from "@emotion/styled";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HookManager = ({ orgs, user, setOrgs }) => {
  const initialOrg = typeof orgs[0] !== "undefined" ? orgs[0].org_id : null;
  const [org, setOrg] = useState(initialOrg);
  const classes = useStyles();
  const [hookType, setHookType] = useState("space");
  const [loading, setLoading] = useState(false);
  const [space_id, setSpaceId] = useState(null);
  const [spData, setSpData] = useState(null);
  const [app_id, setAppId] = useState(null);
  const [, setApData] = useState(null);
  const [snack, setSnack] = React.useState({
    message: null,
    snackType: null,
    isSnack: false,
    reload: false,
  });

  const [state, setState] = useState({
    columns: [
      { title: "App", field: "app" },
      { title: "Created by", field: "created_by" },
      { title: "Hook Type", field: "type" },
      { title: "Id", field: "id" },
      { title: "Status", field: "status" },
      { title: "Url", field: "url" },
      {
        title: "Action",
        field: "actions",
        cellRenderer: "actionsRenderer",
      },
    ],
    data: [],
    frameworkComponents: {
      actionsRenderer: HookActions,
    },
  });

  const [hookField] = useState({
    columns: [
      { title: "App", field: "app" },
      { title: "Created by", field: "created_by" },
      { title: "Hook Type", field: "type" },
      { title: "Id", field: "id" },
      { title: "Status", field: "status" },
      { title: "Url", field: "url" },
      {
        title: "Action",
        field: "actions",
        cellRenderer: "actionsRenderer",
      },
    ],
    data: [],
    frameworkComponents: {
      actionsRenderer: HookActions,
    },
  });

  const [space, setSpace] = useState({
    columns: [
      { title: "Created by", field: "created_by" },
      { title: "Hook Type", field: "type", rowGroup: true, hide: true },
      { title: "Id", field: "id" },
      { title: "Status", field: "status" },
      { title: "Url", field: "url" },
      { title: "Actions", field: "action" },
      {
        title: "Action",
        field: "actions",
        cellRenderer: "actionsRenderer",
      },
    ],
    data: [],
    frameworkComponents: {
      actionsRenderer: HookActions,
    },
  });
  const [spacesArray, setspacesArray] = useState([]);
  const [appsArray, setappsArray] = useState([]);
  const frameworks = {
    actionsRenderer: HookActions,
  };

  useEffect(() => {
    getSyncedOrganizations().then((response) => setOrgs(response.data));
  }, [setOrgs]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios(
        `https://apisync.thatapp.io/api/v1/sync/spaces/${org}?database=${user.database.database}&skip=0&limit=1000&api_v2=true`
      );

      data.data.map((values) => {
        return setspacesArray((items) => [
          ...items,
          { title: values.name, id: values.space_id },
        ]);
      });
    }

    if (
      (hookType === "space" && org) ||
      (hookType === "app" && org) ||
      (hookType === "app_fields_hooks" && org)
    ) {
      //Avoid duplication
      setspacesArray([]);
      fetchData();
    }
  }, [org, hookType, user.database.database]);

  useEffect(() => {
    async function fetchAppData() {
      axios
        .get(
          `https://apisync.thatapp.io/api/v1/sync/apps/${space_id}/${org}?database=${user.database.database}&skip=0&limit=1000&api_v2=true`
        )
        .then((res) => {
          res.data.data.map((values) => {
            setappsArray((items) => [
              ...items,
              { title: values.config.name, id: values.app_id },
            ]);
            return null;
          });
        });
    }

    if (
      (hookType === "app" && space_id) ||
      (hookType === "app_fields_hooks" && space_id)
    ) {
      setappsArray([]);
      fetchAppData();
    }
    //eslint-disable-next-line
  }, [space_id, hookType]);

  const generateHooks = () => {
    if (hookType === "space" && org) {
      setSpace({
        ...space,
        data: [],
      });

      if (space_id === null) {
        setSnack({
          message: "Please select a space",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
        return;
      }

      setLoading(true);
      Endpoint.hooksUnderSingleSpace(org, user.database.database, space_id)
        .then((res) => {
          res.data.data.forEach((Ddata) => {
            const app_id = Ddata.app_id;

            delete Ddata.app_id;
            const theHooks = Object.keys(Ddata).map((i) => Ddata[i]);

            theHooks.forEach((hook) => {
              setSpace((prevState) => {
                const data = [...prevState.data];

                data.push({
                  space: app_id,
                  created_by: hook.created_by.type,
                  type: hook.type,
                  id: hook.hook_id,
                  status: hook.status,
                  url: hook.url,
                });
                return { ...prevState, data };
              });
            });
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (hookType === "app" && org) {
      if (app_id === null) {
        setSnack({
          message: "Please select an app id",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
        return;
      }

      setSpData(null);
      setLoading(true);
      setState({
        ...state,
        data: [],
      });
      Endpoint.hooksUnderSingleApp(org, user.database.database, app_id)
        .then((res) => {
          res.data.data.forEach((Ddata) => {
            const app_id = Ddata.app_id;

            delete Ddata.app_id;
            const theHooks = Object.keys(Ddata).map((i) => Ddata[i]);

            theHooks.forEach((hook) => {
              setState((prevState) => {
                const data = [...prevState.data];

                data.push({
                  app: app_id,
                  created_by: hook.created_by.type,
                  type: hook.type,
                  id: hook.hook_id,
                  status: hook.status,
                  url: hook.url,
                });
                return { ...prevState, data };
              });
            });
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (hookType === "app_fields_hooks" && org) {
      if (app_id === null) {
        setSnack({
          message: "Please select an app id",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
        return;
      }

      setSpData(null);
      setLoading(true);
      setState({
        ...state,
        data: [],
      });
      Endpoint.fieldHooksUnderSingleApp(org, user.database.database, app_id)
        .then((res) => {
          res.data.data.forEach((Ddata) => {
            const app_id = Ddata.app_id;

            delete Ddata.app_id;
            const theHooks = Object.keys(Ddata).map((i) => Ddata[i]);

            theHooks.forEach((hook) => {
              setState((prevState) => {
                const data = [...prevState.data];

                data.push({
                  app: app_id,
                  created_by: hook.created_by.type,
                  type: hook.type,
                  id: hook.hook_id,
                  status: hook.status,
                  url: hook.url,
                });
                return { ...prevState, data };
              });
            });
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const autoGroupColumnDef = { minWidth: 200 };

  const [grid_loading] = React.useState(
    "No data to display(Click on the generate button to fetch data)"
  );

  function handleClose() {
    setSnack({ message: "", snackType: "", isSnack: false, reload: false });
  }

  return (
    <HookManager.Wrapper>
      {orgs.length === 0 ? (
        <EmptyState />
      ) : (
        <React.Fragment>
          <div className="top-header">
            <div className="form-holder">
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Organisation
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={org}
                  onChange={(e) => setOrg(e.target.value)}
                >
                  {orgs.map((x) => {
                    return (
                      <MenuItem key={x.org_id} value={x.org_id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Hook Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={hookType}
                  onChange={(e) => setHookType(e.target.value)}
                >
                  <MenuItem value="space">Space</MenuItem>
                  <MenuItem value="app">App</MenuItem>
                  <MenuItem value="app_fields_hooks">App Fields Hooks</MenuItem>
                </Select>
              </FormControl>
              {org && (
                <Autocomplete
                  className="sub-title"
                  id="combo-box-demo"
                  options={spacesArray}
                  style={{ minWidth: "120px", marginTop: "0.5rem" }}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, value) => {
                    if (value) {
                      setSpaceId(value.id);
                      setSpData(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Space"
                      variant="standard"
                    />
                  )}
                />
              )}

              {((org && hookType === "app" && space_id) ||
                (org && hookType === "app_fields_hooks" && space_id)) && (
                <Autocomplete
                  style={{ minWidth: "120px", marginTop: "0.5rem" }}
                  className="sub-title"
                  id="combo-box-demo"
                  options={appsArray}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, value) => {
                    if (value) {
                      setAppId(value.id);
                      setApData(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select App"
                      variant="standard"
                    />
                  )}
                />
              )}

              {org &&
                (hookType === "space" ||
                  hookType === "app" ||
                  hookType === "app_fields_hooks") &&
                space_id && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => generateHooks()}
                  >
                    Generate
                  </Button>
                )}
            </div>
          </div>

          {spData && (
            <React.Fragment>
              <h4>{spData.title}</h4>
            </React.Fragment>
          )}

          {loading ? (
            <LinearProgress />
          ) : (
            <div
              className="ag-theme-balham-dark"
              style={{
                height: "700px",
                width: "100%",
              }}
            >
              {hookType === "space" && org && (
                <AgGridReact
                  modules={AllModules}
                  columnDefs={space.columns}
                  defaultColDef={defaultColDef}
                  autoGroupColumnDef={autoGroupColumnDef}
                  overlayNoRowsTemplate={grid_loading}
                  enableRangeSelection={true}
                  animateRows={true}
                  rowData={space.data}
                  rowGroupPanelShow="always"
                  rowSelection="multiple"
                  frameworkComponents={space.frameworkComponents}
                />
              )}
              {hookType === "app" && org && (
                <AgGridReact
                  modules={AllModules}
                  frameworks={frameworks}
                  columnDefs={state.columns}
                  defaultColDef={defaultColDef}
                  autoGroupColumnDef={autoGroupColumnDef}
                  frameworkComponents={state.frameworkComponents}
                  overlayNoRowsTemplate={grid_loading}
                  enableRangeSelection={true}
                  animateRows={true}
                  rowData={state.data}
                  rowGroupPanelShow="always"
                  rowSelection="multiple"
                />
              )}
              {hookType === "app_fields_hooks" && org && (
                <AgGridReact
                  modules={AllModules}
                  frameworks={frameworks}
                  columnDefs={hookField.columns}
                  defaultColDef={defaultColDef}
                  autoGroupColumnDef={autoGroupColumnDef}
                  frameworkComponents={state.frameworkComponents}
                  overlayNoRowsTemplate={grid_loading}
                  enableRangeSelection={true}
                  animateRows={true}
                  rowData={hookField.data}
                  rowGroupPanelShow="always"
                  rowSelection="multiple"
                />
              )}
            </div>
          )}

          <Dialog
            open={snack.isSnack}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <Alert onClose={handleClose} severity={snack.snackType}>
              {snack.message}
            </Alert>
          </Dialog>
        </React.Fragment>
      )}
    </HookManager.Wrapper>
  );
};

HookManager.Wrapper = styled.div`
  .form-holder {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    button {
      height: 75%;
      align-self: center;
    }
  }
`;
const mapStateToProps = (state) => ({
  orgs: state.sync.orgs,
  user: state.user.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HookManager));
