import SideBarHandler from "./SideBarHandler";
import React from "react";
import './styles.css'

const MainNavigation = () => {
  const sidebarRoutes = [
    {
      id: "dashboard",
      icon: "dashboard",
      name: "Dashboard",
      link: "/dashboard/sync",
    },
    {
      id: "haven",
      icon: "insert_drive_file",
      name: "File Haven",
      link: "/dashboard/haven",
    },
    {
      icon: "track_changes",
      name: "Iris",
      link: "/iris",
    },
    {
      icon: "remove_red_eye",
      name: "Monitor",
      submenu: [
        {
          id: "article",
          icon: "",
          name: "Hooks",
          link: "/dashboard/hooks",
        },
        {
          id: "article",
          icon: "",
          name: "Hooks Manager",
          link: "/dashboard/manage-hook",
        },
        {
          id: "article",
          icon: "",
          name: "Raw Data",
          link: "/dashboard/raw-data",
        },
      ],
    },
  ];

  return <SideBarHandler pages={sidebarRoutes} />;
};

export default MainNavigation;
