import React from "react";
// import CustomButton from "./atoms/CustomButton";
import error from "../assets/images/error.png";
import styled from "@emotion/styled";

// more on error boundaries
// https://reactjs.org/docs/error-boundaries.html
const Wrapper = styled.div`
  /* background-color: red; */
  /* display: grid; */
  place-items: center;
  height: 100vh;
  grid-template-columns: 1fr;
  align-items:center;
  text-align:center;
  padding-top:100px;
  img{
    width:300px;
    margin-top:50px;
  }
  h2 {
    margin: 2rem auto;
    color: #292d61;
    font-size: 1rem;
    text-align:center;
  };
  .reload:{
    color: #fff;
    background: #F7C948;
    border-radius: 6px;
    border: none;
    padding: 15px 30px;
  }
`;

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Wrapper>
          <React.Fragment>
            <img src={error} alt="reload" />
            <h1>Something went wrong.</h1>;
            <button
              className="reload"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Reload App
            </button>
          </React.Fragment>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}
