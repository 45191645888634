import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

const Summary = ({ loading, dailyHooks, monthlyHooks, totalHooks }) => {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="audit-tabs">
      <div className="item">
        <div className="label">Daily Hook</div>
        <div className="value">
          {loading ? <CircularProgress /> : numberWithCommas(dailyHooks)}
        </div>
      </div>

      <div className="item">
        <div className="label">Monthly Hooks</div>
        <div className="value">
          {loading ? <CircularProgress /> : numberWithCommas(monthlyHooks)}
        </div>
      </div>

      <div className="item">
        <div className="label">Yearly Hooks</div>
        <div className="value">
          {loading ? <CircularProgress /> : numberWithCommas(totalHooks)}
        </div>
      </div>

      <div className="item">
        <div className="label">Total Hooks</div>
        <div className="value">
          {loading ? <CircularProgress /> : numberWithCommas(totalHooks)}
        </div>
      </div>
    </div>
  );
};

export default Summary;
