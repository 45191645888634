import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PaymentIcon from "@material-ui/icons/Payment";
import styled from "@emotion/styled";
import UserInfo from "./UserInfo";
import { useSelector, useDispatch } from "react-redux";
import PeopleIcon from "@material-ui/icons/People";
import Teams from "./Teams";
import BillingCards from "./BillingCards";
import { getPendingInvitation } from "../../redux/actions/user";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Accounts = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const user = useSelector((state) => state.user.authUser);
  const organisations = useSelector((state) => state.sync.orgs);
  // const unsynced = useSelector((state) => state.getALLUnsyncedOrgs.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPendingInvitation());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabsItem = [
    {
      label: "Overview",
      icon: <VisibilityIcon />,
    },
    {
      label: "Teams",
      icon: <PeopleIcon />,
    },
    {
      label: "Billing Cards",
      icon: <PaymentIcon />,
    },
    // {
    //   label: "Help",
    //   icon: <HelpIcon />,
    // },
    // {
    //   label: "System Cache",
    //   icon: <CachedIcon />,
    // },
  ];

  return (
    <Accounts.Wrapper className={classes.root}>
      <AppBar position="static" color="default" className="Tab__bar">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {TabsItem.map((item, index) => {
            return (
              <Tab
                key={item.label}
                label={item.label}
                icon={item.icon}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <UserInfo
          organisations={organisations}
          user={user}
          // unsynced={unsynced}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Teams />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BillingCards />
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        <HelpTab />
      </TabPanel> */}
      {/* <TabPanel value={value} index={3}>
        <SystemCache />
      </TabPanel> */}
    </Accounts.Wrapper>
  );
};

Accounts.Wrapper = styled.div`
  .Tab__bar {
    background-color: #fff;
    box-shadow: none;
    border-bottom: 1px solid #e1e1e1;
  }
`;
export default Accounts;
