import React from "react";

import "./SyncTable.css";

import SettingsIcon from "../../../assets/icons/settings-icon.svg";
import SyncIcon from "../../../assets/icons/sync-icon.svg";
import EyeIcon from "../../../assets/icons/eye-icon.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../../assets/icons/trash-icon.svg";
import { Link } from "react-router-dom";

const SyncTable = ({ orgs, toggleModal, gdprModal, orgUpdateModal }) => {
  return (
    <div className="sync__table">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Details</th>
              <th>Files</th>
              <th>Created On</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orgs.map((org, i) => {
              let count =
                org.details && org.details.mongo_summary
                  ? org.details.mongo_summary.count
                  : false;
              let details = org.details;

              return (
                <tr key={i}>
                  <td>
                    <p className="title m-0">{org.name}</p>
                    <small className="num">{org.org_id}</small>
                  </td>
                  <td>{org.type}</td>
                  <td>
                    <p className="mb-1">
                      <span className="font-weight-bold">
                        {count ? count.spaces : 0}{" "}
                      </span>{" "}
                      Spaces
                    </p>
                    <p className="mb-1">
                      <span className="font-weight-bold">
                        {count ? count.apps : 0}{" "}
                      </span>{" "}
                      Apps
                    </p>
                    <p className="mb-1">
                      <span className="font-weight-bold">
                        {count ? count.items : 0}
                      </span>{" "}
                      Items{" "}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {count ? count.files : 0}
                    </span>{" "}
                    Files
                  </td>
                  <td>
                    {details && details.mongo_summary
                      ? details.mongo_summary.last_synced
                      : "Queued"}
                  </td>
                  <td>
                    <span className="info">
                      {details && details.mongo_summary
                        ? details.status
                        : "Queued"}
                    </span>
                  </td>
                  <td>
                    <div className="dropdown">
                      <span
                        className="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        ...
                      </span>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            toggleModal(
                              true,
                              "You are about to resync your organization",
                              org.org_id,
                              org.name
                            )
                          }
                        >
                          <img src={SyncIcon} alt="icon" /> Sync
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            orgUpdateModal(org.org_id, org.name, true)
                          }
                        >
                          <img src={EditIcon} alt="icon" width="12px" />
                          Edit
                        </span>
                        {details && (
                          <>
                            <Link to={`/audit/${org.org_id}`}>
                              <span className="dropdown-item">
                                {" "}
                                <img src={EyeIcon} alt="icon" width="13px" />
                                Audit
                              </span>
                            </Link>
                            <span
                              className="dropdown-item"
                              onClick={() => gdprModal(org.org_id, true)}
                            >
                              <img src={SettingsIcon} alt="icon" width="13px" />
                              GDPR Settings
                            </span>
                          </>
                        )}
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            toggleModal(
                              true,
                              "You are about to delete your organization",
                              org.org_id,
                              org.name
                            )
                          }
                        >
                          <img src={DeleteIcon} alt="icon" width="13px" />{" "}
                          Delete
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SyncTable;
