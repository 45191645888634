import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Slider from "@material-ui/core/Slider";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { withRouter } from "react-router";
import $ from "jquery";
import Endpoint from "./Endpoint";
import * as d3 from "d3";
import "./CalcTree.css";
import "./Iris.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#F7F7F7",
  },
}));

const muiTheme = createMuiTheme({
  overrides: {
    MuiSlider: {
      root: {
        color: "#F0B429",
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    },
  },
});

const Iris = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const accessToken = localStorage.getItem("chosen_token");

  const [data, setData] = useState([]);
  const [organisation, setOrganisation] = useState("");

  const [diagram, setDiagram] = useState("");
  const [report, setReport] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASEURL}/iris/get-org-list?token=${accessToken}`
    )
      .then((res) => res.json())
      .then((result) => {
        setData(result.data);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASEURL}/iris/get-edge-bundle-data?token=${accessToken}&type=${report}&org_id=${organisation}`
    ).then((res) => res.json());
  }, [accessToken, organisation, report]);

  // TODO solve the unused variables issuse
  const [expanded, setExpanded] = useState(false);
  // const [selectedOrg] = useState("");
  // const [selectedType] = useState("");
  const [reportTypes] = useState([
    { title: "App Relationship", value: "Relationships" },
    { title: "Calculation Dependencies", value: "Calculations" },
  ]);
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [diagramTypes] = useState([
    { title: "Edge Bundling", value: "bundling" },
    { title: "Radial", value: "radial" },
  ]);
  const [chosen_type, setChosenType] = useState("");
  const [currentOpenSpace, setCurrentOpenSpace] = React.useState([]);
  const [currentOpenApp, setCurrentOpenApp] = React.useState([]);
  const [spacesData, setSpacesData] = React.useState(null);
  const [, setSettingOpen] = React.useState(false);
  const [, setTreeData] = React.useState({ status: false, data: {} });

  //Initial control settings for Iris
  let _innerRadius = 300;
  let _size = 360;
  let svgHeight = `100%`;
  let svgWidth = `100%`;
  const [_zoom, setZoom] = useState(1);
  const [_diameter, setDiameter] = useState(1);
  const [irisData, setIrisData] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [vertical, setVertical] = useState(-73);

  const [horizontal, setHorizontal] = useState(0);
  const [tension, setTension] = useState(0);
  const [legend, setLegend] = useState(null);

  function clearDiv() {
    document.getElementById("theMap").innerHTML = "";
    $(document).ready(function () {
      $("#theMap").append(
        '<div id="theBody"><div id="map" className="svg-container"></div></div>'
      );
    });
    let thediv = document.createElement("div");

    thediv.setAttribute("class", "svg-container");
    thediv.setAttribute("id", "map");
    document.getElementById("theMap").appendChild(thediv);
  }

  useEffect(() => {
    clearDiv();

    if (irisData !== null && report !== null) {
      let classes = [];

      irisData.forEach((ele) => {
        const _node = ele.name.split(".");
        let thenode = standardizeText(_node[1]).replace(
          new RegExp(" ", "g"),
          "_"
        );

        if (!currentOpenSpace.includes(thenode)) {
          classes.push(ele);
        }
      });

      const div = d3
        .select("#theBody")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      const diameter = _diameter,
        radius = diameter / 2,
        innerRadius = radius - _innerRadius;

      const color = d3.scaleOrdinal(d3.schemeCategory10);

      const cluster = d3
        .cluster()
        .separation(function (a, b) {
          return a.parent === b.parent ? 1 : 5;
        })
        .size([_size, innerRadius]);

      const line = d3
        .radialLine()
        .curve(d3.curveBundle.beta(tension))
        .radius(function(d){
          return d.y;
        })
        .angle(function(d){
          return d.x / 180 * Math.PI;
        });

      // const zoom = d3.zoom().on("zoom", zoomed);
      d3.zoom().scaleExtent([1, 2]).on("zoom", zoomed);

      function zoomed() {
        svg.attr("transform", d3.event.transform);

        // var e = d3.event;

        // // now, constrain the x and y components of the translation by the
        // // dimensions of the viewport

        // const tx = Math.min(
        //   0,
        //   Math.max(e.transform.x, svgHeight - svgHeight * e.scale)
        // );
        // const ty = Math.min(
        //   0,
        //   Math.max(e.transform.y, svgHeight - svgHeight * e.scale)
        // );
        // // then, update the zoom behavior's internal translation, so that
        // // it knows how to properly manipulate it on the next movement

        // zoom.translate([tx, ty]);
        // // and finally, update the <g> element's transform attribute with the
        // // correct translation and scale (in reverse order)
        // svg.attr(
        //   "transform",
        //   ["translate(" + [tx, ty] + ")", "scale(" + e.scale + ")"].join(" ")
        // );
      }

      const svg = d3
        .select("#map")
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "-800 -600 2000 1200")
        .attr("id", "theSvgId")
        .classed("svg-content", true);

      svg
        .attr("height", svgHeight)
        .attr("weight", svgWidth)
        // .call(zoom)
        .append("g")
        .attr("transform", "translate(" + radius + "," + radius + ")");

      let link = svg.append("g").selectAll(".link");
      let node = svg.append("g").selectAll(".node");

      setLoading(false);

      const root = packageHierarchy(classes).sum(function (d) {
        return d.size;
      });

      cluster(root);

      const x = packageImports(root.leaves());

      const arr = [],
        arr2 = [];

      for (let i = 0; i < x.length; i++) {
        arr.push(x[i][0].data.key);
        arr2.push(x[i][x[i].length - 1].data.key);
      }

      link = link
        .data(x)
        .enter()
        .append("path")
        .each(function (d) {
          d.source = d[0];
          d.target = d[d.length - 1];
        })
        .attr("class", "link")
        .style("stroke", function (d) {
          let node = d[0].data.name;

          node = node.split(".");

          let space = standardizeText(node[1]).replace(
            new RegExp(" ", "g"),
            "_"
          );

          if (report === "Calculations") {
            if (!CheckKey(d[0].data.key.replace("---", " ")))
              return color(space);
          } else {
            if (!CheckKey(d[0].data.key.split("---")[1])) return color(space);
          }
        })
        .attr("d", line);

      node = node.data(root.leaves());

      node
        .enter()
        .append("a")
        .attr("class", "node")
        .attr("dy", "0.31em")
        .append("text")
        .attr("class", "node")
        .attr("dy", "0.31em")
        .attr("transform", function (d) {
          return (
            "rotate(" +
            (d.x - 90) +
            ")translate(" +
            (d.y + 8) +
            ",0)" +
            (d.x < 180 ? "" : "rotate(180)")
          );
        })
        .attr("text-anchor", function (d) {
          return d.x < 180 ? "start" : "end";
        })
        .append("a")
        .text(function (d) {
          if (report === "Calculations") {
            if (!CheckKey(d.data.key.replace("---", " ")))
              return d.data.key.replace("---", " ");
          } else if (report === "Relationships") {
            if (!CheckKey(d.data.key.split("---")[1]))
              return d.data.key.split("---")[1];
          } else {
            if (!CheckKey(d.data.key.split("---")[1]))
              return d.data.key.split("---")[1];
          }
        })
        .style("fill", function (d) {
          let node = d.data.name;

          node = node.split(".");
          let space = standardizeText(node[1]).replace(
            new RegExp(" ", "g"),
            "_"
          );

          return color(space);
        })
        .on("mouseover", mouseovered)
        .on("mouseout", mouseouted)
        .on("click", performClick);

      /**
       * @return {boolean}
       */
      function CheckKey(key) {
        let x = false;

        currentOpenApp.forEach((e) => {
          let lastIndex = e.lastIndexOf("_");
          let str = e.substring(0, lastIndex);

          str = str.replace("_", " ");

          if (str === key) {
            x = true;
          }
        });

        return x;
      }

      function mouseovered(d) {
        if (report === "Calculations") {
          d3.select(this).style("cursor", "pointer");
        }

        node.each(function (n) {
          n.target = n.source = false;
        });

        link
          .classed("link--target", function (l) {
            if (l.target === d) return (l.source.source = true);
          })
          .classed("link--source", function (l) {
            if (l.source === d) return (l.target.target = true);
          })
          .filter(function (l) {
            return l.target === d || l.source === d;
          })
          .raise();

        node
          .classed("node--target", function (n) {
            return n.target;
          })
          .classed("node--source", function (n) {
            return n.source;
          });

        div.transition().duration(200).style("opacity", 0.9);
        div
          .html(d.data.key + "<br/>")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      }

      function mouseouted() {
        d3.select(this).style("cursor", "default");

        link.classed("link--target", false).classed("link--source", false);

        node.classed("node--target", false).classed("node--source", false);
        div.transition().duration(500).style("opacity", 0);
      }

      function performClick(d) {
        setTreeData({ status: true, data: d });
      }

      // Lazily construct the package hierarchy from class names.
      function packageHierarchy(classes) {
        let map = {};

        function find(name, data) {
          let node = map[name],
            i;

          if (!node) {
            node = map[name] = data || { name: name, children: [] };

            if (name.length) {
              node.parent = find(
                name.substring(0, (i = name.lastIndexOf(".")))
              );
              node.parent.children.push(node);
              node.key = name.substring(i + 1);
            }
          }

          return node;
        }

        classes.forEach(function (d) {
          find(d.name, d);
        });

        return d3.hierarchy(map[""]);
      }

      // Return a list of imports for the given array of nodes.
      function packageImports(nodes) {
        let map = {};
        let imports = [];

        // Compute a map from name to node.
        nodes.forEach(function (d) {
          map[d.data.name] = d;
        });

        // For each import, construct a link from the source to target node.
        nodes.forEach(function (d) {
          if (d.data.imports)
            d.data.imports.forEach(function (i) {
              if (
                typeof map[i] !== "undefined" &&
                typeof map[d.data.name] !== "undefined"
              )
                imports.push(map[d.data.name].path(map[i]));
            });
        });

        return imports;
      }

      function standardizeText(label) {
        if (report === "Calculations") {
          let key = label.split("---");
          let result = "";

          if (key[1]) {
            result += key[1];
          }

          result += " ";

          if (key[0]) {
            result += key[0];
          }

          return result;
        }

        let key = label.split("---");
        let result = "";

        if (key[1]) {
          result += key[1];
        }

        result += " ";

        if (key[0]) {
          result += key[0];
        }

        return result;
      }

      let domain = color.domain();
      let theLegend = [];
      let _legendJsx = [];

      for (let i = 0; i < domain.length; i++) {
        theLegend.push({ label: domain[i], color: color(domain[i]) });
      }

      for (let i = 0; i < theLegend.length; i++) {
        const each_legend = theLegend[i];
        // push the tr to array, the key is important

        _legendJsx.push(
          <div key={i}>
            <div>
              <svg width="8" height="8">
                <rect width="100%" height="100%" fill={each_legend.color} />
              </svg>
              &nbsp;&nbsp; {each_legend.label}
            </div>
          </div>
        );
      }

      setLegend(_legendJsx);
    }
  }, [
    irisData,
    _diameter,
    _innerRadius,
    _size,
    classes,
    svgHeight,
    svgWidth,
    report,
    _zoom,
    tension,
    spacesData,
    currentOpenSpace,
    currentOpenApp,
  ]);

  const handleOpenClick = (panel, type = "space") => {
    if (type === "space") {
      if (currentOpenSpace.includes(panel)) {
        return setCurrentOpenSpace(
          currentOpenSpace.filter((item) => item !== panel)
        );
      }

      setCurrentOpenSpace([...currentOpenSpace, panel]);
    }

    if (type === "app") {
      if (currentOpenApp.includes(panel)) {
        return setCurrentOpenApp(
          currentOpenApp.filter((item) => item !== panel)
        );
      }

      setCurrentOpenApp([...currentOpenApp, panel]);
    }
  };

  useEffect(() => {
    if (document.getElementById("theSvgId") !== null) {
      document.getElementById("theSvgId").style.transformOrigin =
        "center center";
      document.getElementById("theSvgId").style.transform =
        "rotate(" + rotation + "deg) scale(" + _zoom + ")";
      document.getElementById("theSvgId").style.top = vertical + "px";
      document.getElementById("theSvgId").style.left = horizontal + "px";
    }
  }, [rotation, _zoom, vertical, horizontal]);

  const loadEdgeBundling = (classes) => {
    let nodeCount = classes.length;
    let nodeBunch = 60;
    let circumferenceMin = 2000;
    let circumferenceBloater = 17;
    let _zoom = nodeBunch / nodeCount;

    let diameter =
      (circumferenceMin + nodeCount * circumferenceBloater) / Math.PI;

    if (diameter > 4000) diameter = 4000;
    if (_zoom < 0.15) _zoom = 0.15;
    //Insert Spaces, Apps, and Fields to state
    // setZoom(Math.ceil(_zoom));
    setDiameter(diameter);
    setIrisData(classes);
  };

  const handleEdgeBundling = async () => {
    const _url = `${process.env.REACT_APP_BASEURL}/iris/get-edge-bundle-data?token=${accessToken}&type=${report}&org_id=${organisation}`;

    setUrl(_url);
    let classes = [];

    await fetch(_url)
      .then((res) =>
        res.json().then((result) => {
          classes = result;
        })
      )
      .catch(() => {
        return false;
      });
    await performSpaceToState(classes);
    loadEdgeBundling(classes);
  };

  const handleRadial = async () => {
    let radial_data = [];

    // Relationships;
    // await Endpoint.getCalcBundle(selectedOrg.org_id, report).then(
    await Endpoint.getCalcBundle(organisation, report).then((res) => {
      setLoading(false);
      radial_data = res.data;
    });


    const zoomed = () => {
      svg.attr("transform", d3.event.transform);
    //   var e = d3.event,
    //     // now, constrain the x and y components of the translation by the
    //     // dimensions of the viewport
    //     tx = Math.min(
    //       0,
    //       Math.max(e.translate[0], svgHeight - svgHeight * e.scale)
    //     ),
    //     ty = Math.min(
    //       0,
    //       Math.max(e.translate[1], svgHeight - svgHeight * e.scale)
    //     );
    //   then, update the zoom behavior's internal translation, so that
    //   it knows how to properly manipulate it on the next movement
    //   zoom.translate([tx, ty]);
    //   // and finally, update the <g> element's transform attribute with the
    //   // correct translation and scale (in reverse order)
    //   svg.attr(
    //     "transform",
    //     ["translate(" + [tx, ty] + ")", "scale(" + e.scale + ")"].join(" ")
    //   );
    }

    let zoom = d3.zoom().scaleExtent([1, 2]).on("zoom", zoomed);
    // let zoom = d3.zoom().on("zoom", zoomed);
    let svg = d3.select("#map").call(zoom);
    let width = +svg.attr("width");
    let height = +svg.attr("height");

    let transform = d3.zoomIdentity.translate(width / 2, height / 2 + 20);

    let g = svg.append("g");

    svg.call(zoom.transform, transform);

    let cluster = d3.cluster().size([_size, width / 2 - 120]);

    //let self = this;

    let root = d3.hierarchy(radial_data);

    cluster(root);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // let link = ...
    g.selectAll(".radial_node_link")
      .data(root.descendants().slice(1))
      .enter()
      .append("path")
      .attr("class", "radial_node_link")
      .style("stroke", function (d) {
        return color(d.data.external_id);
      })
      .attr("d", (d) => {
        return (
          "M" +
          project(d.x, d.y) +
          "C" +
          project(d.x, (d.y + d.parent.y) / 2) +
          " " +
          project(d.parent.x, (d.y + d.parent.y) / 2) +
          " " +
          project(d.parent.x, d.parent.y)
        );
      });

    let node = g
      .selectAll(".radial_node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", (d) => {
        return (
          "radial_node" +
          (d.children ? " radial_node--internal" : " radial_node--leaf")
        );
      })
      .style("fill", function (d) {
        return color(d.data.external_id);
      })
      .attr("transform", (d) => {
        return "translate(" + project(d.x, d.y) + ")";
      });

    // .on("mouseover", mouseovered)
    //         .on("mouseout", mouseouted);

    node.append("circle").attr("r", 2.2);

    node
      .append("text")
      .attr("dy", "0.31em")
      .attr("x", (d) => {
        // eslint-disable-next-line
        return d.x < 180 === !d.children ? 6 : -6;
      })
      .style("text-anchor", (d) => {
        // eslint-disable-next-line
        return d.x < 180 === !d.children ? "start" : "end";
      })
      .attr("transform", (d) => {
        return "rotate(" + (d.x < 180 ? d.x - 90 : d.x + 90) + ")";
      })
      .text((d) => d.data.app);

    function project(x, y) {
      let angle = ((x - 90) / 180) * Math.PI,
        radius = y;

      return [radius * Math.cos(angle), radius * Math.sin(angle)];
    }
  };

  useEffect(() => {
    Endpoint.getIrisOrgs()
      .then((res) => {
        let org = [];

        res.data.data.map((item) => {
          return org.push({ org_name: item.org_name, org_id: item.org_id });
        });
      })
      .catch(() => {});
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleGenerate = async () => {
    if (organisation && diagram && report) {
      setSettingOpen(true);
      setExpanded("panel4");
      setLoading(true);
      document.getElementById("theMap").innerHTML = "";

      if (chosen_type === "radial") {
        //Create div with id map and svg too
        $(document).ready(function () {
          $("#theMap").append('<svg width="1200" height="1200" id="map" />');
        });
        setLoading(true);
        return handleRadial();
      }

      return handleEdgeBundling();
    }
  };

  const performSpaceToState = (classes) => {
    const spaces = [];

    if (report === "Calculations") {
      for (let i = 0; i < classes.length; i++) {
        let node = classes[i].name;

        node = node.split(".");
        let space = standardizeText(node[1]).replace(new RegExp(" ", "g"), "_");
        let app = standardizeText(node[2]).replace(new RegExp(" ", "g"), "_");
        let field = standardizeText(node[3]).replace(new RegExp(" ", "g"), "_");

        //space
        insertSpace(space);
        //apps
        insertApp(space, app);
        //fields
        insertField(field, app, space);
      }
    }

    if (report === "Relationships") {
      for (let i = 0; i < classes.length; i++) {
        let node = classes[i].name;

        node = node.split(".");
        let space = standardizeText(node[1]).replace(new RegExp(" ", "g"), "_");
        let app = standardizeText(node[2]).replace(new RegExp(" ", "g"), "_");

        //space
        insertSpace(space);
        //apps
        insertApp(space, app);
      }
    }

    function insertSpace(space) {
      let found = false;

      for (let s = 0; s < spaces.length; s++) {
        if (spaces[s].name === space) {
          found = true;
          break;
        }
      }

      if (!found) {
        spaces.push({ name: space, apps: [] });
      }
    }

    function insertApp(space, app) {
      for (let s = 0; s < spaces.length; s++) {
        if (spaces[s].name === space) {
          let apps = spaces[s].apps;
          let found = false;

          for (let a = 0; a < apps.length; a++) {
            if (apps[a].name === app) {
              found = true;
              break;
            }
          }

          if (!found) {
            spaces[s].apps.push({ name: app, fields: [] });
          }
        }
      }
    }

    function insertField(field, app, space) {
      for (let s = 0; s < spaces.length; s++) {
        if (spaces[s].name === space) {
          let apps = spaces[s].apps;

          for (let a = 0; a < apps.length; a++) {
            if (apps[a].name === app) {
              let fields = spaces[s].apps[a];
              let found = false;

              for (let f = 0; f < fields.length; f++) {
                if (field[f].name === field) {
                  found = true;
                  break;
                }
              }

              if (!found) {
                spaces[s].apps[a].fields.push({ name: field });
              }
            }
          }
        }
      }
    }

    setSpacesData(spaces);

    function standardizeText(label) {
      if (report === "Calculations") {
        let key = label.split("---");
        let result = "";

        if (key[1]) {
          result += key[1];
        }

        result += " ";

        if (key[0]) {
          result += key[0];
        }

        return result;
      }

      let key = label.split("---");
      let result = "";

      if (key[1]) {
        result += key[1];
      }

      result += " ";

      if (key[0]) {
        result += key[0];
      }

      return result;
    }
  };

  // const handleFilterChange = (event) => {
  //   setIrisData({ ...irisData, [event.target.name]: event.target.checked });
  // };

  // const [state, setState] = React.useState({})

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header baropen={open} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className="iris">
          <div className="iris-tabs">
            <div className="item">
              <div className="label">Organisation</div>
              <select
                className="sub-title"
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
              >
                <option value="">Select organisation</option>
                {data.map((item) => {
                  return (
                    <option key={item.org_name} value={item.org_id}>
                      {item.org_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="item">
              <div className="label">Diagram Type</div>
              <select
                className="sub-title"
                value={diagram}
                onChange={(e) => {
                  setChosenType(e.target.value);
                  setDiagram(e.target.value);
                }}
              >
                <option value="">Select diagram type</option>
                {diagramTypes.map((item) => {
                  return (
                    <option key={item.title} value={item.value}>
                      {item.title}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="item">
              <div className="label">Report Type</div>
              <select
                className="sub-title"
                value={report}
                onChange={(e) => setReport(e.target.value)}
              >
                <option value="">Select report type</option>
                {reportTypes.map((item) => {
                  return (
                    <option key={item.title} value={item.value}>
                      {item.title}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="item button-container">
              <div className="label"></div>
              <button
                disabled={loading}
                onClick={() => {
                  setUrl(url);
                  return handleGenerate();
                }}
              >
                {loading ? (
                  <CircularProgress
                    color="#fff"
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  "Generate"
                )}
              </button>
            </div>
          </div>

          <div className="iris-container">
            <div className="content">
              <div className="inner-content">
                <div id="theMap" className="Map svg-element"></div>
                {loading && (
                  <div id="loader-holder">
                    <LinearProgress
                      style={{
                        marginTop: "2rem",
                        textAlign: "center",
                        width: "50%",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="filter">
              <div className="settings-container tab-container">
                <div className="title">Settings</div>
                <div className="tab">
                  <div className="label">Rotation</div>
                  <ThemeProvider theme={muiTheme}>
                    <Slider
                      valueLabelDisplay="auto"
                      name="rotation"
                      min={0.1}
                      max={10}
                      onChange={(e, value) => {
                        setRotation(value);
                      }}
                      step={0.01}
                      value={rotation}
                    />
                  </ThemeProvider>
                </div>
                <div className="tab">
                  <div className="label">Zoom</div>
                  <ThemeProvider theme={muiTheme}>
                    <Slider
                      valueLabelDisplay="auto"
                      name="_zoom"
                      min={1}
                      max={4}
                      onChange={(e, value) => {
                        setZoom(value);
                      }}
                      step={0.01}
                      value={_zoom}
                    />
                  </ThemeProvider>
                </div>
                <div className="tab">
                  <div className="label">Diameter</div>
                  <ThemeProvider theme={muiTheme}>
                    <Slider
                      valueLabelDisplay="auto"
                      name="_diameter"
                      min={0.1}
                      max={2500}
                      onChange={(e, value) => {
                        setDiameter(value);
                      }}
                      step={0.01}
                      value={_diameter}
                    />
                  </ThemeProvider>
                </div>
                <div className="tab">
                  <div className="label">Tension</div>
                  <ThemeProvider theme={muiTheme}>
                    <Slider
                      valueLabelDisplay="auto"
                      name="tension"
                      min={0.1}
                      max={9.0}
                      onChange={(e, value) => {
                        setTension(value);
                      }}
                      step={0.01}
                      value={tension}
                    />
                  </ThemeProvider>
                </div>
                <div className="tab">
                  <div className="label">Vertical</div>
                  <ThemeProvider theme={muiTheme}>
                    <Slider
                      valueLabelDisplay="auto"
                      name="vertical"
                      // valueLabelDisplay="auto"
                      min={-73}
                      max={50}
                      onChange={(e, value) => {
                        setVertical(value);
                      }}
                      step={0.01}
                      value={vertical}
                    />
                  </ThemeProvider>
                </div>
                <div className="tab">
                  <div className="label">Horizontal</div>
                  <ThemeProvider theme={muiTheme}>
                    <Slider
                      valueLabelDisplay="auto"
                      name="horizontal"
                      min={190}
                      max={420}
                      onChange={(e, value) => {
                        setHorizontal(value);
                      }}
                      step={0.01}
                      value={horizontal}
                    />
                  </ThemeProvider>
                </div>
              </div>

              <div className="filter-container tab-container">
                {/* <div className="title">Color Legend</div> */}
                <div className="tab">
                  {irisData !== null ? (
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handleChange("panel5")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        className={classes.collapseHeading}
                      >
                        <Typography className={classes.heading}>
                          Color Legend
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="panel-body">{legend}</div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="filter-container tab-container">
                <div className="tab">
                  <Accordion
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      className={classes.collapseHeading}
                    >
                      <Typography className={classes.heading}>
                        Filter
                      </Typography>
                    </AccordionSummary>

                    {spacesData !== null
                      ? spacesData.map((iris, key) => (
                          <AccordionDetails key={key}>
                            <div>
                              <FormControl component="fieldset">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      // currentOpenSpace.includes(iris.name)
                                      // ?
                                      <Checkbox
                                        checked={iris.key}
                                        onChange={() => {
                                          handleOpenClick(iris.name, "space");
                                        }}
                                        name={iris.key}
                                      />
                                      // : ''
                                    }
                                    className="panel-body"
                                    label={iris.name}
                                  />
                                </FormGroup>
                              </FormControl>
                            </div>
                          </AccordionDetails>
                        ))
                      : ""}
                  </Accordion>

                  <br />
                  <div>
                    Note: Filtered items may still appear in the graph if they
                    are referencedfrom other locations.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default withRouter(Iris);
