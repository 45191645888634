import React from "react";

const TeamsList = (props) => {
  return (
    <div className="item" key={props.key}>
      {props.pending && <span className="pending-invite">Pending...</span>}
      <div
        className="image"
        style={{
          backgroundImage: `url(${props.image})`,
          backgroundSize: `cover`,
          backgroundPosition: `center`,
        }}
      ></div>
      <div className="text-container">
        <div className="name">
          {props.name} {props.role === "Admin" ? "(You)" : ""}
        </div>
        <div className="email">{props.email}</div>
      </div>

      {/* <div className="role">{props.role}</div>*/}
    </div>
  );
};

export default TeamsList;
