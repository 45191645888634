import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Swal from "sweetalert2";

const ImitateAction = (props) => {
  //Action Display
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  return (
    <>
      {data !== null ? (
        <>
          <Tooltip title="Imitate User" style={{ marginTop: "-10px" }}>
            <IconButton
              aria-label="Imitate User"
              onClick={() => {
                const x = data.user_id_extension;

                if (x.toLowerCase() === "not connected") {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text:
                      "Podio is not connected for this user, you can't imitate their account at this time.",
                  });
                  return false;
                } else {
                  window.location.href = `/imitate-account?imitate_user=true&task=${data.id}`;
                }
              }}
            >
              <VisibilityIcon style={{ color: "#FFF" }} />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ImitateAction;
