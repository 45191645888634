import React from "react";
import Confirm from "../../../../components/Modal/Confirm";
import {
  banMember,
  deleteSpace,
  refreshMember,
  removeMember,
  syncSpaces,
} from "../../../../services/sync";
import toaster from "toasted-notes";

const MembersActions = (props) => {
  const initial = {
    message: null,
    modal_type: null,
    title: "",
    modal_value: false,
    modal_data: {},
    popup: false,
  };
  const [syncModal, syncModalData] = React.useState(initial);

  //Action
  const handleSubmit = (type) => {
    let data;

    if (type === "syncspace") {
      data = {
        org_id: syncModal.modal_data.org,
        space_id: syncModal.modal_data.space,
      };
      syncSpaces(`?org_id=${data.org_id}&space_ids=${data.space_id}`)
        .then((r) => {
          syncModalData(initial);
          toaster.notify("Space synced successfully", {
            duration: 4000,
            position: "bottom",
          });
          window.document.getElementById("refreshThisGrid").click();
        })
        .catch((e) => {
          syncModalData(initial);
        });
    }

    if (type === "refresh") {
      refreshMember(
        `?org_id=${syncModal.modal_data.org}&space_id=${syncModal.modal_data.space}&user_id=${syncModal.modal_data.user_id}`
      )
        .then((r) => {
          syncModalData(initial);
          toaster.notify("Member refreshed successfully", {
            duration: 4000,
            position: "bottom",
          });
          window.document.getElementById("refreshThisGrid").click();
        })
        .catch((e) => {
          syncModalData(initial);
        });
    }

    if (type === "remove_from_space") {
      removeMember(
        `?org_id=${syncModal.modal_data.org}&space_id=${syncModal.modal_data.space}&user_id=${syncModal.modal_data.user_id}`
      )
        .then((r) => {
          syncModalData(initial);
          toaster.notify("Member removed successfully", {
            duration: 4000,
            position: "bottom",
          });
          window.document.getElementById("refreshThisGrid").click();
        })
        .catch((e) => {
          syncModalData(initial);
        });
    }

    if (type === "ban") {
      banMember(
        `?org_id=${syncModal.modal_data.org}&user_id=${syncModal.modal_data.user_id}`
      )
        .then((r) => {
          syncModalData(initial);
          toaster.notify("Member banned from organisation successfully", {
            duration: 4000,
            position: "bottom",
          });
          window.document.getElementById("refreshThisGrid").click();
        })
        .catch((e) => {
          syncModalData(initial);
        });
    }

    if (type === "deletespace") {
      data = {
        org_id: syncModal.modal_data.org,
        space_id: syncModal.modal_data.space,
      };
      deleteSpace(`?org_id=${data.org_id}&space_id=${data.space_id}`)
        .then((r) => {
          syncModalData(initial);
          toaster.notify(
            "This space and its apps/items were deleted successfully, refresh the grid to see results",
            {
              duration: 4000,
              position: "bottom",
            }
          );
          window.document.getElementById("refreshThisGrid").click();
        })
        .catch((e) => {
          syncModalData(initial);
        });
    }
  };

  const removeFromSpace = (org, space, user_id) => {
    syncModalData({
      message: "Do you want to remove this member from this space?",
      modal_type: "remove_from_space",
      title: "Remove member",
      modal_value: true,
      modal_data: { org, space, user_id },
      popup: true,
    });
  };

  const refreshUser = (org, space, user_id) => {
    syncModalData({
      message: "Do you want to refresh this member?",
      modal_type: "refresh",
      title: "Refresh member",
      modal_value: true,
      modal_data: { org, space, user_id },
      popup: true,
    });
  };

  // const banFromOrg = (org, user_id) => {
  //   syncModalData({
  //     message: "Do you want to ban this member from your organisation?",
  //     modal_type: "ban",
  //     title: "Ban member",
  //     modal_value: true,
  //     modal_data: { org, user_id },
  //     popup: true,
  //   });
  // };

  const syncSpacePopup = (org, space) => {
    syncModalData({
      message: "Do you want to Resync this Space?",
      modal_type: "syncspace",
      title: "Sync Space",
      modal_value: true,
      modal_data: { org, space },
      popup: true,
    });
  };

  const deleteSpacePopup = (org, space) => {
    syncModalData({
      message:
        "Do you want to Delete this Space and all its data from our system?",
      modal_type: "deletespace",
      title: "Delete space",
      modal_value: true,
      modal_data: { org, space },
      popup: true,
    });
  };

  const toggleModal = () => {
    syncModalData(initial);
  };

  //Action Display
  const dataInGroup =
    typeof props.node["childrenAfterGroup"] !== "undefined"
      ? props.node.childrenAfterGroup[0].data
      : {};
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  return (
    <React.Fragment>
      <Confirm
        className="confirm-modal-container"
        openModal={syncModal.modal_value}
        message={syncModal.message}
        action={() => handleSubmit(syncModal.modal_type)}
        title={syncModal.title}
        toggleModal={toggleModal}
      />
      {data !== null ? (
        <React.Fragment>
          <i
            className="fa fa-trash"
            style={{
              fontSize: "1.50em",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Remove from space"
            onClick={() =>
              removeFromSpace(data.org_id, data.space_id, data.user_id)
            }
          ></i>
          <i
            className="fa fa-redo-alt"
            style={{
              fontSize: "1.50em",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Refresh user"
            onClick={() =>
              refreshUser(data.org_id, data.space_id, data.user_id)
            }
          ></i>
          {/*<i*/}
          {/*  className="fa fa-ban"*/}
          {/*  style={{*/}
          {/*    fontSize: "1.50em",*/}
          {/*    marginRight: "10px",*/}
          {/*    cursor: "pointer",*/}
          {/*  }}*/}
          {/*  title="Banish user from organisation"*/}
          {/*  onClick={() => banFromOrg(data.org_id, data.space_id, data.user_id)}*/}
          {/*></i>*/}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <i
            className="fa fa-sync-alt"
            style={{
              fontSize: "1.50em",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Resync Space"
            onClick={() =>
              syncSpacePopup(dataInGroup.org_id, dataInGroup.space_id)
            }
          ></i>
          <i
            className="fa fa-trash"
            style={{
              fontSize: "1.50em",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Delete Space"
            onClick={() =>
              deleteSpacePopup(dataInGroup.org_id, dataInGroup.space_id)
            }
          ></i>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MembersActions;
