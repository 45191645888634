import React, { useState } from "react";
import "../index.css";
import Logo from "../../../assets/images/logosync.svg";
import { Link } from "react-router-dom";
import { authorization_check, podioAuthUser } from "../../../services/auth";
import { setUser } from "../../../redux/actions/user";
import { connect } from "react-redux";
import Asterisk from "../../../components/Common/Asterisk";
import { CircularProgress, Button } from "@material-ui/core";

const Authorizepass = ({ setUser }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [userData, setUserData] = useState({
    name: "",
    existing_user: false,
    email: "",
    password: null,
  });
  const [theData, setTheData] = useState(null);

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("podio_access_object"));

    setTheData(data);
    //Validate user
    authorization_check(`?refresh_token=${data.refresh_token}`).then((res) => {
      setLoading(false);
      setUserData(res);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const hidePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }

    setPasswordType("password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { password } = data;
    const object = {
      email: userData.email,
      password,
      access_token: theData.access_token,
      refresh_token: theData.refresh_token,
      ref_id: theData.user_id,
    };

    try {
      let response = await podioAuthUser(object);

      if (response) {
        if (response.data) {
          localStorage.setItem("chosen_token", response.data.access_token);
          setUser(response.data);
          localStorage.removeItem("podio_access_object");
        }
      }
    } catch (x) {
      setLoading(false);
    }
  };

  return (
    <div className="auth">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="auth__sidebar">
            <div className="layer">
              <div className="custom__container__80">
                <img src={Logo} alt="logo" />
                <h1 className="title">
                  Welcome to <br /> ThatApp!
                </h1>
                <p className="description">
                  It is our job to ensure that you are never lost in the clouds.
                  Gain a refreshing perspective of your business processes and
                  strategic initiatives through the eyes of our skilled workflow
                  architects and system integrators.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="auth__component">
            <div className="custom__container__80">
              <div className="text-right">
                <p className="route__text">
                  Do not have an account?
                  <Link className="link__text" to="/register">
                    {"  "}Sign up
                  </Link>
                </p>
              </div>
              <div className="auth__form__container mt-5">
                <h1>
                  Hi {userData.name}, <br />
                  {userData.existing_user
                    ? "Welcome back"
                    : "Welcome to ThatApp"}
                </h1>
                <p>
                  {userData.existing_user && userData.password
                    ? "Kindly provide your password to continue."
                    : "To continue, kindly create a password."}
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        Password <Asterisk />
                      </label>
                      <input
                        className="form-control"
                        type={passwordType}
                        name="password"
                        required
                        value={data.password}
                        onChange={handleChange}
                      />
                      <i
                        className={`${
                          passwordType === "password"
                            ? "far fa-eye"
                            : "far fa-eye-slash"
                        } field-icon2`}
                        onClick={hidePassword}
                      ></i>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    color="primary"
                  >
                    {loading && <CircularProgress className="mr-1" size={20} />}{" "}
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
});

export default connect(null, mapDispatchToProps)(Authorizepass);
