import React from "react";
import Logo from "../../assets/images/logoyellow.svg";
import Ava from "../../assets/images/avaservice.svg";
import Sync from "../../assets/images/syncservice.svg";
import Print from "../../assets/images/printio.png";
import { CONNECT_PRINT } from "../pagePath";
import { useSelector, useDispatch } from "react-redux";
import {useHistory} from 'react-router-dom'
import "./ChooseService.css";
import CustomButton from "../../components/atoms/CustomButton";
import { logout } from '../../redux/actions/auth';

const ChooseService = () => {
  const user = useSelector((state) => state.user.authUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const cards = [
    {
      image: Sync,
      text: "Backup and Protect your",
      text2: "Sensitive Data!",
      onClick: () => window.location.href="/connect",
    },
    {
      image: Ava,
      text: "Integrate.",
      text2: "Automate. Relax!",
      onClick: () => window.open("https://ava.thatapp.io/login", "_blank"),
    },
    {
      image: Print,
      text: "Dynamic Printing Templates,",
      text2: "Blockchain security and Smart Contracts!",
      onClick: () => window.location.href=CONNECT_PRINT,
    },
  ];

  return (
    <div className="choose__service">
      <div className="container">
        <div className="header">
          <div><img src={Logo} alt="logo" /></div>
          
          <div className="logout">
            <button onClick={() => {
              dispatch(logout());
              history.push("/login")
            }}>LOGOUT</button>
          </div>
        </div>
        <div className="welcome__section">
          <h1 className="title">Welcome {user.name}!</h1>
          <p className="description">
            Choose a service to immediately begin with ThatApp
          </p>
        </div>
        <div className="connectors">
          <div className="row">
            {cards.map((item, index) => (
              <ConnectCard
                key={index}
                image={item.image}
                text={item.text}
                text2={item.text2}
                onClick={item.onClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ConnectCard = (props) => {
  const { image, text, text2, onClick } = props;

  return (
    <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
      <div className="connector__box">
        <div className="image__container">
          <img src={image} alt="podio" />
        </div>
        <div className="text-center mt-3">
          <p>
            {text} <br /> {text2}
          </p>
        </div>
        <CustomButton onClick={onClick}>Get Started</CustomButton>
      </div>
    </div>
  );
};

export default ChooseService;
