import React from "react";
import "./ConfirmModal.css";

const OrgUpdateModal = ({ setOrgUpdateModal, action, the_data }) => {
  const [name, setName] = React.useState(the_data.org_name);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div className="confirm__modal">
      <div className="modal__content">
        <div>
          <div className="modal__card">
            <div>
              <h4>Edit Organisation</h4>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Organisation name
                </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ marginTop: "1rem" }}
                  onChange={handleChange}
                  value={name}
                  name="org_name"
                />
              </div>
              <button
                onClick={() => {
                  if (name !== null) action(the_data.org_id, name);
                }}
              >
                Update organisation
              </button>
              <p
                className="cancel"
                onClick={() => setOrgUpdateModal(null, null, false)}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgUpdateModal;
