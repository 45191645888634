import React from "react";
import EmptyImage from "../../assets/images/coming-soon.svg";
import styled from "@emotion/styled";

const ComingSoon = e => {
  return (
    <Coming>
      <img src={EmptyImage} alt="" className="empty-state" />
      <h2>Price Plans are coming soon! In the meantime, you can talk to one of our customer care representatives.</h2>
      <button className="sync__btn" id="my_intercom_link">
        Send us a message
      </button>
    </Coming>
  );
}

const Coming = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  border-bottom:1px #707070 solid;
  padding-bottom:50px;
  img{
    width:300px;
    margin-top:50px;
  }
  h2 {
    margin: 2rem auto;
    color: #292d61;
    font-size: 1rem;
    text-align:center;
  };
  button {
    color: #fff;
    background: #fcaf3b;
    border-radius: 6px;
    border:none;
    padding: 15px 30px;
  }
`;

export default ComingSoon;
