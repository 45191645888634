import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  dFlex: {
    display: "flex",
  },
  button: {
    backgroundColor: "#FBB03B",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ffc107",
    },
  },
  text: {
    color: "grey",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  text: {
    color: "grey",
  },
  mydail: {
    backgroundColor: "red",
    width: "10px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const classes = useStyles();

  return (
    <div className={classes.mydail}>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.Close}>
          Organizations
        </DialogTitle>
        <DialogContent dividers>
          <p className={classes.text}>
            Choose an Organization to sync right away
          </p>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            autoFocus
            onClick={props.handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={classes.button}
            autoFocus
            onClick={props.click}
            color="primary"
          >
            Start Syncing
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
