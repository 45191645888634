import React, { useCallback, useEffect, useState } from "react";

import SyncIcon from "../../assets/icons/sync-icon.svg";
import CopyIcon from "../../assets/icons/copy-icon.svg";
import EllipseIcon from "../../assets/icons/ellipse-icon.svg";
import DeleteIcon from "../../assets/icons/trash-icon.svg";
import { useParams } from "react-router-dom";

import "./Audit.css";
import AuditTable from "../../components/Table/AuditTable/AuditTable";
import { connect } from "react-redux";
import { getApps, getOrganizations, getSpaces } from "../../services/sync";

const Audit = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [organizations, setOrgs] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(params.id);
  const [selectedSpace, setSelectedSpace] = useState("");
  const [apps, setApps] = useState([]);

  useEffect(() => {
    setLoading(true);

    const getOrgs = async () => {
      let response = await getOrganizations();

      setOrgs(response.data);
    };

    const fetchSpaces = async () => {
      let response = await getSpaces(params.id);

      setSpaces(response.data);

      //setSelectedSpace(response.data[0].space_id)
    };

    fetchSpaces();
    getOrgs();
  }, [params.id]);

  const fetchApps = useCallback(
    async (id) => {
      setLoading(true);
      let query = `?org_id=${params.id}&space_id=${selectedSpace}`;
      let response = await getApps(query);

      setApps(response.data);

      if (response.data) {
        setLoading(false);
      }
    },
    [params.id, selectedSpace]
  );

  useEffect(() => {
    if (selectedSpace) {
      fetchApps(selectedSpace);
    }
  }, [selectedSpace, fetchApps]);

  if (loading) {
    return (
      <div className="load__audit">
        <div className="material_block">
          <svg
            className="spinner"
            width="65px"
            stroke="#F0B429"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="audit__page">
      <div className="row mb-5">
        <div className="col-md-6">
          <select
            className="form-control dropdown"
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
          >
            <option value="">Select Organization</option>
            {organizations.map((org, i) => {
              return (
                <option key={i} value={org.org_id}>
                  {org.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-end">
            <img className="mr-3" src={SyncIcon} width="20px" alt="icon" />
            <img className="mr-3" src={CopyIcon} alt="icon" />
            <img className="mr-3" src={DeleteIcon} alt="icon" />
            <img className="mr-3" src={EllipseIcon} alt="icon" />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <select
            className="form-control"
            value={selectedSpace}
            onChange={(e) => setSelectedSpace(e.target.value)}
          >
            <option value="">Select Spaces</option>
            {spaces.map((space, i) => {
              return (
                <option key={i} value={space.space_id}>
                  {space.space_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <div className="d-flex justofy-content-end"></div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-12 d-flex justify-content-between">
          <h5>APPS</h5>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <i className="fas fa-search"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Quick Search"
            />
          </div>
        </div>
        <div className="col-md-12">
          <AuditTable apps={apps} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orgs: state.sync.orgs,
});

export default connect(mapStateToProps, null)(Audit);
