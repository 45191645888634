import React from "react";
import styled from "@emotion/styled";
import TextField from "@material-ui/core/TextField";

const InputText = (props) => {
  return (
    <InputText.Wrapper>
      <TextField
        id="outlined-basic"
        label={props.label}
        variant="outlined"
        onChange={props.onChange}
        {...props}
      />
    </InputText.Wrapper>
  );
};

InputText.Wrapper = styled.div`
  width: 60%;
`;

export default InputText;
