import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, ListItemIcon } from "@material-ui/core";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useHistory } from "react-router";
import "./Header.css";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { connect, useDispatch } from "react-redux";
import { setUser,deletePodio } from "../../redux/actions/user";
import { setAuditOrg } from "../../redux/actions/orgs";
import DeleteIcon from '@material-ui/icons/Backspace';
import {
  USER_ACCOUNT,
  PLANS,
  MY_INVOICES,
  PLATFORM_USERS,
} from "../../containers/pagePath";
import {getMe} from "../../services/auth";
import { logout } from '../../redux/actions/auth';
import Swal from "sweetalert2";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const Header = ({ baropen, handleDrawerOpen, setUser, user, setAuditOrg }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const [prl, setPrl] = React.useState(user.podio_profile.rate_limit);
  const dispatch = useDispatch();
  const [img, setImg] = React.useState(user?.podio_profile?.profile?.image?.thumbnail_link);
  function refreshRateLimit() {
    getMe()
        .then((res) => {
          setPrl(res.data.podio_profile?.rate_limit);
          setImg(res.data.podio_profile?.profile?.image?.thumbnail_link);
        })
        .catch(() => {
          setPrl(null);
          setImg(null);
        });
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <div className="header">
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: baropen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, baropen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className="user__nav d-flex justify-between w-full">
            <div />
            <div className="user">
              <div style={{ marginRight: "20px" }}>
                <small>
                  Podio rate limit: <b>{prl ? prl.rate_limit : 0}</b><i className="fas fa-sync" style={{fontSize: "1.2em", marginLeft: "5px", cursor: "pointer"}} onClick={() => refreshRateLimit()} title="Refresh to get the latest podio rate-limit"></i>
                </small>
                <br />
                <small>
                  Podio rate limit remaining:{" "}
                  <b>{prl ? prl.rate_limit_remaining : 0}</b>
                </small>
              </div>
              <div className="user__image__container">
                {img? (
                  <img
                    alt="Podio Profile"
                    src={img}
                    style={{ width: "100%" }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div>
                <div
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  style={{ cursor: "pointer" }}
                >
                  {user.name}
                  <i
                    className="fas fa-angle-down"
                    style={{ position: "relative", top: "1px" }}
                  ></i>
                  <small className="d-block">{user.email}</small>
                </div>
                <Popper
                  className="nav__popup"
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={(e) => {
                                history.push(USER_ACCOUNT);
                                handleClose(e);
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                                <Icon>account_circle</Icon>
                              </ListItemIcon>
                              My account
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                history.push(MY_INVOICES);
                                handleClose(e);
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                              <LibraryBooksIcon/>
                              </ListItemIcon>
                              My Invoices
                            </MenuItem>
                            {user.type && (
                              <MenuItem
                                onClick={(e) => {
                                  history.push(PLATFORM_USERS);
                                  handleClose(e);
                                }}
                              >
                                <ListItemIcon className="min-w-40">
                                  <Icon>group</Icon>
                                </ListItemIcon>
                                Platform Users
                              </MenuItem>
                            )}

                            <MenuItem
                              onClick={(e) => {
                                history.push(PLANS);
                                handleClose(e);
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                                <Icon>money</Icon>
                              </ListItemIcon>
                              Plans
                            </MenuItem>
                            <MenuItem
                            onClick={() => {
                             
                              Swal.fire({
                                title: "Remove Podio",
                                text: 'Your podio account would be removed from sync',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Remove Account',
                                cancelButtonText: "Cancel",
                              }).then((result) => {
                                if (result.value) {
                                 dispatch(deletePodio());
                                  history.push("/connect");
                                }
                              });
                            }}
                          >
                            <ListItemIcon className="min-w-40">
                            <DeleteIcon/>
                            </ListItemIcon>
                            Disconnect Podio
                          </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatch(logout());
                              history.push("/login")
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                                <Icon>exit_to_app</Icon>
                              </ListItemIcon>
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAuditOrg: (data) => dispatch(setAuditOrg(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
