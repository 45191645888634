import React from "react";
import styled from "@emotion/styled";
import Header from "./Header/Header";
import BasicTable from "./Table";
import AddTemplate from "./AddTemplate";
import thatapp from "../../../assets/images/thatapp.png";
import CustomButton from "../../../components/atoms/CustomButton";
import "./styles.css";

const PrintDashboardTemplate = () => {
  const [table, setTable] = React.useState(true);

  return (
    <PrintDashboardTemplate.Wrapper>
      <div className="mother-container">
        <Header />
        <div className="header-dashboard flex pad">
          <h1>{table ? "Dashboard" : "New Template"}</h1>
          <CustomButton
            className={table ? "add-template-button" : "cancel-button"}
            onClick={() => setTable(!table)}
          >
            {table ? <i className="fas fa-plus"></i> : ""}
            {table ? "New Template" : "Cancel"}
          </CustomButton>
        </div>

        <div className="table-holder pad">
          <h4>{table ? "Template" : ""}</h4>
          {table ? <BasicTable /> : <AddTemplate />}
        </div>
        <div className="footer-container">
          <footer>
            <img src={thatapp} alt="thatapp" />
            <p>Copyright © TECHeGO {new Date().getFullYear()}</p>
            <p>Privacy Policy Terms of Service</p>
          </footer>
        </div>
      </div>
    </PrintDashboardTemplate.Wrapper>
  );
};

PrintDashboardTemplate.Wrapper = styled.div`
  .mother-container {
    background: #f7f7f7;
  }
  height: 100vh;
  .pad {
    padding-left: 150px;
    padding-right: 150px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-logo {
    background-color: #fff;
    /* padding: 26px 0; */
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    p {
      margin: 0;
      display: inline;
    }
    .logo {
      display: flex;
      align-items: center;
      p {
        font-size: 18px;
        margin-left: 16px;
      }
      /* img {
        width: 400px;
      } */
    }
  }
  .app-user {
    display: flex;
    align-items: center;
    .apps {
      margin-right: 10px;
      position: relative;
      cursor: pointer;
      .app-icon {
        width: 25px;
        margin-bottom: 5px;
      }
      /* img {
      } */
      .app-list {
        position: absolute;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        padding: 15px 24px;
        z-index: 2;
        left: -114px;
        top: 52px;
        -webkit-filter: drop-shadow(
          0 1px 10px rgba(113, 158, 206, 0.8)
        ); /*set shadow colour  and size here*/
        -moz-box-shadow: 0 1px 10px rgba(113, 158, 206, 0.8);
        filter: drop-shadow(0 1px 10px rgba(113, 158, 206, 0.8));

        :after,
        :before {
          bottom: 100%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        :after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #ffffff;
          border-width: 19px;
          left: 50%;
          margin-left: -19px;
        }

        :before {
          border-color: rgba(113, 158, 206, 0);
          border-bottom-color: #719ece;
          border-width: 20px;
          left: 50%;
          margin-left: -20px;
        }
        h6 {
          margin-bottom: 19px;
        }
        > div {
          display: flex;
          img {
            border: 0.5px solid #cfcfcf;
            border-radius: 4px;
            padding: 5px;
            :not(:last-of-type) {
              margin-right: 24px;
            }
            :hover {
              cursor: pointer;
            }
          }
        }
      }
      .active {
        display: none;
      }
    }
  }
  .header-dashboard {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    h1 {
      color: #3b3b3b;
      font-size: 24px;
    }
    .add-template-button {
      background-color: #0277bd;
      padding: 10px 22px;
      i {
        margin-right: 10px;
      }
    }
    .cancel-button {
      background-color: #e6e6e6;
      padding: 14px 28px;
    }
  }
  .table-holder {
    margin-top: 48px;
    h4 {
      font-size: 18px;
      color: #3b3b3b;
      margin-bottom: 16px;
    }
  }
  .footer-container {
    background-color: #f7f7f7;
  }
  footer {
    text-align: center;
    border-top: 1px solid #e1e1e1;
    padding: 33px 0 65px 0;
    margin: 100px 80px 0 80px;
  }
  footer img {
    margin-bottom: 27px;
    width: 300px;
  }
  .user-drop {
    text-align: right;
  }
  .user-drop .profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #c4c4c4;
    margin-right: 20px;
    display: inline-block;
  }
  .user-drop .info {
    margin: 0;
    padding: 0;
    text-align: left;
    display: inline-block;
  }
  .user-drop .arrow {
    text-align: right;
  }
  h5 {
    margin: 0;
    padding: 0;
    font-size: 17px;
  }
  .email {
    margin: 0;
    padding: 0;
  }
  .link__text {
    text-decoration: none;
    color: #000;
  }
`;
export default PrintDashboardTemplate;
