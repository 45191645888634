import React, { useEffect } from "react";

import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { imitate_account } from "../services/auth";
import { setAuditOrg } from "../redux/actions/orgs";

const ImitateAccount = (props) => {
  const user_id = new URLSearchParams(props.location.search).get("task");

  if (!props.user.type || !user_id) {
    alert("You're unauthorized!");
    window.history.back();
  }

  useEffect(() => {
    const imitate = async () => {
      const response = await imitate_account(`?user_id=${user_id}`);

      if (response.data) {
        //Old
        await setUser(null);
        await setAuditOrg(null);
        await localStorage.removeItem("chosen_token");
        //New
        await localStorage.setItem("chosen_token", response.data.access_token);
        await setUser(response.data);
        // window.location.href='/service';
      }
    };

    imitate();
  }, [props.user, props.location, user_id]);

  return (
    <div className="material_block">
      <svg
        className="spinner"
        width="65px"
        height="65px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="circle"
          fill="#fff"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
  );
};

const mapDisptachToProps = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
  setAuditOrg: (data) => dispatch(setAuditOrg(data)),
});

const mapStatetoProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStatetoProps, mapDisptachToProps)(ImitateAccount);
