import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { AllModules } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "./Grid.css";
import GridActions from "./jsxActions/GridActions";
import CheckboxRenderer from "./jsxActions/CheckboxRenderer";
import Switch from "./Switch";
import Endpoint from "../Iris/Endpoint";
import { connect } from "react-redux";
import { editItem } from "../../services/sync";
import DatePicker from "./jsxActions/DatePicker";
import CustomButton from "../../components/atoms/CustomButton";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { removeFromSync, restoreToPodio } from "../../redux/actions/test";
import { toast } from "../../helpers/apiRequests";
const FormatData = require("../../services/FormatData");

const Items = (props) => {
  const { match } = props;
  const [columnDef, setColumnDef] = useState(null);
  const [_columnDef, _setColumnDef] = useState(null);
  // const user = props.user;
  const [catField, setCatField] = useState(null);
  const [checked, setChecked] = useState(true);
  const [appName, setAppName] = useState("");
  const [loading, setLoading] = useState(false);
  const [_rowGroup, setRowGroup] = useState(true);
  const [ownerNamesArray, setownerNamesArray] = useState([]);
  const gridApi = useRef();
  const dispatch = useDispatch();
  const itemsSelected = useSelector((state) => state.liveData.selectedItems);
  const isDeleteSucceful = useSelector((state) => state.liveData.deleteSuccess);
  const user = useSelector((state) => state.user.authUser);
  // used to track what page you are on
  // const isDeletePage = window.location.pathname.split("/")[2] !== "delete";
  const [isDeletePage, setisDeletePage] = useState(false);

  useEffect(() => {
    if (window.location.pathname.split("/")[2] !== "delete") {
      setisDeletePage(true);
    } else {
      setisDeletePage(false);
    }
  }, [match.params]);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
    gridApi.current.refreshCells({ force: true });

    if (!checked) {
      props.history.push(
        `/dashboard/app/${match.params.org_id}/${match.params.id}`
      );
    } else {
      props.history.push(
        `/dashboard/delete/${match.params.org_id}/${match.params.id}`
      );
    }
  };

  const onCellValueChanged = (event) => {
    const check_type = event.colDef.field + "type_it";
    let type = event.data[check_type];
    let field_category_id = 0;

    if (type === "category" || typeof type === "undefined") {
      if (typeof event.data["_category_object"] !== "undefined") {
        const get_category = event.data._category_object.find(
          (element) => element.external_id === event.colDef.field
        );

        if (get_category) {
          type = "category";
          const fieldCategory = get_category.config.settings.options;

          field_category_id = fieldCategory.find(
            (element) => element.text === event.newValue
          );
        }
      } else {
        type = "text"; //Temp
      }
    }

    const data = {
      value: event.newValue,
      item_id: event.data._item_id,
      external_id: event.colDef.field,
      type,
      field_category_id: field_category_id !== 0 ? field_category_id.id : "",
    };

    if (event.newValue !== event.oldValue) {
      editItem(data)
        .then(() => {})
        .catch(() => {});
    }
  };

  const defaultColDef = {
    editable: true,
    resizable: true,
    flex: 1,
    minWidth: 200,
    enableValue: true,
    enableRowGroup: _rowGroup,
    enablePivot: true,
    sortable: true,
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    allowedAggFuncs: ["sum", "min", "max", "count", "avg"],
    floatingFilter: false,
  };

  let pathName = window.location.pathname.split("/");
  const initialCol = [
    {
      headerName: "Select",
      field: "registered",
      cellRenderer: "checkboxRenderer",
      editable: false,
      width: 50,
      hide: isDeletePage,
      suppressToolPanel: isDeletePage,
    },
    {
      headerName: "Action",
      field: "action",
      width: 200,
      enablePivot: false,
      editable: false,
      cellRenderer: "medalCellRenderer",
      columnGroupShow: "closed",
    },
  ];
  // hide: true,
  // suppressToolPanel: true,

  useEffect(() => {
    setLoading(true);
    match.url.includes("delete") ? setChecked(false) : setChecked(true);
    let category_fields = [];
    const columnArray = initialCol;

    let _pathName = window.location.pathname.split("/");

    if (_pathName[2] === "delete") {
      columnArray.push({
        headerName: "Deleted On",
        field: "deleted_on.date",
        width: 200,
        pivot: true,
        hide: false,
        enableRowGroup: true,
        onCellValueChanged,
        floatingFilter: false,
      });
    }

    Endpoint.singleApp(
      match.params.id,
      match.params.org_id,
      user.database.database
    )
      .then((response) => {
        const { data } = response.data;

        setAppName(response.data.data[0].config.name);
        setLoading(false);
        Endpoint.getOwnerNames(
          data[0].space_id,
          match.params.org_id,
          user.database.database
        )
          .then((response) => {
            const ownerNames = [];

            response.data.data.map((userObj) => {
              if (userObj.role === "regular") {
                return ownerNames.push(userObj.profile.name);
              }

              return null;
            });
            setownerNamesArray([]);
            ownerNamesArray.push(...ownerNames);
          })
          .catch(() => {
            setownerNamesArray([]);
          });
        response.data.data.map((item) => {
          return item.fields.map((item) => {
            if (item.type === "category") category_fields.push(item);
            if (item.status === "active")
              return columnArray.push(
                FormatData.FormatHeader(
                  item,
                  onCellValueChanged,
                  ownerNamesArray
                )
              );
            return "";
          });
        });
        setColumnDef(columnArray);

        if (_pathName[2] === "delete") {
          _setColumnDef(_columnDef);
        }
      })
      .catch(() => {
        setLoading(false);
      });

    setCatField(category_fields);
    // eslint-disable-next-line
  }, [match.params.id, match.params.org_id, user, match.url]);
  // []

  const frameworks = {
    medalCellRenderer: GridActions,
    datePickerRender: DatePicker,
    checkboxRenderer: CheckboxRenderer,
  };

  const paginationPageSize = 100;

  //  You can learn more on server side rendering on this link
  // https://www.ag-grid.com/documentation/react/server-side-model-datasource/
  const ServerSideDatasource = () => {
    return {
      getRows: function (params) {
        let data = null;
        let filterModel = null;
        let sort = null;

        if (isDeletePage) {
          const tempcolumnDefs = columnDef;

          tempcolumnDefs[0] = {
            ...tempcolumnDefs[0],
            hide: true,
            suppressToolPanel: true,
          };

          gridApi.current.setColumnDefs(tempcolumnDefs);
          gridApi.current.refreshCells({ force: true });
        } else {
          const tempcolumnDefs = columnDef;

          tempcolumnDefs[0] = {
            ...tempcolumnDefs[0],
            hide: false,
            suppressToolPanel: false,
          };

          gridApi.current.setColumnDefs(tempcolumnDefs);
          gridApi.current.refreshCells({ force: true });
        }

        if (
          Array.isArray(params.request.groupKeys) &&
          params.request.groupKeys.length
        ) {
          const theType = params.parentNode.field + "type_it";

          data = {
            group_value: params.request.groupKeys[0],
            group_field: params.parentNode.field,
            type: params.parentNode.data[theType],
          };
        }

        if (Object.entries(params.request.filterModel).length > 0) {
          let search_key = Object.keys(params.request.filterModel).length;

          if (search_key === 1) {
            search_key = Object.keys(params.request.filterModel)[0];
          } else {
            search_key = Object.keys(params.request.filterModel)[
              Object.keys(params.request.filterModel).length - 1
            ];
          }

          const columnDefs = params.parentNode.columnController.columnDefs;
          const def = columnDefs.find(
            (element) => element.field === search_key
          );
          let field_type = def.sync_type;
          const filterData = params.request.filterModel[search_key];
          let query = "";

          if (field_type === "date") {
            const dateTo = filterData.dateTo;

            query = filterData.dateFrom + "&dateTo=" + dateTo;
          } else {
            query = filterData.filter;
          }

          filterModel = `&search_type=${field_type}&search_word=${query}&search_key=${search_key}&filter=${filterData.type}`;
        }

        let theRowCol = [];

        if (
          Array.isArray(params.request.rowGroupCols) &&
          params.request.rowGroupCols.length > 0
        ) {
          setRowGroup(false);
          /*---------------------- */
          // handles the hiding and showing of the actions
          const tempcolumnDefs = columnDef;

          tempcolumnDefs[1] = {
            ...tempcolumnDefs[1],
            hide: true,
            suppressToolPanel: true,
          };

          gridApi.current.setColumnDefs(tempcolumnDefs);
          gridApi.current.refreshCells({ force: true });
          /*---------------------- */
          params.request.rowGroupCols.forEach((rowCol) => {
            theRowCol.push({
              field: rowCol.field,
              displayName: rowCol.displayName,
            });
          });
        } else {
          setRowGroup(true);
          const tempcolumnDefs = columnDef;
          // handles the hiding and showing of the actions

          tempcolumnDefs[1] = {
            ...tempcolumnDefs[1],
            hide: false,
            suppressToolPanel: false,
          };
          gridApi.current.setColumnDefs(tempcolumnDefs);
          gridApi.current.refreshCells({ force: true });
        }

        if (params.request.sortModel.length > 0) {
          // sort = `&sort=${params.request.sortModel[0].sort}&colId=${params.request.sortModel[0].colId}`;
        }

        pathName = window.location.pathname.split("/");
        let showDelete = false;

        if (pathName[2] === "delete") {
          showDelete = true;
        }

        Endpoint.itemsUnderApps(
          showDelete,
          pathName[4],
          pathName[3],
          user.database.database,
          params.request.startRow,
          paginationPageSize,
          data,
          filterModel,
          sort
        )
          .then((response) => {
            const mainData = response.data.data;
            const RowArray = [];
            let compiled;

            mainData.map((item, i) => {
              const y = [];

              if (showDelete) {
                y["deleted_on"] = item.deleted_on;
              }

              if (Array.isArray(mainData[i].fields)) {
                for (let b = 0; b < mainData[i].fields.length; b++) {
                  const formatedValue = FormatData.FormatData(item.fields[b]);

                  y[item.fields[b].external_id] =
                    typeof formatedValue === "string"
                      ? formatedValue.replace(/(<([^>]+)>)/gi, "")
                      : formatedValue;
                  y["_item_id"] = item.item_id;
                  y["_org_id"] = match.params.org_id;
                  y["revision_data"] = item.revision_data;
                  y["check_deletion"] = "0000";

                  if (
                    "undefined" === typeof item["deleted_from_podio"] ||
                    item["deleted_from_podio"] !== "true"
                  ) {
                    y["check_deletion"] = "false";
                  } else {
                    y["check_deletion"] = item["deleted_from_podio"];
                  }

                  if (item.fields[b].type === "app")
                    y["_app_id"] = item.fields[b].values[0].value.app.app_id;
                  const the_type = item.fields[b].external_id + "type_it";

                  y[the_type] = item.fields[b].type;
                  y["_field_index_"] = b;
                  y["_field_id_"] = item.fields[b].field_id;
                  y["item_link"] = item.link;
                  if (item.fields[b].type === "category")
                    y["_category_object"] = catField;
                }

                return RowArray.push(y);
              } else {
                return RowArray.push(mainData[i].fields);
              }
            });
            setTimeout(function () {
              let endResults = [];

              if (theRowCol.length > 0 && data === 0) {
                theRowCol.forEach((x) => {
                  RowArray.map((item) => {
                    const mainKey = x.field;
                    const val = item[mainKey];
                    const check = endResults.some(function (o) {
                      return o[mainKey] === val;
                    });

                    if (!check) {
                      endResults.push(item);
                    }

                    return null;
                  });
                });
                compiled = endResults;
              } else {
                compiled = RowArray;
              }

              //Sort
              if (params.request.sortModel.length > 0) {
                const columnId = params.request.sortModel[0].colId;
                const sortType = params.request.sortModel[0].sort;
                //TODO change sort from this to backend sorting.

                compiled.sort(function (a, b) {
                  if (
                    typeof a[columnId] !== "undefined" &&
                    typeof b[columnId] !== "undefined"
                  ) {
                    //Check if its a date
                    if (
                      FormatData.isDate(a[columnId]) &&
                      FormatData.isDate(b[columnId])
                    ) {
                      if (sortType === "asc") {
                        return new Date(a[columnId]) - new Date(b[columnId]);
                      } else if (sortType === "desc") {
                        return new Date(b[columnId]) - new Date(a[columnId]);
                      }
                    }

                    const A = a[columnId].toUpperCase(); // ignore upper and lowercase
                    const B = b[columnId].toUpperCase(); // ignore upper and lowercase

                    if (sortType === "asc") {
                      if (A < B) {
                        return -1;
                      }

                      if (A > B) {
                        return 1;
                      }
                    } else if (sortType === "desc") {
                      if (A < B) {
                        return 1;
                      }

                      if (A > B) {
                        return -1;
                      }
                    }
                  }

                  // values must be equal
                  return 0;
                });
              }

              // the first returns the data, the second ensures that you don't have extra columns in the grid
              params.successCallback(compiled, compiled.length);
            }, 200);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  };

  const deleteSelectedItems = () => {
    Swal.fire({
      title: "Delete Items",
      text: "Do you want to Delete selected items",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete Items",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        dispatch(
          removeFromSync({
            org_id: match.params.org_id,
            item_ids: JSON.stringify(itemsSelected),
          })
        );
        toast("Deleting Items....");
      }
    });
  };

  const restoreItems = () => {
    Swal.fire({
      title: "Restore Items",
      text: "Do you want to Restore selected items",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Restore Item",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        dispatch(
          restoreToPodio({
            org_id: match.params.org_id,
            item_ids: JSON.stringify(itemsSelected),
          })
        );
        toast("Restoring Items....");
      }
    });
  };

  useEffect(() => {
    if (isDeleteSucceful) {
      window.location.reload();
    }
  }, [isDeleteSucceful]);

  const onGridReady = (params) => {
    const theData = ServerSideDatasource();

    //https://stackoverflow.com/questions/60115939/how-to-access-ag-grid-api-in-react-function-component-usestate-hook

    gridApi.current = params.api; // <== to access the Actions on Grid
    params.api.setColumnDefs(columnDef);
    params.api.setServerSideDatasource(theData);
  };

  const rawDataUrl = `${process.env.REACT_APP_BASEURL}/sync/podio/raw/${
    match.params.org_id && user.database.database
  }/podio_spaces_${match.params.org_id}?email=${user.email}&secret_key=${
    user.raw_data_hash
  }`;

  if (loading) {
    return (
      <div className="material_block">
        <svg
          className="spinner"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle"
            fill="#fff"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    );
  }

  return (
    <div className="file__haven">
      <div className="row mb-2">
        <div className="col-md-12 text-right">
          {appName ? (
            <h4 className="float-left mt-30 mr-10">{appName} - Live Data</h4>
          ) : (
            ""
          )}
          <CustomButton
            variant="contained"
            style={{ marginLeft: "20px" }}
            color="primary"
            className="float-right"
            onClick={() => {
              props.history.push(
                `/dashboard/createitem/app/${match.params.id}/${match.params.org_id}`
              );
            }}
          >
            Add an Item
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            className="float-right mr-6"
            onClick={() => window.open(rawDataUrl, "_blank")}
          >
            Get Raw Data
          </CustomButton>
          <br />
        </div>
        <div
          style={{
            position: "relative",
            marginBottom: "10px",
            clear: "both",
            width: "fit-content",
          }}
        >
          <Switch handleChecked={handleChecked} checked={checked} />
        </div>
        <br />
      </div>
      {!isDeletePage && (
        <div className="row mb-2" style={{ marginLeft: "0px" }}>
          {itemsSelected.length !== 0 && (
            <React.Fragment>
              <CustomButton
                variant="contained"
                color="primary"
                style={{ backgroundColor: "green" }}
                className="float-right "
                onClick={restoreItems}
                disabled={itemsSelected.length === 0}
              >
                Restore Selected
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                style={{ backgroundColor: "firebrick" }}
                className="float-right ml-4"
                onClick={deleteSelectedItems}
              >
                Delete Selected
              </CustomButton>
            </React.Fragment>
          )}
        </div>
      )}
      <div
        className="ag-theme-balham-dark"
        style={{
          height: "1000px",
          width: "100%",
        }}
      >
        <AgGridReact
          modules={AllModules}
          columnDefs={isDeletePage ? columnDef : _columnDef}
          onGridReady={onGridReady}
          frameworkComponents={frameworks}
          animateRows={true}
          debug={true}
          enableRangeSelection={true}
          sideBar={true}
          suppressAggFuncInHeader={true}
          enableFilter={true}
          enableSorting={true}
          showToolPanel={true}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={paginationPageSize}
          rowModelType="serverSide"
          rowGroupPanelShow="always"
          rowSelection="multiple"
          enableCharts={true}
        ></AgGridReact>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(Items);
