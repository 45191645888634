import React, { useRef, useEffect } from "react"
import {Link} from 'react-router-dom'
import podio from "../../../../assets/images/podiolg.png";
import Popover from "@material-ui/core/Popover";
import edit_icon from "../../../../assets/icons/edit-icon.svg";
import delete_icon from "../../../../assets/icons/delete-icon.svg";
import printSmall from "../../../../assets/images/printsmall.png";
import avaSmall from "../../../../assets/images/avasmall.png";
import syncSmall from "../../../../assets/images/syncsmall.png";
import appIcon from "../../../../assets/images/apps.png";
import "./Header.css";
import { useSelector } from "react-redux";


const useClickOutside = (handler) => {
  const domNode = useRef();

  useEffect(() => {
    const maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Header = () => {
  const user = useSelector(state => state.user.authUser)
  const [img] = React.useState(user?.podio_profile?.profile?.image?.thumbnail_link);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showApps, setshowApps] = React.useState(true);
  const domNode = useClickOutside(() => {
    setshowApps(true);
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  return (
    <div className="header-logo flex pad ">
      <div className="logo">
        <Link className="link__text" to='/service'>
          <img src={podio} alt="podio logo" />
          <p>Podio</p>
        </Link>
      </div>

      <div className="app-user">
        <div className="apps">
          <img
            src={appIcon}
            alt="appIcon"
            className="app-icon"
            onClick={() => setshowApps(!showApps)}
          />
          <div
            ref={domNode}
            className={`app-list ${showApps ? "active" : ""}`}
          >
            <h6>Apps</h6>
            <div>
              <img src={printSmall} alt="printSmall" />
              <img src={avaSmall} alt="printSmall" />
              <img src={syncSmall} alt="printSmall" />
            </div>
          </div>
        </div>
        <div className="user-drop">
          <div className="profile-picture"
                style={{
                    backgroundImage: `url(${img})`,
                    backgroundSize:`cover`,
                    backgroundPosition:`center`
                }} 
            ></div>
          <div className="info">
            <h5>{user.name}</h5>
            <span className="email small">{user.email}</span>
          </div>
          {
            //  <div className="arrow" onClick={handleClick}>
            //   <img src={arrow_down} alt="arrow down" />
            // </div>
          }
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className="action-button-tab">
              <img src={edit_icon} alt="edit icon" /> Billing
            </div>
            <div className="action-button-tab">
              <img src={delete_icon} alt="delete icon" /> Packages
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Header
