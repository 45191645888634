import "./ConfirmModal.css";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  dFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "1.5rem",
  },
  button: {
    marginTop: 20,
    background: "#2871B6",
    color: "#fff",
    border: "none",
    width: "100%",
    padding: "10px 30px",
    borderRadius: "4px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2871B6",
    },
  },
  cancel: {
    color: "#2871B6",
    marginTop: 10,
    cursor: "pointer",
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs({
  openModal,
  title,
  message,
  action,
  toggleModal,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={openModal}>
        <DialogContent dividers>
          <div className={classes.dFlex}>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
            <Typography component="p" gutterBottom>
              {message}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => action()}
            >
              Yes, I am sure
            </Button>
            <p className={classes.cancel} onClick={toggleModal}>
              Cancel
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
