import { authorizePodio } from "../../helpers/Authentication/Auth";

export const parseHashString = (hashString) => {
  if (!hashString || !hashString.trim()) return {};
  const hash = unescape(hashString);

  const splitted = hash.split("&");
  const tempObj = {};

  for (let singleItem of splitted) {
    const [key, value] = singleItem.split("=");

    tempObj[key] = value;
  }

  return tempObj;
};

export const authorize = (selectedConnector, setConnecting, current_path) => {
  if (selectedConnector.title === "Podio") {
    setConnecting({ connectors: "podio", loading: true });
    const callback_url = window.location.origin + current_path;

    authorizePodio(callback_url);
  }
};
