import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { getPlansRequest } from "../../redux/actions";
import ComingSoon from "./ComingSoon";
import "./plan.css";

const Plans = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlansRequest());
  }, [dispatch]);
  return (
    <div>
      <div>
        <div className="color__background"></div>
        <div className="px-4">
          <div className="flex__center">
            <ComingSoon />;
          </div>
          <div className="">
            <Typography
              variant="h4"
              style={{ margin: "4rem 0", textAlign: "center" }}
            >
              Frequently Asked Questions
            </Typography>

            <div className="grid-2">
              <div className="w-full sm:w-1/2 p-24">
                <Typography className="text-20 mb-8">
                  What are your payment options?
                </Typography>
                <Typography className="text-16" color="textSecondary">
                  Our preferred method of payment is via credit card: we accept
                  Visa, MasterCard, American Express, Discover, Diners, UnionPay
                  and JCB cards. ThatApp has a secure credit card processing
                  system provided by Bluesnap. Registered retail customers and
                  corporate/government/organizational clients can arrange
                  payments via ACH or wire transfer.
                </Typography>
              </div>

              <div className="w-full sm:w-1/2 p-24">
                <Typography className="text-20 mb-8">
                  What if I don't have a credit card?
                </Typography>
                <Typography className="text-16" color="textSecondary">
                  We also accept PayPal payments. Registered retail customers
                  and corporate/government/organizational clients can arrange
                  payments via ACH or wire transfer.
                </Typography>
              </div>

              <div className="w-full sm:w-1/2 p-24">
                <Typography className="text-20 mb-8">
                  Who processes my payment?
                </Typography>
                <Typography className="text-16" color="textSecondary">
                  Our primary processing system for credit cards is provided by
                  Bluesnap.
                </Typography>
              </div>

              <div className="w-full sm:w-1/2 p-24">
                <Typography className="text-20 mb-8">
                  How long is a quote valid?
                </Typography>
                <Typography className="text-16" color="textSecondary">
                  If you have registered and saved a quote, the quote will
                  remain valid for up to seven days.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
