import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 320,
  },
  select: {
    marginTop: "0.5rem",
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();

  return (
    <div>
      <div>{props.title} </div>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          onChange={props.handleChange}
          value={props.value}
          className={classes.select}
        >
          {props.options.map((item, index) => {
            return (
              <MenuItem key={item} value={index}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
