import React from "react";

import "./AuditTable.css";

import SyncIcon from "../../../assets/icons/sync-icon.svg";
import CopyIcon from "../../../assets/icons/copy-icon.svg";
import DeleteIcon from "../../../assets/icons/trash-icon.svg";

const AuditTable = ({ apps }) => {
  return (
    <div className="audit__table">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                <input type="checkbox" className="form-control" />
              </th>
              <th>ID</th>
              <th>Name</th>
              <th>Created</th>
              <th>Items</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {apps.map((app, i) => {
              return (
                <tr key={i}>
                  <td>
                    <input type="checkbox" className="form-control" />
                  </td>
                  <td>
                    <p className="title m-0">{app.app_id}</p>
                  </td>
                  <td>{app.app}</td>
                  <td>
                    <p className="mb-1">{app.synced_at}</p>
                  </td>
                  <td>
                    <span className="font-weight-bold">{app.items_count}</span>
                  </td>
                  <td>
                    <span className="info">
                      {app.deleted_from_podio ? "Active" : "Deleted"}
                    </span>
                  </td>
                  <td>
                    <div className="dropdown">
                      <span
                        className="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        ...
                      </span>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <span className="dropdown-item">
                          {" "}
                          <img src={SyncIcon} alt="icon" /> Sync
                        </span>
                        <span className="dropdown-item">
                          {" "}
                          <img src={CopyIcon} alt="icon" width="13px" /> Copy
                        </span>
                        <span className="dropdown-item">
                          {" "}
                          <img src={DeleteIcon} alt="icon" width="13px" />{" "}
                          Delete
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AuditTable;
