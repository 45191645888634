import React from "react";
import styled from "@emotion/styled";
import Button from "@material-ui/core/Button";

const UserInfo = ({ user, organisations }) => {

  return (
    <UserInfo.Wrapper>
      <div className="d-flex mt-5 main-section">
        <div className="w-50">
          <h5>ACCOUNT</h5>
          <p className="w-50">
            Having an up-to-date email address attached to your account is a
            great step towards improved security
          </p>
        </div>
        <div className="d-flex flex-column ">
          <div className="mb-3">
            <img
              src={
                user.podio_profile.profile.image
                  ? user.podio_profile.profile.image.link
                  : "https://i.stack.imgur.com/l60Hf.png"
              }
              alt="fuse"
              className="profile-pic"
            />
          </div>
          <div className="mb-3">
            <h6 className="font-weight-bold">Name</h6>
            <h5> {user && user.name} </h5>
          </div>
          <div className="mb-3">
            <h6 className="font-weight-bold">Email</h6>
            <h5> {user && user.email} </h5>
          </div>
          <div className="mb-3">
            <h6 className="font-weight-bold">Organisations</h6>
            <h5>Synced {organisations.length} org(s)</h5>
            <h5>Unsynced {organisations.length} org(s)</h5>
          </div>
        </div>
      </div>
      <div className="account-info mt-3 d-flex">
        <div className="w-50">
          <h5>DELETE ACCOUNT</h5>
          <p className="w-50">
            If you delete your account, your data will be gone forever.
          </p>
        </div>
        <div>
          <Button
            variant="outlined"
            style={{ border: "none", marginLeft: "1rem", color: "#FF0400" }}
            color="primary"
            id="intercom_link"
            onClick={() => {window.Intercom('showNewMessage', 'Hello. I would like to delete my Sync account and all data attached to it.')}}
          >
            Delete
          </Button>
        </div>
      </div>
    </UserInfo.Wrapper>
  );
};

UserInfo.Wrapper = styled.div`
  color: #515151;
  p {
    color: #9e9e9e;
  }
  .main-section {
    border-bottom: 1px solid #e1e1e1;
  }
  .profile-pic {
    width: 96px;
    height: 96px;
    border-radius: 50%;
  }
  .account-info{
    margin-top:50px;
    padding-top:50px;
  }
  .delete-account {
    p {
      margin: 0;
    }
  }
`;
export default UserInfo;
