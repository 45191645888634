import React, { useState, useEffect } from "react";

import "../index.css";
import Logo from "../../../assets/images/logosync.svg";

import { Link, useHistory } from "react-router-dom";
import { loginUser } from "../../../services/auth";
import { setUser } from "../../../redux/actions/user";
import { useDispatch } from "react-redux";
import Asterisk from "../../../components/Common/Asterisk";
import styled from "@emotion/styled";
import qs from "stringquery";
import Button from "@material-ui/core/Button";

const Login = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window.location.hash.substr(1) !== "undefined") {
      const type = window.location.hash.substr(1);
      const obj = qs(type);

      let access = {};

      if (typeof obj["ccess_token"] !== "undefined") {
        access["access_token"] = obj.ccess_token;
        access["refresh_token"] = obj.refresh_token;
        access["expires_in"] = obj.expires_in;
        access["user_id"] = type.split("=")[3].split("&")[0];
        localStorage.setItem("podio_access_object", JSON.stringify(access));
      }
    }

    if (window.opener !== null && window.opener !== "null") {
      setTimeout(function () {
        window.opener.location.href = "/authorizepass";
        window.close();
      }, 1000);
    }
  });

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const hidePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }

    setPasswordType("password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = data;
    const userInfo = {
      email,
      password,
    };

    try {
      let response = await loginUser(userInfo);

      if (response) {
        if (response.data) {
          localStorage.setItem("chosen_token", response.data.access_token);
          dispatch(setUser(response.data));
          setLoading(false);
        }
      }
    } catch (x) {
      setLoading(false);
    }
  };

  const authorizeWithPodio = async () => {
    const url = `${window.location.origin}/login`;

    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;

    window.open(
      `https://podio.com/oauth/authorize?client_id=sync-for-podio-dev&redirect_uri=${url}&scope=global:all&response_type=token`,
      "login",
      params
    );
  };

  return (
    <div className="auth">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="auth__sidebar">
            <div className="layer">
              <div className="custom__container__80">
                <img src={Logo} alt="logo" />
                <h1 className="title">
                  Welcome to <br /> ThatApp!
                </h1>
                <p className="description">
                  {/* It is our job to ensure that you are never lost in the clouds.
                  Gain a refreshing perspective of your business processes and
                  strategic initiatives through the eyes of our skilled workflow
                  architects and system integrators. */}
                  “<b>Thatapp.io</b> the intelligent and collaborative database
                  solution that securely empowers productivity through
                  streamlined and automated business processes.”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="auth__component">
            <div className="custom__container__80">
              <div className="text-right">
                <p className="route__text">
                  Do not have an account?
                  <Link className="link__text" to="/register">
                    {"  "} Sign up
                  </Link>
                </p>
              </div>
              <div className="auth__form__container mt-5">
                <h1>Sign in to ThatApp</h1>
                <form onSubmit={handleSubmit} className="mt-5">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        Email <Asterisk />
                      </label>
                      <input
                        className="form-control"
                        name="email"
                        value={data.email}
                        required
                        type="email"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        Password <Asterisk />
                      </label>
                      <input
                        className="form-control"
                        type={passwordType}
                        name="password"
                        required
                        value={data.password}
                        onChange={handleChange}
                      />
                      <i
                        className={`${
                          passwordType === "password"
                            ? "far fa-eye"
                            : "far fa-eye-slash"
                        } field-icon2`}
                        onClick={hidePassword}
                      ></i>
                    </div>
                  </div>
                  <PasswordLink onClick={() => history.push("/forgotpassword")}>
                    Forgot Password
                  </PasswordLink>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    size="large"
                    type="submit"
                  >
                    {loading ? "Please Wait" : "Sign in"}
                  </Button>
                </form>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  size="large"
                  className="mt-4"
                  onClick={() => authorizeWithPodio()}
                >
                  Authorize with Podio
                  <img
                    src="https://print.thatapp.io/images/podio.png"
                    alt="Podio Logo"
                    className="ml-2"
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PasswordLink = styled.div`
  margin-bottom: 1rem;
  margin-top: -1rem;
  color: #e5ac4c;
  :hover {
    cursor: pointer;
  }
`;

export default Login;
