import React from "react";

import "./ConfirmEmail.css";
import email_icon from "../../../assets/icons/email-icon.svg";
import logo from "../../../assets/images/logoyellow.svg";

const ConfirmEmail = () => {
  return (
    <div className="confirm__email">
      <div className="background"></div>
      <div className="container">
        <img src={email_icon} alt="Email Icon" className="email_icon" />
        <h1>Verify Your Email Address</h1>
        <div>
          Please verify your account by clicking on the link sent to your email
          address to begin using ThatApp.
        </div>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
