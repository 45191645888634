import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../../assets/images/dashboardlogo.svg";
import "./Sidebar.css";
import MainNavigation from "../Navigation/MainNavigation";
import { Divider } from "@material-ui/core";
import { connect } from "react-redux";
import OrganizationNavFunction from "../Navigation/OrganizationNavFunction";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const Sidebar = ({ open, handleDrawerOpen, user }) => {
  const classes = useStyles();

  return (
    <div className="sidebar">
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div className="sidebar__header">
            <div className="flex items-center">
              <img src={Logo} alt="logo" />
              <span className="logo__title">Podio</span>
              <p style={{ marginLeft: "2.5rem", fontSize: "12px" }}>
                {" "}
                {user.podio_profile.profile.mail
                  ? user.podio_profile.profile.mail[0]
                  : ""}{" "}
              </p>
            </div>
          </div>

          <MenuIcon
            color="white"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          ></MenuIcon>
        </div>

        <div className="sidebar__content">
          {open ? <p className="subheading mt-2">SYSTEM</p> : <Divider />}
          <MainNavigation />
          {open ? (
            <p className="subheading mt-4 mb-3">ORGANIZATIONS</p>
          ) : (
            <Divider />
          )}
          <OrganizationNavFunction />
        </div>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(Sidebar);
