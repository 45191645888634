import React from "react";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RestoreIcon from "@material-ui/icons/Restore";
import Dialog from "@material-ui/core/Dialog";
import { Alert } from "@material-ui/lab";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { restoreFileInHaven } from "../../../../services/sync";
import Endpoint from "../../../Iris/Endpoint";

const MainAction = (props) => {
  const [spaceSync, syncModalData] = React.useState({
    message: null,
    modal_type: null,
    modal_value: false,
    modal_data: {},
  });
  const [snack, setSnack] = React.useState({
    message: null,
    snackType: null,
    isSnack: false,
    reload: false,
  });

  //Action Display
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  const restoreFileModal = (org, file_id) => {
    syncModalData({
      message: "Do you want to restore this file to Podio?",
      modal_type: "restore_file",
      modal_value: true,
      modal_data: { org, file_id },
    });
  };

  const restoreFile = (org, file_id) => {
    const data = {
      org_id: org,
      file_id,
    };

    if (data) {
      // props.setValue({deleted_from_podio: 'Exists'});
      props["data"]["deleted_from_podio"] = "Exists";
    }

    restoreFileInHaven(`?org_id=${data.org_id}&file_id=${data.file_id}`)
      .then((res) => {
        setSnack({
          message: "Restored successfully",
          snackType: "success",
          isSnack: true,
          reload: true,
        });
        confirm_handleClose();
      })
      .catch((err) => {
        setSnack({
          message: "Failed to restore, try again later!",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
        confirm_handleClose();
      });
  };

  const confirm_handleClose = () => {
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  };

  const handleClose = () => {
    setSnack({ message: "", snackType: "", isSnack: false, reload: false });
  };

  if (!data) return <React.Fragment></React.Fragment>;

  return (
    <>
      {
        <>
          {data.deleted_from_podio === "true" ||
          data.deleted_from_podio === "True" ? (
            <Tooltip title="Restore File" style={{ marginTop: "-5px" }}>
              <IconButton
                aria-label="Download Item"
                onClick={() => restoreFileModal(data.org_id, data.file_id)}
              >
                <RestoreIcon style={{ color: "#FFF" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Download File" style={{ marginTop: "-5px" }}>
              <IconButton
                aria-label="Download Item"
                onClick={() => {
                  if (typeof props.node["key"] !== "undefined") {
                    const field = props.node.field;
                    const db = data.db;
                    const org_id = data.org_id;
                    let query = "&api_v2=true";

                    Endpoint.getFiles(org_id, db, 0, 100, query).then((res) => {
                      const data = res.data.data;
                      const results = data.filter(
                        (x) => x[field] === props.node["key"]
                      );
                      let file_ids = [];

                      results.forEach((x) => {
                        file_ids.push(x.file_id);
                      });

                      if (file_ids.length > 0) {
                        file_ids = JSON.stringify(file_ids);
                      }

                      window.open(
                        `${
                          process.env.REACT_APP_BASEURL
                        }/sync/podio/download/files?org_id=${org_id}&file_ids=${file_ids}&token=${localStorage.getItem(
                          "chosen_token"
                        )}`,
                        "_blank"
                      );
                    });
                  } else {
                    window.location.href = data.link;
                  }
                }}
              >
                <CloudDownloadIcon style={{ color: "#FFF" }} />
              </IconButton>
            </Tooltip>
          )}
          <Dialog
            open={snack.isSnack}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <Alert onClose={handleClose} severity={snack.snackType}>
              {snack.message}
            </Alert>
          </Dialog>

          <Dialog
            open={spaceSync.modal_value}
            onClose={confirm_handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">File Restoration</DialogTitle>
            <DialogContent>
              <DialogContentText>{spaceSync.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={confirm_handleClose} color="primary">
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  restoreFile(
                    spaceSync.modal_data.org,
                    spaceSync.modal_data.file_id
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </>
  );
};

export default MainAction;
