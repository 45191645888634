import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import "./config";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ErrorBoundary from "./components/ErrorBoundary";
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG,
  plugins: [new BugsnagPluginReact()],
});
const ErrorNotifier = Bugsnag.getPlugin("react").createErrorBoundary(React);

// Bugsnag.notify(new Error("process env error"));
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <ErrorNotifier>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ErrorNotifier>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
