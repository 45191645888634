import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styled from "@emotion/styled";
import podio from "../../assets/images/podiolg.png";
import CustomButton from "../../components/atoms/CustomButton";

const Wrapper = styled.div`
  /* padding: 4em; */
  background: #e5e5e5;
  height: 100vh;
  .pad {
    padding-left: 76px;
    padding-right: 82px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-logo {
    background-color: #fff;
    /* padding: 26px 0; */
    padding-top: 26px;
    padding-bottom: 26px;
    border-bottom: 1px solid #e1e1e1;
    p {
      margin: 0;
      display: inline;
    }
    .logo {
      display: flex;
      align-items: center;
      p {
        font-size: 18px;
        margin-left: 16px;
      }
    }
  }
  .grid-holder {
    display: grid;
    grid-template-columns: 371px auto 290px;
    gap: 20px;
    .left-sidebar {
      background-color: #fff;
      height: 100vh;
      padding: 16px;
    }
    aside {
      background-color: #fff;
      height: 100vh;
      padding: 16px;
      .button-holder {
        button {
          height: 48px;
          width: 100%;
          margin-bottom: 24px;
          :nth-of-type(1) {
            background-color: #0277bd;
          }
          :nth-of-type(2) {
            background-color: #4caf50;
          }
          :nth-of-type(3) {
            background-color: #0277bd;
          }
          :nth-of-type(4) {
            background-color: #f44336;
          }
        }
      }
    }
  }
`;

class App extends Component {
  render() {
    ClassicEditor.create(document.querySelector("#editor"), {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "blockQuote",
      ],
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    }).catch((error) => {});
    return (
      <Wrapper className="App">
        <div className="header-logo flex pad ">
          <div className="logo">
            <img src={podio} alt="podio" />
            <p>Podio</p>
          </div>
          <div className="user-drop ">user see</div>
        </div>
        <div className="grid-holder">
          <div className="left-sidebar">can you see me the side bar</div>
          <main>
            <h2>Using CKEditor 5 build in React</h2>
            <CKEditor
              editor={ClassicEditor}
              data="<p>Hello from CKEditor 5!</p>"
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                editor.getData();
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
          </main>
          <aside>
            I am the aside
            <div className="button-holder">
              <CustomButton>Save</CustomButton>
              <CustomButton>Upload to Podio</CustomButton>
              <CustomButton>Download</CustomButton>
              <CustomButton>Deactivate Print</CustomButton>
            </div>
          </aside>
        </div>
      </Wrapper>
    );
  }
}

export default App;
