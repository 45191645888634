import React from "react";
import { HorizontalBar } from "react-chartjs-2";

const Chart = ({ data }) => {
  const state = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Hooks",
        fill: true,
        lineTension: 0.5,
        backgroundColor: "#0D355B",
        borderColor: "#FBB03B",
        borderWidth: 3,
        // data: data ? data.datasets["2020"].data[0].data : [],
        // the key can be 2020 or 2021 so I just get the first item there
        data: data
          ? data.datasets[Object.keys(data.datasets)[0]].data[0].data
          : [],
      },
    ],
  };

  return (
    <div>
      <HorizontalBar
        data={state}
        options={{
          title: {
            display: true,
            text: "Hooks Chart",
            fontSize: 20,
          },
          legend: {
            display: false,
            position: "right",
          },
        }}
      />
    </div>
  );
};

export default Chart;
