export const testRequest = {
  GET_COLUMNS: "/sync/app/23103122?org_id=1473960&db=ThatApp_4814&api_v2=true",
  GET_DATA:
    "/sync/app/items/23103122?org_id=1473960&database=ThatApp_4814&api_v2=true&skip=0&limit=100",
};

export const userRequest = {
  PLANS: "/sync/billing/plans",
  INVOICES: "/account/invoices",
  BUY_PLAN: "/sync/billing/register/plan",
  INVITE: "/sync/podio/invite/user",
  TEAM_MEMBERS: "/sync/podio/team/members",
  PENDING_INVITATION: "/sync/podio/invites",
  ME: "/auth/me",
  DELETE_PODIO: "/sync/podio/disconnect",
};
export const OrganisationRequest = {
  DELETE_SELECTED: "/sync/podio/permanent_delete/items",
  RESTORE_SELECTED: "/sync/podio/restore/items",
};
export const rawDataRequest = {
  COLLECTION: "/account/collection/list?collection_type=mongo",
};
export const printRequest = {
  ORGANIZATIONS: "/print/podio/organizations",
  SPACES: "/print/podio/organization/:id/workspaces",
  APPS: "/print/podio/workspace/:id/apps",
  CREATE: "/print/template/create",
  TEMPLATES: "/print/template/list",
};
